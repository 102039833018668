import styled, { css } from 'styled-components';

export const Content = styled.div`
  padding: 0;
  box-sizing: border-box;
  /* background: #1e2125; */
  background-color: #393e44;
  color: #fff;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);

  /* max-width: 700px; */
  width: 100%;
  /* margin: 0px 0 0 -350px; */
  border-radius: 5px;
  border: solid 3px #ff8322;
  box-shadow: 0 0 50px rgba(0, 0, 0, 1);
  z-index: 100000;
  max-width: 1000px;

  /* ${(props) =>
    props.double &&
    css`
      max-width: 1000px;
      margin: 0 0 0 -500px;
    `} */
  &.withLoading {
    &::after {
      content: '';
      z-index: 3;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.8) url('/images/loader.svg') no-repeat 50%;
      background-size: 60px auto;
    }
  }
`;

export const Inner = styled.div`
  padding: 20px;
`;

export const Title = styled.div`
  text-align: center;
  font-size: 17px;
  white-space: nowrap;
  line-height: 1.5;
  padding: 0 20px;
  background-color: #1c2024;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  height: 56px;
  border-bottom: 2px solid #ff8322;
  flex-shrink: 0;
  flex-wrap: wrap;
  span {
    color: #bbb;
    display: inline-block;
    margin-right: 10px;
  }
  b {
    display: block;
    font-weight: normal;
    font-size: 1.5em;
  }
`;

export const Calls = styled.div`
  position: absolute;
  right: -58px;
  top: -17px;
  z-index: 2;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  justify-content: center;
  button {
    min-width: 150px;
    margin: 0 10px;
  }
`;

export const PlateInfo = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
  justify-content: center;
`;
