import React from 'react';
import { connect } from 'react-redux';
import { openWindow } from '../store/actions/windows';
import { Wrapper, Bar } from './TopBar.styled';
import User from './User';
import InfoBlock from './InfoBlock';

const TopBar = (props) => {
  return (
    <Wrapper>
      <Bar>
        <InfoBlock />
        <User openWindow={(data) => props.openWindow(data)} />
      </Bar>
    </Wrapper>
  );
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {
  openWindow,
})(TopBar);
