import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import SignalsGrid from './grid';

const SignalsWrapper = styled.div`
  height: 620px;
  width: 100%;
  display: flex;
`;

function Signals(props) {
  function getEventsArr() {
    const signalsList = [];
    if (props.signals) {
      for (let rack in props.signals) {
        let currentRack = props.signals[rack];
        //console.log('🚀 ~ getEventsArr ~ currentRack', currentRack);
        for (let key in currentRack) {
          signalsList.push(currentRack[key]);
        }
      }
    }
    signalsList.sort((x, y) => {
      return y.timestamp - x.timestamp;
    });
    return signalsList;
  }

  return (
    <SignalsWrapper>
      <SignalsGrid signals={getEventsArr()} />
    </SignalsWrapper>
  );
}

function mapStateToProps(store) {
  return {
    signals: store.events.events,
  };
}

export default connect(mapStateToProps)(Signals);
