import React from "react";
import styled from "styled-components";
import { BlueButton, WhiteButton } from "../../../../shared/buttons";

const WarningContainer = styled.div`
  padding: 16px;
  box-sizing: border-box;
  background: #fff;
  color: #000;
  position: absolute;
  max-width: 480px;
  left: 0;
  top: 35px;
  border-radius: 16px;
  z-index: 100;
`;

const Confirm = ({ cancel, ok, error }) => {
  if (error) {
    return (
      <WarningContainer>
        <div>
          <div>Не удалось выдать БСК</div>
          <div
            style={{
              display: "flex",
              marginTop: "20px",
              justifyContent: "space-evenly",
            }}
          >
            <BlueButton onClick={cancel}>Закрыть</BlueButton>
          </div>
        </div>
      </WarningContainer>
    );
  }
  return (
    <WarningContainer>
      <div>
        <div>Подтвердите выдачу БСК</div>
        <div
          style={{
            display: "flex",
            marginTop: "20px",
            justifyContent: "space-evenly",
          }}
        >
          <WhiteButton onClick={cancel}>Отменить</WhiteButton>
          <BlueButton onClick={ok}>Выдать</BlueButton>
        </div>
      </div>
    </WarningContainer>
  );
};

export default Confirm;
