import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { RedButton, WhiteButton } from '../../../../shared/buttons';
import { Fields, Block, WarningContainer, Content, Title, Buttons } from './ConfirmWithComment.styled';

const ConfirmWithComment = (props) => {
  const [reason, setReason] = useState('');
  const [comment, setComment] = useState('');

  if (!window.dict['reasonofpass']) {
    return null;
  }

  /**
   * set value from input
   * @param {*} event 
   */
  const setValue = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === 'reason') {
      setReason(value);
    }
    if (name === 'comment') {
      setComment(value);
    }
  }

  /**
   * send message
   */
  const send = () => {
    const out = 'Причина: ' + reason + '. Комментарий: ' + comment;    
    props.action(out);
    props.close();
  };

  /**
   * validation
   */
  const isValid = () => {
    return reason && comment.trim().length > 0;
  };

  const valid = isValid();
  const items = window.dict['reasonofpass'].map((el, idx) => {
    return (
      <option key={idx} value={el.name}>
        {el.name}
      </option>
    );
  });

  return (
    <WarningContainer>
      <Content>
        <Title>Открытие шлагбаума</Title>
        <p>Выберите причину открытия шлагбаума и заполните поле комментарий</p>
        <Fields>
          {items.length > 0 && (
            <Block>
              <label htmlFor="reason">Причина</label>
              <select id="reason" value={reason} name="reason" onChange={setValue}>
                <option value={''}></option>
                {items}
              </select>
            </Block>
          )}
          <Block>
            <label htmlFor="comment">Комментарий</label>
            <input maxLength={255} type="text" id="comment" name="comment" value={comment} onChange={setValue} />
          </Block>
        </Fields>
        <Buttons>
          <WhiteButton onClick={props.close}>Отменить</WhiteButton>
          <RedButton disabled={!valid} onClick={send}>
            Подтвердить
          </RedButton>
        </Buttons>
      </Content>
    </WarningContainer>
  );
};

ConfirmWithComment.propTypes = {
  close: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired,
};

export default ConfirmWithComment;
