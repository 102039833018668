import styled, { css } from 'styled-components';

export const WarningContainer = styled.div`
  box-sizing: border-box;
  background: #4f5761;
  color: #fff;
  position: absolute;

  width: 450px;
  left: 50%;
  top: 50%;
  border-radius: 8px;
  z-index: 100;
  margin: -95px 0 0 -225px;
`;
export const Title = styled.div`
  margin: 0 0 15px;
  font-size: 20px;
  ${(props) =>
    props.center &&
    css`
      text-align: center;
    `}
`;

export const Content = styled.div`
  padding: 16px;
`;
export const Buttons = styled.div`
  margin: 15px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Block = styled.div`
  margin: 0 0 10px;
  label {
    color: #ccc;
    display: block;
    margin: 0 0 3px;
  }
  select {
    padding: 5px;
    border-radius: 4px;
  }

  input {
    border-radius: 4px;
    padding: 5px;
    width: 97%;
    display: block;
  }
`;
export const Fields = styled.div`
  margin: 0 0 15px;
  padding: 0 0 5px;
  border-bottom: 1px solid #999;
`;
