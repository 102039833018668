import styled, { css } from 'styled-components';

export const Place = styled.div`
  margin: 0;
  padding: 5px 0;
  position: relative;
  ${(props) =>
    props.loading &&
    css`
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.8) url('/images/loader.svg') no-repeat 50% 50%;
        background-size: 30px auto;
      }
    `}
`;
export const PlaceItem = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
  label {
    cursor: pointer;
    margin: 0 0 0 10px;
  }
  input {
    cursor: pointer;    
    font-size: 16px;
    padding: 3px 5px;
  }
  [type='checkbox'] {
    cursor: pointer;
    width: 17px;
    height: 17px;
  }
`;
