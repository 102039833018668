import styled from 'styled-components';

export const RackGroupWrapper = styled.div`
  position: absolute;
  left: ${(props) => props.left};
  top: ${(props) => props.top};
`;

export const RackGroupId = styled.div`
  font-size: 14px;
  color: #bfc1c4;
  opacity: 0.9;
  //max-width: 100%;
  height: 35px;
  border-radius: 4px;
  background-color: #0f1214;
  display: inline-flex;
  //justify-content: center;
  align-items: center;
  padding: 0 10px;
`;
export const Racks = styled.div`
  display: flex;
`;
