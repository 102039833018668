import React from 'react';
import styled from 'styled-components';
import Zone from '../shared/zone/Zone';
//import zones from '../../lists/zonesList'
import { connect } from 'react-redux';

const MapLocationsWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

function LocationsMap(props) {
  const zones = window.config.objects ? window.config.objects.zones : [];

  if (!zones) {
    return null;
  }

  if (!props.boards) {
    return null;
  }

  function getZones(zones) {
    //console.log('🚀 ~ getZones ~ zones', zones);

    let zonesList = zones.map((item) => {
      const places = props.boards[item.zoneId] ? props.boards[item.zoneId].maximum : 0;
      //console.log('🚀 ~ zonesList ~ props.boards[item.zoneId]', props.boards[item.zoneId]);
      return <Zone key={item.zoneId} places={places} {...item} />;
    });
    return zonesList;
  }

  return <MapLocationsWrapper>{getZones(zones)}</MapLocationsWrapper>;
}

const mapStateToProps = function (store, props) {
  return {
    boards: store.boards.boards,
  };
};

export default connect(mapStateToProps)(LocationsMap);
