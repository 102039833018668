import React from 'react';
import styled, { css } from 'styled-components';

export const Filter = styled.div`
  position: relative;
  padding: 20px;
  background-color: #4f5761;  
  ${(props) =>
    props.disabled &&
    css`
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 10;
        cursor: not-allowed;
        background: rgba(0, 0, 0, 0.5);
      }
    `}
`;
export const FilterInputsBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  align-content: space-between;

`;

export const FilterInputs = styled.div`
  margin-right: 20px;
  p {
    opacity: 0.6;
    font-size: 13px;
    text-align: left;
    color: #ffffff;
    margin-bottom: 5px;
  }
`;
export const CalendarInterval = styled.div`
  display: flex;
  justify-content: space-around;
  p {
    cursor: pointer;
    color: #3ea5ff;
    font-size: 13px;
    text-align: left;
  }
  p:hover {
    /*color: #6200ff; */
    color: #fff;
  }
`;
export const AngleDown = styled.div`
  position: absolute;
  top: 15px;
  right: 10px;
  width: 8px;
  height: 8px;
  border: ${(props) => (props.secondaryColor ? '2px solid #b7babf' : '2px solid #6e7a86')};
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
`;
export const Input = styled.input`
  color: white;
  box-sizing: border-box;
  font-size: 15px;
  padding: 9px;
  background-color: #4f5761;
  height: 40px;
  display: ${(props) => {
    if (props.mergedLeftInput || props.mergedRightInput) return 'inline-block';
  }};
  border: solid 1px #a3b0c0;
  border-right: ${(props) => {
    if (props.mergedLeftInput) return 0;
  }};
  border-radius: ${(props) => {
    if (props.mergedLeftInput) return '4px 0 0 4px';
    else if (props.mergedRightInput) return '0 4px 4px 0';
    else return '4px';
  }};
`;
export const FilterSelect = styled.div`
  position: relative;
  display: inline-block;
`;

const SelectInput = styled.select`
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 15px;
  padding: 9px;
  background-color: ${(props) => (props.secondaryColor ? '#4f5761' : '#4f5761')};
  color: #ffffff;
  height: 40px;
  border: solid 1px #bebebe;
`;
const SelectWrapper = styled.div`
  position: relative;
  display: ${(props) => {
    if (props.mergedLeftInput || props.mergedRightInput) return 'inline-block';
  }};
  select {
    border-radius: ${(props) => {
      if (props.mergedLeftInput) return '4px 0 0 4px';
      else if (props.mergedRightInput) return '0 4px 4px 0';
      else return '4px';
    }};
    border-right: ${(props) => {
      if (props.mergedLeftInput) return 0;
    }};
  }
`;
export const Select = (props) => {
  return (
    <SelectWrapper mergedLeftInput={props.mergedLeftInput} mergedRightInput={props.mergedRightInput} className={props.className}>
      <SelectInput secondaryColor={props.secondaryColor} value={props.value} onChange={props.onChange}>
        {props.children}
      </SelectInput>
      <AngleDown secondaryColor={props.secondaryColor} />
    </SelectWrapper>
  );
};

export const ToggleBox = styled.div`
  position: absolute;
  bottom: 30px;
  display: flex;
  align-items: center;
  p {
    font-size: 15px;
    color: #3e3e3e;
  }
`;
export const Toggle = styled.div`
  width: 38px;
  height: 20px;
  cursor: pointer;
  border-radius: 21px;
  background-color: ${(props) => (props.status ? '#3ea5ff' : '#cfd1d6')};
  margin-left: 5px;
  padding: 1px;
  div {
    position: relative;
    width: 18px;
    height: 18px;
    background-color: #ffffff;
    border-radius: 50%;
    transition: left 0.2s;
    left: ${(props) => (props.status ? '18px' : '0')};
  }
`;
export const ClearFilters = styled.div`
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  right: 20px;
  bottom: 30px;
  p {
    font-size: 15px;
    color: #3ea5ff;
  }
  div {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
    &::before,
    &::after {
      position: absolute;
      left: 10px;
      content: ' ';
      height: 20px;
      width: 2px;
      background-color: #3ea5ff;
    }
  }
`;
export const SelectBox = styled.div`
  position: relative;
`;
export const ZoneInput = styled.input.attrs({
  disabled: 'disabled',
})`
  background-color: #fff;
  width: ${(props) => props.width};
  height: 40px;
  font-size: 15px;
  padding: 9px;
  border: solid 1px #bebebe;
  border-radius: 4px;
`;
export const ZoneInputBox = styled.div``;
export const ZoneInputValueList = styled.ul`
  padding: 9px;
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
`;
export const ZoneInputValueItem = styled.li`
  list-style: none;
  position: relative;
  font-size: 22px;
  &:first-child {
    z-index: 8;
    left: ${(props) => (props.ridesSelect ? '-5px' : '-0px')};
  }
  &:nth-child(2) {
    z-index: 7;
    left: ${(props) => (props.ridesSelect ? '-26px' : '-20px')};
  }
  &:nth-child(3) {
    z-index: 6;
    left: ${(props) => (props.ridesSelect ? '-46px' : '-40px')};
  }
  &:nth-child(4) {
    z-index: 5;
    left: ${(props) => (props.ridesSelect ? '-66px' : '-60px')};
  }
  &:nth-child(5) {
    z-index: 4;
    left: ${(props) => (props.ridesSelect ? '-86px' : '-80px')};
  }
  &:nth-child(6) {
    z-index: 3;
    left: ${(props) => (props.ridesSelect ? '-106px' : '-100px')};
  }
  &:nth-child(7) {
    z-index: 2;
    left: ${(props) => (props.ridesSelect ? '-126px' : '-120px')};
  }
  &:nth-child(8) {
    z-index: 1;
    left: ${(props) => (props.ridesSelect ? '-146px' : '-140px')};
  }
`;
export const DoubleZoneInputValueItem = styled.li`
  list-style: none;
  position: relative;
  div {
    &:first-child {
      z-index: 8;
      left: 0px;
    }
    &:nth-child(2) {
      z-index: 7;
      left: -20px;
    }
  }
`;

export const ZonePopup = styled.div`
  position: absolute;
  z-index: 999;
  border: solid 1px #bebebe;
  border-top: 0;
  border-radius: 4px;
  background-color: #fff;
  width: ${(props) => props.width};
  top: 40px;
  left: 0;
  display: ${(props) => (props.popupIsOpen ? 'block !important' : 'none !important')};
`;
export const ZonesList = styled.ul``;
export const ZoneItem = styled.li`
  list-style: none;
`;
export const EllipsisItem = styled(ZoneItem)``;
export const ItemBox = styled.div`
  height: ${(props) => (props.double ? '96px' : '48px')};
  display: flex;
  align-items: center;
  padding: 10px;
  p {
    font-size: 15px;
    color: #000000;
    opacity: 1;
  }
`;
export const CheckBox = styled.div`
  border: 1px solid;
  border-color: ${(props) => (props.selected ? '#3ea5ff' : '#bebebe')};
  background-color: ${(props) => (props.selected ? '#3ea5ff' : '#fff')};
  border-radius: 3px;
  width: 14px;
  height: 14px;
  margin-right: 15px;
  span {
    display: ${(props) => (props.selected ? 'inline-block' : 'none')};
    width: 6px;
    height: 10px;
    border: solid #fff;
    margin: 0 0 4px 3px;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`;
