import React from 'react';
import moment from 'moment';

import { Arrow } from '../styledComponents';
import {
  PaymentMeanButton,
  FinalRow,
  InfoRow,
  PaymentMeanBlock,
  PaymentMeanBody,
  PaymentMeanHeader,
  PaymentMeanPlace,
} from './PaymentMeanInfo.styled';

class PaymentMeanInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPaymentMean: false,
      info: null,
    };
  }

  componentDidMount() {
    window.socket.on('get_payment_mean', (data) => {
      this.setState({ info: data.payment_mean });
    });
  }

  togglePaymentMean = () => {
    this.setState({ showPaymentMean: !this.state.showPaymentMean }, this.getPaymentMeanInfo);
  };

  getPaymentMeanInfo = () => {
    this.setState({ info: null });
    //this.props.removePaymentMeanInfo();
    this.props.sendControlMsg('get_payment_mean');
  };

  getPaymentMeanType = () => {
    if (this.state.info) {
      switch (this.state.info.payment_mean_type) {
        case 'subscription':
          return 'Абонемент';
        case 'receipt':
          return 'Билет';
        case 'bank_card':
          return 'Банковская карта';
        case 'card':
          return 'БСК';
        case 'rfid':
          return 'RFID';
        case 'vehicle_plate':
        case 'plate':
          return 'ГРЗ';
        default:
          return this.state.info.payment_mean_type || 'Не определено';
      }
    }
  };

  getStayInfo(stay) {
    if (!stay) {
      return null;
    }

    const add1 =
      stay && stay.is_secondentry ? (
        <InfoRow>
          <div>Повторный въезд</div>
        </InfoRow>
      ) : null;

    return (
      <>
        {stay.entry_at && (
          <InfoRow>
            <p>Время вьезда: </p>
            <span>
              {moment(stay.entry_at)
                .format('lll')
                .replace(/[г., ]{4}/, '')
                .replace(/\./, '')}
            </span>
          </InfoRow>
        )}
        {add1}
        {stay.amount && (
          <FinalRow>
            <p>К оплате: </p>
            <span>{stay.amount / 100} р.</span>
          </FinalRow>
        )}
      </>
    );
  }

  showAdditionalPaymentInfo = () => {
    const info = this.state.info;
    //console.log('🚀 ~ info', info);

    return (
      <>
        {info.is_mean_blocked && (
          <InfoRow>
            <p>
              <img src="/images/warning.png" alt="" />
            </p>{' '}
            <span style={{ color: 'red', marginRight: '135px', whiteSpace: ' nowrap' }}>СО блокировано</span>
          </InfoRow>
        )}
        <InfoRow>
          <p>Класс: </p>
          <span>{this.getVehicleClass(info.vehicle_class)}</span>
        </InfoRow>
        <InfoRow>
          <p>ГРЗ: </p>
          <span>{info.vehicle_plate}</span>
        </InfoRow>
        {this.getStayInfo(info.stay)}
      </>
    );
  };

  getVehicleClass = (class_id) => {
    switch (class_id) {
      case 1:
        return 'Легковые автомобили';
      case 2:
        return 'Микроавтобусы/газели';
      case 3:
        return 'Грузовики/автобусы';
      case 4:
        return 'Спецтранспорт (с прицепом)';
      default:
        return class_id;
    }
  };

  render() {
    const { info, showPaymentMean } = this.state;
    const { single } = this.props;
    //const info = paymentMean.info;
    return (
      <PaymentMeanPlace single={single}>
        <PaymentMeanButton onClick={this.togglePaymentMean}>Подробности</PaymentMeanButton>
        {showPaymentMean ? (
          <PaymentMeanBlock>
            <Arrow />
            <PaymentMeanHeader>
              {info && (
                <p>
                  {this.getPaymentMeanType()}
                  <span>{info.payment_mean_number}</span>
                </p>
              )}
              <img onClick={this.togglePaymentMean} src="/images/close.png" alt="" />
            </PaymentMeanHeader>
            <PaymentMeanBody>{info ? this.showAdditionalPaymentInfo() : <p className="no-info">Нет данных по средству оплаты</p>}</PaymentMeanBody>
          </PaymentMeanBlock>
        ) : null}
      </PaymentMeanPlace>
    );
  }
}

PaymentMeanInfo.defaultProps = {
  single: false,
};

export default PaymentMeanInfo;
