import { setPeriod, prepareDate } from 'libs/common';
import { DateSelect } from 'libs/ui';
import { CalendarInterval } from './dates-select.styled';

export const DatesSelect = (props) => {
  const { from, to, updateFilter } = props;

  /**
   * predefined periods
   * @param {*} period
   */
  const changePeriod = (period) => {
    setPeriod(period, updateFilter);
  };

  // const timeFrom = prepareDate(from);
  // console.log('🚀 ~ DatesSelect ~ from:', from, timeFrom);
  // const timeTo = prepareDate(to);
  // console.log('🚀 ~ DatesSelect ~ to:', to, timeTo);

  return (
    <>
      <DateSelect fieldName="time_from" setFilter={updateFilter} value={from} mergedLeftInput></DateSelect>
      <DateSelect fieldName="time_to" setFilter={updateFilter} value={to} mergedRightInput></DateSelect>
      <CalendarInterval>
        <p onClick={() => changePeriod('today')}>Сегодня</p>
        <p onClick={() => changePeriod('currentWeek')}>7 дней</p>
        <p onClick={() => changePeriod('currentMonth')}>30 дней</p>
        <p onClick={() => changePeriod('lastThreeMonths')}>3 месяца</p>
        <p onClick={() => changePeriod('')}>Весь период</p>
      </CalendarInterval>
    </>
  );
};
