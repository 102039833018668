import { PLAY_SOUND, STOP_SOUND } from '../constants';

export const playSound = (type) => {
  return {
    type: PLAY_SOUND,
    data: type,
  };
};

export const stopSound = (type) => {
  return {
    type: STOP_SOUND,
    data: type,
  };
};
