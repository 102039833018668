import React from 'react'

import { BlueButton, WhiteButton } from '../../../../shared/buttons'
import {
  Notification as Container,
  WarningNotification as WarningContainer
} from '../styledComponents'

const Notification = ({ notification, sendControlMsg, toggleReloadPopup }) => {

  const doReboot = () => {
    sendControlMsg('reboot', toggleReloadPopup)
  }

  const getNotification = () => {
    switch (notification) {
      case 'reload_confirm':
        return (
          <WarningContainer>
            <div style={{ marginRight: '20px' }}><img src='/images/icon.png' alt='' /></div>
            <div>
              <div>
                Вы уверены что хотите перезагрузить контроллер?
                </div>
              <div style={{ display: 'flex', marginTop: '20px', justifyContent: 'space-evenly' }}>
                <WhiteButton onClick={toggleReloadPopup}>Отменить</WhiteButton>
                <BlueButton onClick={() => doReboot()}>Подтвердить</BlueButton>
              </div>
            </div>
          </WarningContainer>
        )
      case 'reload_help':
        return (
          <Container>
            <div>
              <div style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold', marginBottom: '10px' }}>Помогла перезагрузка?</div>
              <div>
                Для проверки работоспособности, необходимо продолжить/начать оплату в паркомате
              </div>
              <div style={{ display: 'flex', marginTop: '20px', justifyContent: 'space-evenly' }}>
                <WhiteButton onClick={toggleReloadPopup}>Да, помогла</WhiteButton>
                <BlueButton onClick={() => sendControlMsg('restart_service', toggleReloadPopup)}>Перезапустить сервис</BlueButton>
              </div>
            </div>
          </Container>
        )
      default: return null
    }
  }

  return getNotification()
}

export default Notification