import { useState, useMemo, useRef } from 'react';

import { useOutsideClicker } from 'libs/hooks';
import { Container, Header, List, ListContainer, ListItem, ArrowDown } from './custom-select.styled';

export const CustomSelect = (props) => {
  const { items, value, onChange, ...rest } = props;

  /**
   * find option
   * @param {*} value
   * @returns
   */
  const findOption = (value) => {
    const v = items.filter((el) => el.value === value);
    return v.length ? v[0] : null;
  };

  /**
   * current option
   */
  const current = useMemo(() => {
    return findOption(value);
  }, [value]);

  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(current);

  /**
   * toggler open
   * @returns `
   */
  const toggle = () => setOpen(!open);

  /**
   * select option
   * @param {*} value
   * @returns
   */
  const onOptionClicked = (value) => () => {
    const opt = findOption(value);
    setSelectedOption(opt);
    setOpen(false);
    onChange(value);
    //console.log("🚀 ~ onOptionClicked ~ selectedOption", selectedOption);
  };

  /**
   * close on click outside
   */
  const ref = useRef(null);
  useOutsideClicker(ref, () => {
    setOpen(false);
  });

  return (
    <div ref={ref}>
      <Container {...rest}>
        <Header onClick={toggle} className={selectedOption.value}>{selectedOption.name || ''}</Header>
        {open && (
          <ListContainer>
            <List>
              {items.map((option) => {
                let cl = option.value === selectedOption.value ? 'active' : '';
                cl += ' ' + option.value;
                //console.log('🚀 ~ {items.map ~ cl', cl);
                return (
                  <ListItem className={cl} onClick={onOptionClicked(option.value)} key={option.value}>
                    {option.name}
                  </ListItem>
                );
              })}
            </List>
          </ListContainer>
        )}
        <ArrowDown active={open} />
      </Container>
    </div>
  );
};
