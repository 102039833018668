class Event {
  constructor(data, active = false) {
    /*
    if (data.type === 'info') {
      data.type = 'signal';
    }
    */
    //if (data.type === "notification") {
      //data.type = 'info';
    //}

    this.equipmentType = window.service.call("getEquipmentType", data.source);
    this.id = data.id;
    this.type = data.type;
    this.timestamp = data.timestamp;
    this.source = data.source;
    this.source_type = data.source_type;
    this.source_name = data.source_name;
    this.message = data.message;
    this.active = active;
    this.data = data.data;
  }
}

export default Event;
