/**
 * Text input UI adapter
 *
 * @overview Callback parent component after success login
 */

import { FormGroup, Input } from './text-input.styled';

export function TextInput(props) {
  const { name, helperText, label, error, ...rest } = props;
  //console.log('🚀 ~ TextInput ~ props:', props);

  return (
    <FormGroup error={error}>
      <Input {...rest} placeholder={label} id={name} name={name} />
      {helperText}
    </FormGroup>
  );
}

TextInput.defaultProps = {
  fullWidth: true,
  //size: 'small',
  //variant: 'standard',
  type: 'text',
};

export default TextInput;
