import React from 'react';

import { FilterActions, PrioritySelect, DatesSelect } from 'libs/ui';
import { FilterInputsBox, FilterInputs, FilterSelect, ClearFilters } from 'components/shared/filter';
import { DescriptionInput, EquipmentSelect, NumberInput, SignalsHistoryFilterBox } from './filter.styled';

const SignalsHistoryFilter = (props) => {
  const { filter, target } = props;
  // console.log('🚀 ~ SignalsHistoryFilter ~ filter:', filter.time_from);

  return (
    <SignalsHistoryFilterBox disabled={props.refreshActive}>
      <FilterInputsBox>
        <FilterInputs>
          <p>Начало события</p>
          <DatesSelect from={filter.time_from} to={filter.time_to} updateFilter={props.updateFilter} />
        </FilterInputs>

        {!target && (
          <FilterInputs>
            <p>Оборудование</p>
            <FilterSelect>
              <EquipmentSelect value={filter.equipment_type} onChange={(e) => props.updateFilter('equipment_type', e.target.value)}>
                <option value="">Все</option>
                <option value="rack.lane">Стойка</option>
                <option value="rack.payment">Паркомат</option>
                <option value="rack.cashbox">Касса</option>
                <option value="rack.server">Сервер</option>
              </EquipmentSelect>
            </FilterSelect>
          </FilterInputs>
        )}

        {!target && (
          <FilterInputs>
            <p>Номер</p>
            <NumberInput value={filter.source} onChange={(e) => props.updateFilter('source', e.target.value)} />
          </FilterInputs>
        )}

        <FilterInputs>
          <p>Приоритет</p>
          <PrioritySelect value={filter.type} onChange={(val) => props.updateFilter('type', val)} />
        </FilterInputs>

        <FilterInputs style={{ margin: '0px' }}>
          <p>Описание сигнала</p>
          <DescriptionInput value={filter.message} onChange={(e) => props.updateFilter('message', e.target.value)} />
        </FilterInputs>

        <FilterActions active={props.refreshActive}>
          {props.refreshInput}
          <ClearFilters onClick={props.resetFilter} className="with-refresh">
            <div />
            <p>Сбросить</p>
          </ClearFilters>
        </FilterActions>
      </FilterInputsBox>
    </SignalsHistoryFilterBox>
  );
};

export default SignalsHistoryFilter;
