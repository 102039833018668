import { useState, useEffect } from 'react';

import { socket } from 'api';
import { Content } from './items.styled';
import { Item } from './item';
import { getItems, getStayData } from './items.func';

const GET_STAY_METHOD = 'stays.get_by_id';

export const Items = (props) => {
  const { event, rackId } = props;
  const { data } = event;
  const [photo, setPhoto] = useState('');
  const [plate, setPlate] = useState('');
  const [loading, setLoading] = useState(true);
  const node = window.config.nodes[event.source];
  const nodeType = node?.rack_type;

  /**
   * get in data
   * @returns
   */
  const getData = () => {
    setLoading(true);

    if (1 === 0 && data?.stay_id) {
      socket.call(GET_STAY_METHOD, { stay_id: data?.stay_id });
    } else {
      setLoading(false);
    }
  };

  // on request stay data
  useEffect(() => {
    socket.on(GET_STAY_METHOD, prepareStayData);
    getData();

    return () => {
      socket.off(GET_STAY_METHOD, prepareStayData);
    };
  }, [rackId]);

  /**
   * prepare stay data
   * @param msg
   */
  const prepareStayData = (msg) => {
    const { photo, plate } = getStayData(msg);
    setPhoto(photo);
    setPlate(plate);
    setLoading(false);
  };

  /**
   * get foto items elements
   * @param {*} items
   * @returns
   */
  const getItemsEl = () => {
    const items = getItems(nodeType, data, photo || data.photo_stay, plate || data.vehicle_plate_stay);
    // console.log('🚀 ~ getItemsEl ~ items:', items);
    return items.map((el, idx) => {
      return <Item key={idx} item={el} source={event.source} getData={getData} />;
    });
  };

  const itemsEl = getItemsEl();

  const cl = loading ? 'loading' : '';
  return <Content className={cl}>{loading ? null : itemsEl}</Content>;
};
