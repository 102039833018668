import styled from 'styled-components';

import config from 'config';
const bg = config.theme?.header?.background ? config.theme.header.background : '#1e2227';

export const Wrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  background: ${bg};
  padding: 10px;
`;

export const Bar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media (max-width: 1021px) {
    justify-content: left;
  }
`;
