import getEquipmentInnerId from './getEquipmentInnerId';

export function getNodeType(type, id) {
  const types = {
    entry: 'Въездная стойка',
    zone: 'Промежуточная стойка',
    leave: 'Выездная стойка',
  };
  let name = '';
  switch (type) {
    case 'payment_terminal':
      name = 'АПП';
      break;
    case 'cashbox':
      name = 'Ручная касса';
      break;
    case 'server':
      name = 'Сервер';
      break;
    case 'goose':
      name = 'Стойка для прохода';
      break;
    default:
      name = 'Стойка';
      if (id) {
        const node = window.config.nodes[id];
        if (node && node.rack_type && types[node.rack_type]) {
          name = types[node.rack_type];
        }
      }
      break;
  }
  return name;
}

export function getNodeTitle(type, id, caption) {
  let out = getNodeType(type, id);
  if (out) {
    out += ' ';
  }
  if (caption) {
    out += caption;
  } else {
    out += type === 'cashbox' ? '' : getEquipmentInnerId(id);
  }

  return out;
}
