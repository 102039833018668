import React from 'react';
import PropTypes from 'prop-types';

import { Item, Title, Value } from './RackState.styled';

class RackStateItem extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { title, active, info, ...rest } = this.props;
    //console.log('🚀 ~ render ~ this.props', this.props);

    return (
      <Item title={info} {...rest}>
        <Value active={active}>
          <Title>{title}</Title>
        </Value>
      </Item>
    );
  }
}

RackStateItem.propTypes = {
  title: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
};

RackStateItem.defaultProps = {};

export default RackStateItem;
