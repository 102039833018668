import React from 'react';

import Window from 'components/shared/window/window';

import Signals from './signals';
import SignalsHistory from './signals-history/signals-history';
import Options from './options/options';
import OptionsApp from './options/optionsApp';
import RackControl from './racksControl/rack/RackControl';
import PaymentRackControl from './racksControl/paymentRack/PaymentRackControl';
import GooseControl from './racksControl/goose/GooseControl';

function RackWindow(props) {
  const type = props.data.data.type;
  const tabs = [];
  const withoutRack = ['server', 'cashbox'].includes(type);
  if (!withoutRack) {
    tabs.push({ text: 'Управление', id: 'control' });
  }
  tabs.push({ text: 'Сигналы', id: 'signals' });
  tabs.push({ text: 'История сигналов', id: 'history' });
  if (window.haveRight('mnemo.rack_settings') && !['goose', 'server', 'cashbox'].includes(props.data.data.type)) {
    tabs.push({ text: 'Настройки', id: 'options' });
  }

  const acctiveTab = props.data.data.withSignals ? 'signals' : '';

  function getRackControlByType() {
    switch (props.data.data.type) {
      case 'single_rack':
        return RackControl;
      case 'payment_terminal':
        return PaymentRackControl;
      case 'goose':
        return GooseControl;
      case 'server':
      case 'cashbox':
        return null; //CashboxControl;
      default:
        return RackControl;
    }
  }

  const content = {
    control: getRackControlByType(),
    signals: Signals,
    history: SignalsHistory,
    options: props.data.data.type === 'payment_terminal' ? OptionsApp : Options,
  };

  const height = props.data.data.type === 'payment_terminal' ? '740px' : '700px';

  return (
    <Window
      withMode
      acctiveTab={acctiveTab}
      type={type}
      tabs={tabs}
      content={content}
      width="990px"
      height={height}
      contentHeight={height}
      {...props.data}
    />
  );
}

export default RackWindow;
