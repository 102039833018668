import { SignalsHistoryAll } from 'libs/ui';

/**
 * general history
 * @returns
 */
const SignalsHistory = () => {
  return <SignalsHistoryAll apiMethod="get_all_events_history" />;
};

export default SignalsHistory;
