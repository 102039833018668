import React from 'react';
import { connect } from 'react-redux';

import { socket } from 'api';
import { dropCall } from 'store/actions/calls';
import { removeWidget } from 'store/actions/widgets';
import { Content, BottomPanel, CallStatus, Dot, DropCall, TopPanel, TypeIcon } from './ActiveCall.styled';

class ActiveCall extends React.Component {
  state = {
    activeCall: [],
    status: null,
  };

  timerInterval = null;

  componentDidMount() {
    this.findActiveCall();
    this.setCallTimer();
    window.addEventListener('beforeunload', this.dropCall);
  }

  componentWillUnmount = () => {
    window.clearInterval(this.timerInterval);
    window.onclose = this.dropCall;
  };

  findActiveCall = () => {
    const active = [];
    const activeCalls = window.getActiveCalls();
    for (let key in activeCalls) {
      if (this.props.calls[key]) {
        active.push(this.props.calls[key]);
      }
    }
    this.setState({ activeCall: active });
  };

  getSourceType = () => {
    let paymentTerminalFinded = false;
    let rackFinded = false;
    this.state.activeCall.forEach((item) => {
      if (item.source_inner_id.length === 5) {
        paymentTerminalFinded = true;
      } else {
        rackFinded = true;
      }
    });
    if (paymentTerminalFinded && rackFinded) {
      return <img src="/images/payment-and-rack.png" alt="" />;
    } else if (paymentTerminalFinded) {
      return <img src="/images/payment_terminal.png" alt="" />;
    } else {
      return <img src="/images/single_rack.png" alt="" />;
    }
  };

  getCallStatus = () => {
    if (this.timerInterval) {
      let minutes = null;
      let seconds = this.state.status;
      minutes = Math.floor(seconds / 60);
      if (minutes < 10) {
        minutes = '0' + minutes;
      }
      seconds = seconds - minutes * 60;
      if (seconds < 10) {
        seconds = '0' + seconds;
      }
      return minutes + ':' + seconds;
    } else {
      return this.state.status;
    }
  };

  setCallTimer = () => {
    this.timerInterval = window.setInterval(() => {
      this.setState((state) => {
        return {
          status: state.status + 1,
        };
      });
    }, 1000);
  };

  getNodeName = (id) => {
    const types = {
      cashbox: 'Касса',
      single_rack: 'Стойка',
      payment_terminal: 'АПП',
    };
    if (!window.config.nodes[id]) {
      return { title: '' + id, payment: false };
    }
    const item = window.config.nodes[id];
    let out = types[item.type] ? types[item.type] + ' ' : '';
    out += item.caption;
    return { title: out, payment: item.type === 'payment_terminal' };
  };

  getSourceNumber = () => {
    let paymentTerminalIds = [];
    let rackIds = [];
    this.state.activeCall.forEach((item) => {
      //console.log("🚀 ~ this.state.activeCall.forEach ~ item", item);
      const node = this.getNodeName(item.source_inner_id);
      /*
      if (item.source_inner_id.length === 5) {
        paymentTerminalIds.push(
          window.service.call("getEquipmentInnerId", item.source_inner_id)
        );
      } else {
        rackIds.push(item.source_inner_id);
      }
      */
      if (node.payment) {
        paymentTerminalIds.push(node.title);
      } else {
        rackIds.push(node.title);
      }
    });
    return (
      <>
        {paymentTerminalIds.length ? (
          <p>
            <Dot color={'#4b7153'} />
            {paymentTerminalIds.join(', ')}
          </p>
        ) : null}
        {rackIds.length ? (
          <p>
            <Dot color={'#7e7343'} />
            {rackIds.join(', ')}
          </p>
        ) : null}
      </>
    );
  };

  dropCall = () => {
    if (this.timerInterval) {
      window.clearInterval(this.timerInterval);
      this.timerInterval = null;
    }
    this.state.activeCall.forEach((item) => {
      socket.call('call_end', item);
      //window.socket.emit('message', {jsonrpc: '2.0', id: '1', method: 'calls.end', params: item})
    });
    this.setState(
      {
        status: 'Конец разговора',
      },
      () =>
        window.setTimeout(() => {
          this.state.activeCall.forEach((item) => {
            this.props.dropCall(item.source_inner_id);
          });
          this.props.removeWidget('activeCall');
        }, 1000)
    );
  };

  render() {
    return (
      <Content className={this.props.className}>
        <TopPanel>
          <TypeIcon>{this.getSourceType()}</TypeIcon>
          <CallStatus>{this.getCallStatus()}</CallStatus>
          <DropCall onClick={this.dropCall}>
            <img src="/images/audio-2.png" alt="" />
          </DropCall>
        </TopPanel>
        <BottomPanel>{this.getSourceNumber()}</BottomPanel>
      </Content>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    calls: store.calls.calls,
  };
};

export default connect(mapStateToProps, {
  dropCall,
  removeWidget,
})(ActiveCall);
