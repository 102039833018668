import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { injectGlobal } from 'styled-components';
import ReactNotifications from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import './index.css';
import App from './app';
import store from './store/store';

import './config';

if (config.theme?.body?.background) {
  injectGlobal`
  body {
    background: ${config.theme.body.background};
  }
`;
}

//console.log('🚀 ~ checkUserToken ~ 0', 0);

ReactDOM.render(
  <Provider store={store}>
    <React.Fragment>
      <ReactNotifications />
      <App />
    </React.Fragment>
  </Provider>,
  document.getElementById('root')
);
