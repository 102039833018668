import { ADD_OPENED_WINDOW, REMOVE_OPENED_WINDOW } from '../constants'

const windowsReducer = (state, action) => {

  const defaultState = {
    openedWindows: {},
    newWindow: ''
  }

  if(!state){
    state = defaultState
  }

  const newOpenedWindows = {...state.openedWindows}

  switch(action.type){
    case ADD_OPENED_WINDOW:
      newOpenedWindows[action.data.id] = action.data
      return{
        ...state,
        openedWindows: newOpenedWindows,
        newWindow: action.data.id
      }
    case REMOVE_OPENED_WINDOW:
      delete newOpenedWindows[action.id]
      return{
        ...state,
        newWindow: '',
        openedWindows: newOpenedWindows
      }
    default:
      return state
  }
}

export default windowsReducer
