import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { socket } from 'api';
import { user } from 'services/user';
import services from 'services';
import { BlueButton, RedButton } from 'components/shared/buttons';
import { setUnrecognized } from 'store/actions/events';
import { CallButtons } from '../call-buttons/call-buttons';
import { Items } from './items';
import { Content, Title, Buttons, Inner, PlateInfo } from './unrecognized.styled';
import { Plate } from './plate';

export const Unrecognized = (props) => {
  const { data, id } = props;
  const event = data;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const plateMean = event.data?.vehicle_plate_mean;

  const afterSetPlate = () => {
    // props.getData();
    setLoading(false);
  };

  useEffect(() => {
    socket.on('set_vehicle_plate', afterSetPlate);
    return () => {
      socket.off('set_vehicle_plate', afterSetPlate);
    };
  }, []);

  if (!id) {
    return null;
  }

  /**
   * allow ride
   */
  const ok = () => {
    const msg = { target: id, user_id: user.getId() };
    socket.call('allow_payment_mean', msg);
    cancel();
  };

  /**
   * cancel ride
   */
  const cancel = () => {
    socket.call('confirm_event_by_id', { id: event.id });
    dispatch(setUnrecognized(null));
  };

  /**
   * change plate
   * @param {*} value
   */
  const changePlate = (value) => {
    setLoading(true);
    socket.call('set_vehicle_plate', { target: event.source, vehicle_plate: value });
  };

  const msg = event.message ?? 'номер распознан некорректно';
  const date = services.formatDateTime(event.created_at);

  return (
    <Content>
      <CallButtons rackId={id} style={{}} />
      <Title>
        <span style={{ margin: 0 }}>{date}</span> &nbsp; / &nbsp; <span>{event.source_name}:</span> {msg}
      </Title>
      <Inner>
        <Items event={event} rackId={id} />
        {plateMean && (
          <PlateInfo>
            <Plate disabled title="ГРЗ в СО" loading={loading} value={plateMean} change={changePlate} />
          </PlateInfo>
        )}
        <Buttons>
          <RedButton className="big" onClick={cancel}>
            Запретить
          </RedButton>
          <BlueButton className="big" onClick={ok}>
            Разрешить
          </BlueButton>
        </Buttons>
      </Inner>
    </Content>
  );
};
