import React, { Component } from 'react';
import moment from 'moment';
//import styled from "styled-components";

import VideoBlockUrls from './VideoBlockUrls';
import { VideoBlockCap, TwoVideosPlace, Refresh } from './styledComponents';

class AppVideos extends Component {
  state = {
    createtime: 1,
  };

  componentDidMount = () => {
    const createtime = moment().unix();
    this.setState({ createtime: createtime });
  };

  /**
   * refresh video
   */
  refresh = () => {
    const createtime = moment().unix();
    this.setState({ createtime: createtime });
  };

  render() {
    const { createtime } = this.state;
    const { cams, id, cl } = this.props;

    // if no cams
    if (cams === null || !cams.length) {
      return (
        <VideoBlockCap
          className={cl}
          style={{
            height: '310px',
            width: '500px',
          }}
        />
      );
    }

    return (
      <TwoVideosPlace>
        <Refresh onClick={this.refresh} />
        <VideoBlockUrls
          createtime={createtime}
          rackId={id}
          type="one_doubled"
          camHeight="310px"
          //cams={["face", "main"]}
          cams={cams}
        />
      </TwoVideosPlace>
    );
  }
}

export default AppVideos;
