import styled from 'styled-components';

import { BlueButton } from 'components/shared/buttons';
import { ControlPanel } from '../styledComponents';

export const StyledControlPanel = styled(ControlPanel)`
  padding: 30px 20px;
`;

export const OpenGateButton = styled(BlueButton)`
  display: flex;
  width: 210px;
  position: relative;
  height: 45px !important;
  flex-shrink: 0;
  &.working{
    cursor: not-allowed;
    background: #1e85df !important;
  }
  &.working::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.6) url(/images/loader.svg) no-repeat 50% 50%;
    background-size: 35px auto;
    border-radius: 5px;
  }
`;

export const Buttons = styled.div`
  margin: -8px 0 0;
`;
