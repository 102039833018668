import styled from 'styled-components';

import { WarningWrapper } from 'components/events/Event';

export const EventWindowWrapper = styled.div`
  position: relative;
  width: 520px;
  min-height: 400px;
  border-radius: 4px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.4);
  background-color: #1e2125;
  border: ${(props) => {
    switch (props.type) {
      case 'critical':
        return 'solid 2px #e70f2c';
      case 'notification':
        return 'solid 2px #ff8322';
      case 'signal':
      default:
        return 'solid 2px #d9c969';
    }
  }};
  padding: 25px;
  box-sizing: border-box;
`;
export const TopInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  img {
    width: 40px;
  }
`;
export const TimeBlock = styled.div`
  width: 398px;
  height: 38px;
  border-radius: 2px;
  padding: 0 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => {
    switch (props.type) {
      case 'critical':
        return '#e70f2c';
      case 'notification':
        return '#ff8322';
      case 'signal':
      default:
        return '#d9c969';
    }
  }};
`;
export const Text = styled.div`
  font-size: 20px;
  color: ${(props) => {
    switch (props.type) {
      case 'critical':
        return '#e70f2c';
      case 'notification':
        return '#ff8322';
      case 'signal':
      default:
        return '#d9c969';
    }
  }};
  margin-bottom: 30px;
`;
export const RackInfo = styled.div`
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  ${WarningWrapper} {
    display: none;
  }
`;
export const Close = styled.img`
  position: absolute;
  cursor: pointer;
  width: 20px;
  top: 4px;
  right: 4px;
`;
export const Submit = styled.div`
  cursor: pointer;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 15px;
  width: 128px;
  height: 30px;
  border-radius: 20px;
  background-image: linear-gradient(#3ea5ff, #3ea5ff), linear-gradient(to bottom, rgba(255, 255, 255, 0.5), #00396c 98%);
  box-shadow: 0 1px 1px 0 #1c4a7b;
`;

export const SubmitAll = styled(Submit)`
  width: 150px;
  background-image: linear-gradient(#f74747, #f74747), linear-gradient(to bottom, rgba(255, 255, 255, 0.5), #f74747 98%);
  margin-top: 20px;
`;
