import { useEffect } from 'react';

import { RefreshFilter } from 'libs/ui';
import { useStateStorage } from './hook-use-state-storage';

export const useFilterRefresher = (name, def, action, storage, timeout = 5000) => {
  const [active, setActive] = useStateStorage(name, def, storage);

  /**
   * change checkbox
   * @param {*} event
   */
  const change = (event) => {
    setActive(event.target.checked);
  };

  /**
   * action
   */
  useEffect(() => {
    let timer;
    if (active) {
      timer = setInterval(() => {
        action();
      }, timeout);
      action();
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [active]);

  const input = <RefreshFilter value={active} change={change} />;

  return [active, input];
};
