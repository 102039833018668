import styled, { css} from 'styled-components';

export const FormGroup = styled.div`
  margin: 0 0 40px;
  width: 100%;
  position: relative;
  span{
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;    
    color: red;
    font-size: .875rem;
    margin: 3px 0 0;
  }
  ${props => props.error && css`
    input {
      border-color: red;
    }
  `}
`;

export const Input = styled.input`
  font-size: 20px;
  padding: 7px 12px;
  min-width: 320px;
  height: 40px;
  border: 1px solid rgb(190, 190, 190);
  color: rgb(255, 255, 255);
  background-color: rgb(79, 87, 97);
  border-radius: 4px;
`;
