import styled, { css } from 'styled-components';

export const WarningContainer = styled.div`
  box-sizing: border-box;
  background: #4f5761;
  color: #fff;
  position: absolute;

  width: 390px;
  left: 0;
  top: 76px;
  border-radius: 8px;
  z-index: 100;
`;
export const Title = styled.div`
  margin: 0 0 15px;
  font-size: 20px;
  ${(props) =>
    props.center &&
    css`
      text-align: center;
    `}
`;

export const Content = styled.div`
  padding: 16px;
`;
export const Buttons = styled.div`
  margin: 15px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
