import styled from 'styled-components';

export const CallWrapper = styled.div`
  width: 37px;
  position: absolute;
  top: -20px;
`;

export const CallIcon = styled.img`
  z-index: 9;
  position: ${(props) => (props.status === 'active' ? 'absolute' : 'relative')};
  ${(props) => {
    if (props.status === 'active') {
      return 'right: -20px; width: 30px;';
    }
  }}
  cursor: pointer;

  @keyframes bounce {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      transform: translate3d(0, -50px, 0);
    }
  }

  animation: bounce 0.5s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
  animation-iteration-count: infinite;
`;

export const CallBlock = styled.div`
  top: -115px;
  right: -205px;
  position: absolute;
  border-radius: 5px;
  background-color: #000000;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.58);
  width: 205px;
  height: 115px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 999;
  img {
    height: 47px;
    width: 47px;
    margin-bottom: 10px;
  }
  span {
    color: white;
    opacity: 0.6;
  }
`;

export const CallIndicator = styled.div`
  pointer-events: none;
  position: absolute;
  top: 23px;
  left: -6px;
  width: 50px;
  height: 88px;
  opacity: 0.4;
  border-radius: 3px;
  background-color: #16b6f0;
`;

export const ButtonBlock = styled.div`
  text-align: center;
`;
