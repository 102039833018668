import React from 'react'
import styled from 'styled-components'


export const IndicatorActive = styled.div`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #47a9fe;
`

export const Indicator = styled((props) => {
  return (
    <div onClick={props.onClick} className={props.className}>
      {props.active ?
        <IndicatorActive />
        :
        null
      }
    </div>
  )
})`
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #262a2e;
  margin-right: 20px;
  border-radius: 50%;
  cursor: pointer;
`