import Cookies from 'js-cookie';
import moment from 'moment';

import getEquipmentType from './getEquipmentType';
import getEquipmentInnerId from './getEquipmentInnerId';
import isOperator from './isOperator';
import { getNodeTitle, getNodeType } from './getNodeTitle';

const camCookieName = 'app-default-cam';

export const getDefaultCam = (rackId) => {
  const name = camCookieName + '_' + rackId;

  let cam = Cookies.get(name);
  if (!cam) {
    cam = 0;
  }
  return cam;
};

export const setDefaultCam = (rackId, cam) => {
  const name = camCookieName + '_' + rackId;
  Cookies.set(name, cam);
};

/**
 *
 * @param {*} videos = {camera_1, ..., target}
 */
export const prepareVideoUrls = (videos) => {
  //console.log("🚀 ~ prepareVideoUrls ", videos);
  let out = [];
  if (!videos || !Object.keys(videos).length) {
    return out;
  }

  Object.keys(videos).forEach((el) => {
    let url = videos[el];
    if (url && url !== null && el.indexOf('camera') !== -1) {
      if (window.config.localver) {
        url = url.replace('/getVideo/', '');
      }
      out.push({ id: el, url: url });
      //out.push({id: el + '_', url: url});
    }
  });

  return out;
};

export const formatDateTime = (val) => {
  const m = moment(val);
  return m.format('ll HH:mm:ss');
};

export const getSignalPriority = (type) => {
  switch (type) {
    case 'critical':
      return 'Высокий';
    case 'notification':
      return 'Средний';
    case 'signal':
    default:
      return 'Низкий';
  }
};

export const getEquipmentTitle = (source_type) => {
  const types = {
    'rack.lane': 'Стойка',
    'rack.payment': 'Паркомат',
    'rack.cashbox': 'Касса',
  };
  return types[source_type] ? types[source_type] : 'Сервер';
};

export const getEquipmentFilterType = (type) => {
  const types = {
    payment_terminal: 'rack.payment',
    cashbox: 'rack.cashbox',
    server: 'rack.server',
  };

  return types[type] ? types[type] : 'rack.lane';
};

export const getNode = (id) => {
  if (!id || !window.config.nodes) {
    return null;
  }
  if (!window.config.nodes[id]) {
    return null;
  }
  return window.config.nodes[id];
};

export { isOperator };

export default {
  getNode,
  getEquipmentFilterType,
  getDefaultCam,
  setDefaultCam,
  prepareVideoUrls,
  getEquipmentType,
  getEquipmentInnerId,
  getEquipmentTitle,
  isOperator,
  getNodeTitle,
  getNodeType,
  formatDateTime,
  getSignalPriority,
};
