import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/ru';

import { socket } from 'api';
import { WindowWrapper } from 'components/shared/window/window-wrapper';
import RackGroup from 'components/shared/rack/RackGroup';
import Rack from 'components/shared/rack/Rack';
import { PaymentTerminalWrapper } from 'components/maps/RacksMap';
import { deactivateEvent, deactivateAllEvents } from 'store/actions/events';
import { closeWindow } from 'store/actions/windows';
import { Close, EventWindowWrapper, RackInfo, Submit, SubmitAll, Text, TimeBlock, TopInfo } from './event-window.styled';

function EventWindow(props) {
  const rackGroups = window.config.objects ? window.config.objects.nodes : [];
  const paymentTerminals = window.config.objects ? window.config.objects.payments : [];

  function getIcon() {
    switch (props.data.data.type) {
      case 'critical':
        return 'error.png';
      case 'notification':
        return 'warning.png';
      case 'signal':
      default:
        return 'signal.png';
    }
  }

  function getRackInfo() {
    //console.log(props.data.data);
    //props.data.data.equipmentType = 'payment_terminal';
    if (props.data.data.equipmentType === 'payment_terminal') {
      const paymentTerminalId = props.data.data.source;
      for (let key in paymentTerminals) {
        if (paymentTerminals[key].id === paymentTerminalId) {
          //console.log(paymentTerminals[key]);
          return (
            <PaymentTerminalWrapper key={'term' + paymentTerminals[key].id}>
              <Rack {...paymentTerminals[key]} />
            </PaymentTerminalWrapper>
          );
        }
      }
    } else {
      for (let key in rackGroups) {
        let rackGroup = rackGroups[key];
        for (let rack = 0; rack < rackGroup.racks.length; rack++) {
          if (rackGroup.racks[rack].id === props.data.data.source) {
            const rackGroupNumber = rackGroup.id;
            const selectedRack = rackGroup.racks[rack];
            const RackInfo = {
              id: rackGroupNumber,
              racks: [selectedRack],
            };
            return <RackGroup {...RackInfo} />;
          }
        }
      }
    }
  }

  function getMessage() {
    return props.data.data.message;
  }

  function close() {
    socket.call('confirm_event_by_id', { id: props.data.data.id });
    //window.socket.emit('message', { method: 'signals.confirm', params: { id: props.data.data.id } })
    props.closeWindow(props.data.id);
    props.deactivateEvent(props.data.data.source, props.data.data.id);
  }

  function closeAll() {
    socket.call('confirm_event_by_rack', { target: props.data.data.source });
    //window.socket.emit('message', { method: 'signals.confirmByRack', params: { id: props.data.data.source } })
    props.closeWindow(props.data.id);
    props.deactivateAllEvents(props.data.data.source);
  }

  function getPriority() {
    switch (props.data.data.type) {
      case 'critical':
        return 'Высокий';
      case 'notification':
        return 'Средний';
      case 'signal':
      default:
        return 'Низкий';
    }
  }

  function getDateTime() {
    return moment(props.data.data.timestamp).format('lll');
  }

  const rightConfirm = window.haveRight('mnemo.confirm_events');

  return (
    <WindowWrapper>
      <EventWindowWrapper type={props.data.data.type}>
        <Close src="/images/close_icon.png" alt="" onClick={() => props.closeWindow(props.data.id)} />
        <TopInfo>
          <img src={'/images/' + getIcon()} alt="" />
          <TimeBlock type={props.data.data.type}>
            <div>{getPriority()}</div>
            <div>{getDateTime()}</div>
          </TimeBlock>
        </TopInfo>
        <Text type={props.data.data.type}>{getMessage()}</Text>
        <RackInfo>{getRackInfo()}</RackInfo>
        {rightConfirm && <Submit onClick={close}>Подтвердить</Submit>}
        {rightConfirm && <SubmitAll onClick={closeAll}>Подтвердить все</SubmitAll>}
      </EventWindowWrapper>
    </WindowWrapper>
  );
}

export default connect(null, {
  closeWindow,
  deactivateEvent,
  deactivateAllEvents,
})(EventWindow);
