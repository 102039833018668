import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

const ZoneInfoWrapper = styled.div`
  box-sizing: border-box;
  position: absolute;
  left: ${(props) => props.left};
  top: ${(props) => props.top};
  width: 115px;
  height: 58px;
  border-radius: 4px;
  background-color: #13161a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
`;
const ZoneIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  font-size: 14px;
  color: #babcbe;
`;
const Number = styled.div`
  font-size: 22px;
  color: #818488;
  font-family: 'Conv_LCDNOVA';
  letter-spacing: 5px;
`;

function ZoneInfo(props) {
  const rightSetStatus = window.haveRight('mnemo.set_zone_status');
  return (
    <ZoneInfoWrapper
      disabled={!rightSetStatus}
      className={props.className}
      onClick={rightSetStatus ? props.onClick : null}
      left={props.left}
      top={props.top}
    >
      <ZoneIcon color={props.color}>{props.zoneId}</ZoneIcon>
      <Number>{props.count}</Number>
    </ZoneInfoWrapper>
  );
}
const mapStateToProps = function (store, props) {
  //console.log(store.boards);
  const count = store.boards.boards[props.zoneId]
    ? store.boards.boards[props.zoneId].maximum - store.boards.boards[props.zoneId].current
    : 0;
  return {
    count: count,
  };
};

export default connect(mapStateToProps)(ZoneInfo);
