import styled from 'styled-components';

export const Content = styled.div`
  padding: 0px;
  box-sizing: border-box;
  position: relative;
  min-height: 400px;
  color: #fff;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100%;
    height: auto;
  }
`;
export const Buttons = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 10px;
  left: 0;
  text-align: center;
  right: 0;
`;
export const Button = styled.button`
  padding: 15px;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  color: #eee;
  border-radius: 3px;
  padding: 0 15px;
  border: 0;
  cursor: pointer;
  background: #48515b;
  margin: 0;
  position: relative;
  transition: 0.3s all ease-in;
  &:hover {
    background: #28313b;
  }
`;
