import { api } from 'api';
import { data } from 'services/data';

export class User {
  isLoggedIn;
  storage;
  permissions;
  rightLogged = {};

  /**
   * create
   * @param {*} param0
   */
  constructor({ storage }) {
    this.storage = storage;
    this.isLoggedIn = false;
  }

  /**
   * set user info from api
   * @param {*} info
   */
  setUserInfo = (info) => {
    if (info.config) {
      window.config = Object.assign({}, window.config, info.config);
      window.config.nodes = data.setNodes(info.config);
      this.permissions = info.config.permissions;
      this.id = info.config.id;
    }
    this.info = info;
    this.isLoggedIn = true;
  };

  getId = () => {
    return this.id;
  };

  /**
   * logout
   */
  logoutUser = () => {
    this.isLoggedIn = false;
    api.logout();
  };

  /**
   * login
   * @param {*} params
   * @returns
   */
  login = async (params) => {
    return await api.call('auth.login', params).then((data) => {
      //console.log('🚀 ~ returnawaitapi.call ~ data:', data);
      this.setUserInfo(data.user_info);
      window.location = '/';
    });
  };

  /**
   * get user info from api
   * @returns
   */
  getInfo = async () => {
    // if no token no call
    if (!this.storage.getItem('token')) {
      return Promise.resolve();
    }

    // get info
    const res = await api.call('users.info').then((data) => {
      this.setUserInfo(data);
    });

    return res;
  };

  /**
   * have right
   * @param {*} right
   * @returns
   */
  haveRight(right) {
    const permissions = this.permissions;
    if (!permissions) {
      return;
    }
    if (!this.rightLogged[right]) {
      this.rightLogged[right] = 1;
    }
    return permissions[right];
  }

  /**
   * is operator
   * @returns
   */
  isOperator() {
    return this.info.access_level === 1;
  }
}
