import { SET_BOARDS_DATA } from '../constants'

export default function boardsReducer(state, action){
  const defaultState = {
    boards: {}
  }
  if(!state){
    state = defaultState
  }
  switch(action.type){
    case SET_BOARDS_DATA:
      return {
        ...state,
        boards: {
          ...state.boards,
          ...action.data
        }
      }
    default:
      return state
  }
}
