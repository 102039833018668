import { SimpleModal, SimpleModalButtons, SimpleModalTitle } from '../simple-modal/simple-modal';
// import Button from '../button/button';
import { RedButton, GrayButton } from 'components/shared/buttons';
import { Content } from './confirm.styled';

export const Confirm = (props) => {
  const { title, children, close, action, closeTitle, actionTitle, actionDisabled } = props;

  const handleCancel = () => {
    close();
  };

  const handleAction = () => {
    action && !actionDisabled && action();
  };

  const actionOther = {};
  if (actionDisabled) {
    actionOther.disabled = true;
  }

  return (
    <SimpleModal className="confirm">
      <SimpleModalTitle>{title}</SimpleModalTitle>
      <Content>{children}</Content>
      <SimpleModalButtons>
        <GrayButton className="btn" onClick={handleCancel}>
          {closeTitle}
        </GrayButton>
        {actionTitle && (
          <RedButton className="btn" onClick={handleAction} {...actionOther}>
            {actionTitle}
          </RedButton>
        )}
      </SimpleModalButtons>
    </SimpleModal>
  );
};

Confirm.defaultProps = {
  closeTitle: 'Отмена',
};
