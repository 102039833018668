import { SET_BOARDS_DATA } from '../constants';

export const setBoardsData = (data) => {
    let obj = {};
    data.forEach((item) => {
        obj[item.zone_id] = item;
    })
    return {
        type: SET_BOARDS_DATA,
        data: obj
    }
}
