import React from 'react';
import { connect } from 'react-redux';

import { socket } from 'api';
import { notify } from 'libs/common';
import Window from 'components/shared/window/window';
import { closeWindow } from 'store/actions/windows';
import { Button, Buttons, Content, Label, TypeSelector } from './index.styled';

class SettingsSIP extends React.Component {
  constructor(props) {
    super(props);

    const sip = window.config.sip_number;
    this.state = {
      sip: sip,
    };
  }

  changeSip = (val) => {
    const out = val.target.value;
    this.setState({ sip: out });
    window.config.sip_number = out;
  };

  close = () => {
    this.props.closeWindow('settingssipwindow');
  };

  save = () => {
    //console.log('🚀 ~ ', this.state.sip);
    socket.call('users.set_sip_number', { number: this.state.sip });
    notify('SIP номер', 'Изменения сохранены', 'success');
    this.close();
  };

  render() {
    const { sip } = this.state;
    const x = (window.innerWidth / 2) - 162;

    return (
      <Window width="324px" height="240px" contentHeight="308px" {...this.props.data} defaultPosition={{ x, y: 40 }}>
        <Content>
          <TypeSelector>
            <Label>SIP номер</Label>
            <input value={sip} onChange={this.changeSip} />
          </TypeSelector>
          <Buttons>
            <Button onClick={this.close}>Отменить</Button>
            <Button onClick={this.save}>Сохранить</Button>
          </Buttons>
        </Content>
      </Window>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, { closeWindow })(SettingsSIP);
