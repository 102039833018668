import React from 'react'

import FieldWrapper from './FieldWrapper'
import { Select, Input, Textarea } from './Fields'
import Date from './Date'
import Multiselect from './Multiselect'
import Autocomplete from './Autocomplete'


const FieldSelector = (props) => {
  const { type, withoutWrapper } = props

  const getInputByType = () => {
    let Component = null
    
    switch(type) {
      case 'text':
      case 'password':
      case 'checkbox':
      case 'radio':
        Component = Input
        break
      case 'dateTime':
      case 'date':
        Component = Date
        break
      case 'select':
        Component = Select
        break
      case 'multiselect':
        Component = Multiselect
        break
      case 'textarea':
        Component = Textarea
        break
      case 'autocomplete':
        Component = Autocomplete
        break
      default:
        throw new Error('There are missing type of Input')
    }
    return Component
  }
  
  
  const Component = getInputByType()
  return (
    withoutWrapper ?
      <Component {...props}/>
      : 
      <FieldWrapper component={Component} {...props} />
  )
}

export default FieldSelector