import { UPDATE_STATUS, UPDATE_STATUSBG, SET_PAYMENT_MEAN_INFO, CLEAR_PAYMENT_MEAN_INFO } from '../constants'

export function updateStatus(msg){
  return{
    type: UPDATE_STATUS,
    data: msg
  }
}

export function updateStatusBg(msg){
  return{
    type: UPDATE_STATUSBG,
    data: msg
  }
}

export function setPaymentMeanInfo(id, info){
  return{
    type: SET_PAYMENT_MEAN_INFO,
    id: id,
    info: info
  }
}

export function removePaymentMeanInfo(id) {
  return{
    type: CLEAR_PAYMENT_MEAN_INFO,
    id: id
  }
}
