import styled, { css } from 'styled-components';

import { Block } from '../styledComponents';
import { BlackButton } from './RackControl.styled';

export const PaymentMeanButton = styled(BlackButton)`
  position: relative;
  left: 165px;
`;

export const PaymentMeanIcon = styled.img`
  position: absolute;
  left: 165px;
  cursor: pointer;
`;
export const PaymentMeanPlace = styled.div`
  ${(props) =>
    props.single &&
    css`
      position: relative;
      height: 57px;
    `}
  ${PaymentMeanIcon} {
    left: 0;
  }
  ${PaymentMeanButton} {
    left: 0;
  }
`;

export const PaymentMeanBlock = styled(Block)`
  background-color: rgba(0, 0, 0, 0.9);
  padding: 10px 15px;
  min-height: 195px;
  width: 350px;
  top: 0px;
  left: 40px;
  z-index: 9;
  color: #6e6e6e;
  overflow: hidden;
  & .no-info {
    text-align: center;
  }
`;
export const PaymentMeanHeader = styled.div`
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  border-bottom: 1px solid #1b1b1b;
  position: relative;
  img {
    cursor: pointer;
    position: absolute;
    right: -5px;
    top: 0px;
  }
  p {
    font-size: 18px;
    margin: 0;
    padding: 0;
    line-height: 1;
  }
  span {
    margin-left: 10px;
    color: #3c9ef4;
  }
`;
export const PaymentMeanBody = styled.div`
  .no-info {
    margin-top: 40px;
    text-align: center;
  }
`;
export const InfoRow = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 15px;
  margin-top: 10px;
  p {
    text-align: left;
    margin-top: 40px;
    margin: 0;
    width: 52%;
  }
  span {
    width: 65%;
    color: #fff;
  }
  div {
    color: #ff8322; //#6e6e6e;
    text-align: left;
    width: 100%;
  }
`;
export const FinalRow = styled(InfoRow)`
  span,
  p {
    color: #3c9ef4;
  }
`;
