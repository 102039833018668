import React from 'react'

import Window from 'components/shared/window/window'
import Board from './Board'


function SingleBoardWindow(props){

  return(
    <Window content={Board} width='480px' height='278px' {...props.data}/>
  )

}

export default SingleBoardWindow
