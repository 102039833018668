import React from 'react';
import { ZoneWrapper, Places, ZoneId } from './Zone.styled';

function Zone(props) {
  //console.log('🚀 ~ Zone ~ props', props);
  return (
    <ZoneWrapper left={props.left} top={props.top}>
      <ZoneId color={props.color}>{props.caption}</ZoneId>
      <Places show={props.showPlaces} color={props.color}>
        {props.places || 0} мест
      </Places>
    </ZoneWrapper>
  );
}

export default Zone;
