export const titles = {
  sensor_loop_1: { title: 'П1', info: 'Петля у стойки' },
  sensor_loop_2: { title: 'П2', info: 'Петля у шлагбаума' },
  sensor_safety: { title: 'ИК', info: 'ИК-датчик безопасности' },
  button_ticket: { title: 'БИЛ', info: 'Кнопка выдачи билета' },
  sensor_gate_op: { title: 'ШО', info: 'Датчик отрытого шлагбаума' },
  sensor_gate_cl: { title: 'ШЗ', info: 'Датчик закрытого шлагбаума' },
  sensor_height_1: { title: 'ВС1', info: 'Первый ИК-датчик высоты' },
  sensor_height_2: { title: 'ВС2', info: 'Второй ИК-датчик высоты' },
  sensor_door_op: { title: 'ДВ', info: 'Датчик открытия двери стойки' },
  sensor_lower_door_op: { title: 'ДВВ', info: 'Датчик открытия верхней двери' },
  sensor_upper_door_op: { title: 'ДВН', info: 'Датчик открытия нижней двери' },
  sensor_gate_door_op: { title: 'ДВШ', info: 'Датчик открытия двери шлагбаума' },
  button_call: { title: 'ВЫЗ', info: 'Кнопка вызова оператора' },
  sensor_door1_op: { title: 'ДВВ', info: 'Датчик открытия верхней двери' },
  sensor_door2_op: { title: 'ДВН', info: 'Датчик открытия нижней двери' },
  relay_sema_pred_g: { title: 'СЗП', info: 'Зелёный светофор перед стойкой' },
  relay_sema_pred_r: { title: 'СКП', info: 'Красный светофор перед стойкой' },
  relay_sema_post_g: { title: 'СЗЗ', info: 'Зелёный светофор за стойкой' },
  relay_sema_post_r: { title: 'СКЗ', info: 'Красный светофор за стойкой' },
  relay_gate_op: { title: 'ШЛО', info: 'Открытие шлагбаума' },
  relay_gate_cl: { title: 'ШЛЗ', info: 'Закрытие шлагбаума' },
  is_online: { title: 'ВСТ', info: 'В сети' },
  relay_main_door: { title: 'РМД', info: 'Реле главной двери' },
  sensor_rack_door: { title: 'СРД', info: 'Реле сенсорной двери' },
};
