import styled from "styled-components";
import config from "config";

const color = config.theme?.header?.color ? config.theme.header.color : '#d7d7d7';

export const InfoWrapper = styled.div`
  display: flex;
  //flex-direction: column;
  //justify-content: space-around;
  margin: 0 20px 0 0;
  /* height: 168px; */
  box-sizing: border-box;
`;
export const Signals = styled.div`
  display: flex;
`;
export const Separator = styled.div`
  height: 1px;
  margin: 0 15px;
  background-color: #313335;
`;
export const InfoGroup = styled.div`
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  p {
    margin: 0 15px 0 0;
    color: ${color};
  }
`;
export const CallsCount = styled.div`
  display: flex;
  align-items: center;
  color: #ff4747;
  img {
    margin-right: 4px;
  }
`;
export const SignalsCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  min-width: 32px;
  line-height: 32px;
  padding: 0px;
  border-radius: 50%;
  font-size: 14px;
  font-weight: bold;
  background-color: ${(props) => {
    if (props.priority === 1) return "#f74747";
    if (props.priority === 2) return "#f88222";
    if (props.priority === 3) return "#e6d56d";
  }};
`;

export const Button = styled.button`
  height: 32px;
  line-height: 32px;
  min-width: 32px;
  font-size: 14px;
  color: #eee;
  border-radius: 16px;
  padding: 0 12px;
  border: 0;
  cursor: pointer;
  background: #38414b;
  margin: 0 0 0 10px;
  position: relative;
`;
