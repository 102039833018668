import styled from 'styled-components';

export const CallErrorWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 442px;
  height: 225px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.5);

  position: relative;
`;
export const BigText = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

export const SmallText = styled.div`
  margin-top: 25px;
  font-size: 15px;
`;
export const Submit = styled.div`
  cursor: pointer;
  margin-left: 78px;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 30px;
  border-radius: 40px;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
  font-size: 16px;
`;
