import React from 'react';

import Rack from './Rack';
import { RackGroupWrapper, RackGroupId, Racks } from './RackGroup.styled';

function RackGroup(props) {
  function getRacks() {
    const racksList = props.racks.map((item, idx) => {
      return <Rack key={'r' + item.id} n={idx} {...item} />;
    });
    return racksList;
  }

  return (
    <RackGroupWrapper left={props.left} top={props.top}>
      <RackGroupId>{props.caption}</RackGroupId>
      <Racks>{getRacks()}</Racks>
    </RackGroupWrapper>
  );
}

export default RackGroup;
