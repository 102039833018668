import styled, { css } from 'styled-components';

export const WarningContainer = styled.div`
  box-sizing: border-box;
  background: #4f5761;
  color: #fff;
  position: absolute;

  width: 390px;
  left: 0;
  top: 276px;
  border-radius: 8px;
  z-index: 100;
`;
export const Title = styled.div`
  margin: 0 0 15px;
  ${(props) =>
    props.center &&
    css`
      text-align: center;
    `}
`;
export const Data = styled.div`
  height: 200px;
  position: relative;
  overflow: hidden;
  background: #333;
  img {
    height: 200px;
    width: auto;
  }
  button {
    position: absolute;
    left: 10px;
    bottom: 13px;
  }
  &.loading {
    background: #4f5761;
  }
  &.loading::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: url(/images/loader.svg) no-repeat 50% 50%;
    background-size: 60px auto;
    margin: -30px 0 0 -30px;
  }
`;

export const Content = styled.div`
  padding: 16px;
`;
export const Buttons = styled.div`
  margin: 15px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Buttons2 = styled.div`
  margin: 15px 0 0;
  padding: 0px 15px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    text-transform: uppercase;
    min-width: 100px;
  }
`;

export const InputPlace = styled.div`
  margin: 5px 0 0;
`;

export const Img = styled.div`
  padding: 50px 0 25px;
  text-align: center;
`;
export const Error = styled.div`
  color: #e73737;
  font-size: 14px;
  line-height: normal;
  margin: 30px 0 0;
  text-align: center;
`;
