export default class Service{
  constructor() {
    this.services = {}
  }

  add(serviceName, service) {
    this.services[serviceName] = service
  }

  unsubscribe(serviceName){
    delete this.services[serviceName]
  }

  call(serviceName, ...params){
    const service = this.services[serviceName]
    if(typeof service === 'function'){
      return service(...params)
    }else{
      throw new Error('Try to call unknown service ' + serviceName)
    }
  }
}
