import styled from 'styled-components';

export const ImgStyled = styled.img`
  /* width: 100%; */
  max-width: 100%;
  /* height: auto; */
  display: inline-block;
  object-fit: contain;
  /* min-height: 270px; */
  &.loading {
    position: relative;
  }
  &.loading::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #eee url('/images/loader.svg') no-repeat 50%;
    background-size: 25px auto;
  }
`;
