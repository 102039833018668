import { useState } from 'react';
import { ImgStyled } from './img.styled';

export const Img = (props) => {
  const { src, title, afterError, ...rest } = props;
  // const [url, setUrl] = useState(src);
  const [alt, setAlt] = useState(title);
  const [loading, setLoading] = useState(true);

  const onLoad = () => {
    // setTitle('ok');
    setLoading(false);
  };

  const onError = () => {
    // setUrl('/images/no-photo.png');
    setAlt('Ошибка загрузки');
    setLoading(false);
    afterError();
  };

  const cl = loading ? 'loading' : '';

  return <ImgStyled className={cl} src={src} alt={alt} title={alt} onLoad={onLoad} onError={onError} {...rest} />;
};
