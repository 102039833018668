// additional functions
window.isObjectEmpty = function (obj) {
  if (obj === this.undefined) {
    return true;
  }
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};


// rights
window.rightLogged = {};
window.haveRight = (right) => {
  const permissions = window.config.permissions;
  if (!permissions) {
    return;
  }
  if (!window.rightLogged[right]) {
    window.rightLogged[right] = 1;
  }
  return permissions[right];
};
