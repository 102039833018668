import React from 'react';

import { socket } from 'api';
import { BlueButton, WhiteButton } from 'components/shared/buttons';
import { WarningContainer, Buttons, Buttons2, Content, Data, Error, Img, InputPlace, Title } from './ConfirmBenefit.styled';

class ConfirmBenefit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mean_id: '',
      mean_type: '',
      vehicle_plate: '',
      isScaned: false,
      photo: '',
      stay_id: '',
      error: '',
      loading: false,
      result: false,
    };

    this.timer = null;
  }

  componentDidMount() {
    //this.getStay();

    this._mounted = true;

    window.socket.on('add_event', this.addEvent);
    window.socket.on('stays.get', this.staysGet);
    window.socket.on('racks.get_photo', this.getPhoto);
    window.socket.on('benefit.add_handicapped', this.getAddHandicapped);
  }

  getAddHandicapped = (msg) => {
    //console.log('🚀 ~ add hand', msg);
    this.setState({
      loading: false,
      result: msg.result,
      error: msg.error,
    });
  };

  getPhoto = (msg) => {
    //console.log('🚀 ~ stays get', msg);
    if (this._mounted && msg.target === this.props.id) {
      this.setState({ photo: msg.content, loading: false });
    }
  };

  staysGet = (msg) => {
    //console.log('🚀 ~ stays get', msg);
    this.setState({ vehicle_plate: msg.vehicle_plate, stay_id: msg.id, isScaned: true });
  };

  /**
   * event after ticket read
   * @param {*} msg
   */
  addEvent = (msg) => {
    //console.log('🚀 ~ window.socket.on ~ msg', msg);
    if (this._mounted && msg.source === this.props.id && msg.data && msg.data.method === 'barcode.ticket_read') {
      this.setState({ mean_id: msg.data.mean_id, mean_type: msg.data.mean_type }, () => {
        this.getStay();
      });
    }
  };

  /**
   * get stay info
   */
  getStay = () => {
    const { mean_type, mean_id } = this.state;
    socket.call('stays.get', { mean_type: mean_type, mean_id: mean_id });
  };

  componentWillUnmount() {
    this._mounted = false;

    window.socket.off('add_event', this.addEvent);
    window.socket.off('stays.get', this.staysGet);
    window.socket.off('racks.get_photo', this.getPhoto);
    window.socket.off('benefit.add_handicapped', this.getAddHandicapped);
  }

  setVehiclePlate = (e) => {
    //console.log('🚀 ~ e', e.target.value);
    this.setState({ vehicle_plate: e.target.value });
  };

  useBenefit = () => {
    const { photo, vehicle_plate, stay_id } = this.state;
    this.setState({ loading: true }, () => {
      socket.call('benefit.add_handicapped', {
        stay_id: stay_id,
        vehicle_plate: vehicle_plate,
        comment: '',
        photo: photo,
      });
    });
  };

  takeFoto = () => {
    const { takeFoto } = this.props;

    this.setState({ loading: true }, () => {
      takeFoto();
    });
  };

  reset = () => {
    this.setState({ error: '', result: false, mean_id: '', mean_type: '', vehicle_plate: '', isScaned: false, photo: '', stay_id: '' });
  };

  render() {
    const { vehicle_plate, isScaned, photo, loading, result, error } = this.state;
    const title = isScaned
      ? vehicle_plate
        ? 'Предоставление льготы для автомобиля с ГРЗ'
        : 'Предоставление льготы для автомобиля'
      : 'Ожидание считывания билета';
    let disabled = !isScaned ? true : undefined;
    let disabled1 = !photo ? true : undefined;
    if (loading) {
      disabled = true;
      disabled1 = true;
    }
    const isLoading = !isScaned;
    const cl = isLoading || loading ? 'loading' : '';
    //const img = window.config.network.images_url + photo;
    const img = 'data:image/png;base64, ' + photo;
    //console.log('🚀 ~ ConfirmBenefit ~ disabled', disabled);

    if (result) {
      return (
        <WarningContainer>
          <Content>
            <Title center>Льгота применена</Title>
            <Img>
              <img src="/images/success.png" alt="" />
            </Img>
          </Content>
          <Buttons2>
            <WhiteButton onClick={this.props.close}>Ок</WhiteButton>
          </Buttons2>
        </WarningContainer>
      );
    }
    if (error) {
      return (
        <WarningContainer>
          <Content>
            <Title center>Льгота не применена. Попробуйте ещё раз.</Title>

            <Error>{error}</Error>
            <Img>
              <img src="/images/block.png" alt="" />
            </Img>
            <Buttons2>
              <WhiteButton onClick={this.reset}>Ок</WhiteButton>
            </Buttons2>
          </Content>
        </WarningContainer>
      );
    }

    return (
      <WarningContainer>
        <Content>
          <Title>
            {title}
            {isScaned && (
              <InputPlace>
                <input type="text" value={vehicle_plate} onChange={this.setVehiclePlate} />
              </InputPlace>
            )}
          </Title>
          <Data className={cl}>
            {photo && (
              <React.Fragment>
                <img height="200" src={img} alt="" />
                {/*<WhiteButton onClick={takeFoto}>Обновить</WhiteButton>*/}
              </React.Fragment>
            )}
          </Data>
          <Buttons>
            <WhiteButton disabled={disabled} onClick={this.takeFoto}>
              Сделать фото документа
            </WhiteButton>
            <BlueButton disabled={disabled1} onClick={this.useBenefit}>
              Применить льготу
            </BlueButton>
          </Buttons>
        </Content>
      </WarningContainer>
    );
  }
}

export default ConfirmBenefit;
