import { useState, useEffect } from 'react';

export const useStateStorage = (key, defaultValue, storage) => {
  // get default
  const [value, setValue] = useState(() => {
    const val = storage.getItem(key);
    return val || defaultValue;
  });

  // set
  useEffect(() => {
    storage.setItem(key, value);
  }, [key, storage, value]);

  return [value, setValue];
};
