import { Content, Place, SimpleModalButtons, SimpleModalTitle } from './simple-modal.styled';

export { SimpleModalButtons, SimpleModalTitle };

export const SimpleModal = (props) => {
  const { children, ...rest } = props;

  return (
    <Place onClick={(event) => event.stopPropagation()} {...rest}>
      <Content>{children}</Content>
    </Place>
  );
};
