import React from 'react'

export const Select = props => {
  const { className, label, input, children, disabled } = props

  return (
    <div className={className}>
      <label>{label}</label>
      <select {...input} disabled={disabled}>
        {children}
      </select>
    </div>
  )
}

export const Input = props => {
  const { value, input, type, placeholder, className, label, disabled } = props

  return (
    <div className={className}>
      <label>{label}</label>
      <input
        disabled={disabled}
        type={type}
        placeholder={placeholder}
        value={value}
        {...input}
      />
    </div>
  )
}

export const Textarea = props => {
  const { input, placeholder, className, label, disabled } = props

  return (
    <div className={className}>
      <label>{label}</label>
      <textarea
        placeholder={placeholder}
        disabled={disabled}
        {...input}
      />
    </div>
  )
}