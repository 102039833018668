import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { openWindow } from 'store/actions/windows';
import { user } from 'services/user';
import { socket } from 'api';
import { Img } from '../img/img';
import { Plate } from './plate';
import { getPhotoUrl } from './items.func';
import { Content, Title, PhotoPlace, Refresh } from './item.styled';

export const Item = (props) => {
  const { item, source } = props;
  const { title, photo, photo64, plate, refresh } = item;
  const [error, setError] = useState(!photo && !photo64);
  const [photoOk, setPhotoOk] = useState(photo);
  const [photoOk64, setPhotoOk64] = useState(photo64);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const afterSetPlate = () => {
    // props.getData();
    setLoading(false);
  };

  const getRackPhoto = (msg) => {
    if (msg.target === source) {
      setPhotoOk64(msg.content ? msg.content : msg.photo ? getPhotoUrl(msg.photo) : '-');
      setError(false);
    }
  };

  useEffect(() => {
    socket.on('set_vehicle_plate', afterSetPlate);
    socket.on('racks.get_photo', getRackPhoto);
    return () => {
      socket.off('set_vehicle_plate', afterSetPlate);
      socket.off('racks.get_photo', getRackPhoto);
    };
  }, []);

  const showFoto = (photo, title) => {
    dispatch(
      openWindow({
        id: 'foto',
        type: 'foto',
        data: photo,
        header: title,
      })
    );
  };

  const changePlate = (value) => {
    setLoading(true);
    socket.call('set_vehicle_plate', { target: source, vehicle_plate: value });
  };

  const afterError = () => {
    setError(true);
  };

  /**
   * refresh photo
   * @param {*} event
   */
  const refreshPhoto = (event) => {
    event.stopPropagation();
    socket.call('racks.get_photo', { target: source, user_id: user.getId(), content: true });
  };

  const src = photoOk64 ? 'data:image/png;base64,' + photoOk64 : photoOk;
  // console.log('🚀 ~ Photo ~ src:', src);
  const cl = error ? 'error' : '';

  return (
    <Content>
      <Title>{title}</Title>
      <PhotoPlace onClick={() => !error && showFoto(src, title)} className={cl}>
        {refresh && <Refresh onClick={refreshPhoto} />}
        {!error && <Img height={270} src={src} title="..." afterError={afterError} />}
      </PhotoPlace>
      <Plate loading={loading} value={plate} change={changePlate} />
    </Content>
  );
};
