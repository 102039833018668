import { SET_EVENTS_HISTORY, CLEAR_EVENTS_HISTORY } from '../constants'
const eventsHistoryReducer = (state, action) => {
  const defaultState = {
    events: {}
  }
  if(!state){
    state = defaultState
  }

  let newEvents = {...state.events}
  for(let key in action.events){
    newEvents[key] = {...newEvents[key], ...action.events[key]}
  }
  switch(action.type){
    case SET_EVENTS_HISTORY:
      return {
        events: newEvents
      }
    case CLEAR_EVENTS_HISTORY:
      if(action.id){
        return {
          ...state.events,
          [action.id]: {}
        }
      }else{
        return {
          events: {}
        }
      }

    default:
      return state
  }
}

export default eventsHistoryReducer
