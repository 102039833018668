import { useState, useRef } from 'react';
import Calendar from 'rc-calendar';
import ruRU from 'rc-calendar/lib/locale/ru_RU';
import moment from 'moment';
import TimePickerPanel from 'rc-time-picker/lib/Panel';

import { useOutsideClicker } from 'libs/hooks';
import { CalendarBox, DateInput, DateInputBox, InputBox } from './date-select.styled';

const timePickerElement = <TimePickerPanel defaultValue={moment('00:00:00', 'HH:mm')} />;
const timePickerElement2 = <TimePickerPanel defaultValue={moment('23:59:59', 'HH:mm')} />;

export const DateSelect = (props) => {
  const [open, setOpen] = useState(false);

  /**
   * toggler open
   * @returns `
   */
  const toggle = () => setOpen(!open);

  /**
   * set date
   * @param {*} date
   */
  const setDate = (date) => {
    props.setFilter(props.fieldName, date);
  };

  /**
   * close on click outside
   */
  const ref = useRef(null);
  useOutsideClicker(ref, () => {
    setOpen(false);
  });

  const getDisplayedValue = () => {
    return props.value
      ? moment(props.value)
          .format('lll')
          .replace(/[г., ]{4}/, '')
          .replace(/\./, '')
      : '';
  };

  const timeel = props.fieldName.indexOf('_to') === -1 ? timePickerElement : timePickerElement2;

  return (
    <InputBox>
      <DateInputBox onClick={toggle}>
        <DateInput
          value={getDisplayedValue()}
          mergedLeftInput={props.mergedLeftInput}
          mergedRightInput={props.mergedRightInput}
          placeholder="01 авг 2000 12:30"
          name="date"
        />
      </DateInputBox>
      <div className="POPUP" ref={ref}>
        <CalendarBox style={{ display: open ? 'block' : 'none' }}>
          <Calendar
            value={moment(props.value)}
            selectedValue={moment(props.value)}
            timePicker={timeel}
            onSelect={(date) => {
              setDate(date);
            }}
            onChange={(date) => {
              setDate(date);
            }}
            locale={ruRU}
            className={'calendar'}
            showDateInput={false}
          />
        </CalendarBox>
      </div>
    </InputBox>
  );
};
