export const SET_EVENT = 'SET_EVENT';
export const SET_EVENTS_LIST = 'SET_EVENTS_LIST';
export const ADD_OPENED_WINDOW = 'ADD_OPENED_WINDOW';
export const REMOVE_OPENED_WINDOW = 'REMOVE_OPENED_WINDOW';
export const DEACTIVATE_EVENT = 'DEACTIVATE_EVENT';
export const DEACTIVATE_ALL_EVENTS = 'DEACTIVATE_ALL_EVENTS';
export const SET_CALL = 'SET_CALL';
export const SET_CALL_ACTIVE = 'SET_CALL_ACTIVE';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const UPDATE_STATUSBG = 'UPDATE_STATUSBG';
export const ADD_WIDGET = 'ADD_WIDGET';
export const REMOVE_WIDGET = 'REMOVE_WIDGET';
export const DROP_CALL = 'DROP_CALL';
export const SET_EVENTS_HISTORY = 'SET_EVENTS_HISTORY';
export const CLEAR_EVENTS_HISTORY = 'CLEAR_EVENTS_HISTORY';
export const PLAY_SOUND = 'PLAY_SOUND';
export const STOP_SOUND = 'STOP_SOUND';
export const SET_PAYMENT_MEAN_INFO = 'SET_PAYMENT_MEAN_INFO';
export const SET_BOARDS_DATA = 'SET_BOARDS_DATA';
export const CLEAR_PAYMENT_MEAN_INFO = 'CLEAR_PAYMENT_MEAN_INFO';
export const SET_EVENT_UNRECOGNIZED = 'SET_EVENT_UNRECOGNIZED';
