import styled from 'styled-components';

export const Buttons = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonStyled = styled.button`
  height: 55px;
  background: #3ea5ff;
  box-shadow: 0 1px 1px 0 #1d4a7b;
  font-size: 20px;
  line-height: 0.75;
  width: 100%;
  border: 0;
  cursor: pointer;
  border-radius: 5px;
  color: #fff;
  transition: 0.3s all ease-out;
  :hover {
    background: #1e85df;
  }
`;
