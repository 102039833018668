import styled, { css } from 'styled-components';

export const FilterActions = styled.div`
  position: absolute;
  bottom: 0px;
  right: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 11;

  & .with-refresh {
    position: relative;
    bottom: auto;
    right: auto;
  }
  ${(props) =>
    props.active &&
    css`
      & .with-refresh {
        opacity: 0.5;
      }
      & .with-refresh::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        cursor: not-allowed;
        z-index: 11;
        background: rgba(0, 0, 0, 0.01);
      }
    `}
`;

export const Label = styled.label`
  margin: 0 10px 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #a7abb0;
  cursor: pointer;
  span {
    display: block;
    padding-left: 5px;
  }
  input {
    cursor: pointer;
    width: 14px;
    height: 14px;
  }
`;
