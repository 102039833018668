import React from 'react';

import { LoginForm } from './login-form';
import { Content } from './auth.styled';
import { user } from 'services/user';

export const Auth = () => {
  /**
   * user login
   * @param {*} values
   * @returns
   */
  const actionLogin = async (data, helpers) => {
    await user.login(data).catch((errors) => {
      helpers.setErrors({ api: errors });
    });
  };

  //console.log('🚀 ~ render auth:', user);  

  return (
    <Content>
      <img height="100" src="/images/auth-logo.png" alt="" />
      <LoginForm onSubmit={actionLogin} />
    </Content>
  );
};

export default Auth;
