import React, { Component } from 'react'
import styled from 'styled-components'
import { change } from 'redux-form'


const Wrapper = styled.div`
  position: relative;
`
const Popup = styled.div`
  max-height: 230px;
  overflow-y: auto;
  position: absolute;
  top: 38px;
  width: 100%;
  background-color: #fff;
  border: solid 1px #bebebe;  
  border-radius: 4px;
  z-index: 11;
  ul {
    li {
      padding: 5px;
      list-style: none;
      cursor: pointer;
      :hover {
        background-color: #3ea5ff;
        color: #fff;
      }
    }
  }
`
// this component is not universal now
// need rework getList method and some minor changes
export default class Autocomplete extends Component {
  state = { list: [] }
  
  getListItems = (list) => {
    const { name } = this.props.input
    const { dispatch, form } = this.props.meta

    return list.map(item => {
      return (
        <li
          key={item.id}
          onClick={() => dispatch(change(form, name, item.full_name))}
        >
          {item.full_name}
        </li>
      )
    })
  }

  getTariffList = (value) => {
    const { getList, setAutocompleteList } = this.props
    const { requestParams: { filterParams, sortParams } } = this.props

    if (value) {
      filterParams.full_name = value

      getList(filterParams, sortParams, list => {
        this.setState({ list: list })
        setAutocompleteList(list)
      })
    } else {
      this.setState({ list: {} })
    }
  }

  render() {
    const { 
      className, 
      placeholder,
      disabled,
      input,
      meta
    } = this.props
    return (
      <Wrapper className={ className }>
        {this.props.label ?
          <label>{this.props.label}</label>
          : null
        }
        <input
          {...input}
          type='text'
          autoComplete="off"
          disabled={disabled}
          placeholder={ placeholder }
          onChange={ event => {
            input.onChange(event.target.value)
            this.getTariffList(event.target.value)
          }}
          onBlur={ event => setTimeout(input.onBlur, 150) } 
        />
        {
          (meta.active && Object.keys(this.state.list).length)  ? 
            <Popup>
              <ul>{this.getListItems(this.state.list)}</ul>
            </Popup>
          : null
        }
      </Wrapper>
    )
  }
}