import styled from 'styled-components';

export const CalendarInterval = styled.div`
  display: flex;
  justify-content: space-around;
  p {
    cursor: pointer;
    color: #3ea5ff;
    font-size: 13px;
    text-align: left;
  }
  p:hover {
    /*color: #6200ff; */
    color: #fff;
  }
`;
