import React from 'react';
import { connect } from 'react-redux';

import { socket } from 'api';
import { CashTable, Content, Nominal, Center, NominalTitle } from './denominations2.styled';
import Window from 'components/shared/window/window';

class Denominations2 extends React.Component {
  nominals = {};

  constructor(props) {
    super(props);

    this.appsArray = window.config.objects.payments;
    const apps = {};
    this.appsArray.forEach((app) => {
      apps[app.id] = { title: app.caption };
    });
    const appsSorted = {};
    Object.keys(apps)
      .sort()
      .forEach((el) => {
        appsSorted[el] = apps[el];
      });

    this.state = {
      apps: appsSorted,
      ready: 0,
      ok: 0,
    };
  }

  componentDidMount() {
    //console.log('🚀 did mount');
    this.getAppsInfo();
    const add = 0;

    // on get app state
    window.socket.on('get_state', (msg) => {
      //console.log("🚀 ~ app state", msg);
      const cnt = this.state.ready;
      const out = Object.assign({}, this.state.apps);
      const disabled = msg.mode === 'offline' || msg.cashbox === undefined; // || (msg.state && msg.state === "app_not_work");
      //if (msg.cashbox_sum) {

      const cntok = this.state.ok;
      if (out[msg.target]) {
        out[msg.target].disabled = disabled;
        if (disabled) {
          out[msg.target].cashbox = [];
          out[msg.target].cashbox_sum = 0;
          out[msg.target].payout = [];
          out[msg.target].payout_sum = 0;
        } else {
          out[msg.target].cashbox = msg.cashbox;
          out[msg.target].cashbox_sum = msg.cashbox_sum / 100;
          out[msg.target].payout = msg.payout;
          out[msg.target].payout_sum = msg.payout_sum / 100;
        }

        this.setState({ apps: out, ready: cnt + 1 + add, ok: cntok + 1 + add });
      } else {
        console.log('🚀 ~ not found app', msg);
      }
    });
  }

  // get apps state
  getAppsInfo = () => {
    // const { apps } = this.state; //window.config.objects.payments;
    // console.log('🚀 ~ componentDidMount ~ apps:', apps.length);
    this.appsArray.forEach((app) => {
      //console.log("🚀 ~ apps.forEach ~ app", app);
      socket.call('get_state', { target: app.id, user_id: window.user.getId() });
    });
  };

  /**
   * cmp ready
   * @returns
   */
  isReady = () => {
    const { ready } = this.state;
    return ready >= this.appsArray.length; //window.config.objects.payments
  };

  /**
   * get apps table headers
   * @returns
   */
  getAppsHeaders = () => {
    const { apps } = this.state;
    return Object.keys(apps).map((el) => {
      const app = apps[el];
      const cl = app.disabled ? 'disabled' : '';
      return (
        <th className={cl} key={el}>
          № {app.title}
        </th>
      );
    });
  };

  /**
   * get total cash in apps
   * @returns
   */
  getTotals = (type) => {
    const { apps } = this.state;
    const typeOk = type + '_sum';
    return Object.keys(apps).map((el) => {
      const v = apps[el].disabled ? '-' : apps[el][typeOk];
      return (
        <th key={el} className="gray">
          <Nominal>{v}</Nominal>
        </th>
      );
    });
  };

  /**
   * get nominals counts
   * @returns
   */
  getCounts = (type) => {
    const nominals = this.getNominals(type);
    // console.log("🚀 ~", this.nominals);

    return nominals.map((item, idx) => {
      const items = this.getNominalCounts(item, type);
      const cl = '' + (this.nominals[item * 100].description.indexOf('bill') !== -1 ? 'bill' : 'coin');
      return (
        <tr key={idx}>
          <td>
            <NominalTitle>
              {item}
              <i className={cl}></i>
            </NominalTitle>
          </td>
          {items}
        </tr>
      );
    });
  };

  /**
   * get counts for nominal
   * @param {*} nominal
   * @returns
   */
  getNominalCounts = (nominal, type) => {
    const { apps } = this.state;
    const out = [];
    Object.keys(apps).forEach((key) => {
      const el = apps[key];
      //console.log('🚀 ~ Object.keys ~ el', el);
      let a;
      if (el[type]) {
        a = el[type].find((el1) => {
          return el1.value === nominal * 100;
        });
        if (el.disabled) {
          a = null;
        }
      } else {
        a = null;
      }

      const icon =
        type === 'payout' && a ? (
          a.count === 0 ? (
            <img src="/images/warning-hight-icon.png" alt="" />
          ) : a.count < 50 ? (
            <img src="/images/warning-icon.png" alt="" />
          ) : null
        ) : null;
      out.push(
        <td key={key}>
          <Nominal>
            {a ? a.count : '-'}
            {icon}
          </Nominal>
        </td>
      );
    });
    return out;
  };

  /**
   * get nominals
   * @returns
   */
  getNominals = (type) => {
    const { apps } = this.state;
    const out = {},
      out1 = [];
    Object.keys(apps).forEach((key) => {
      const el = apps[key];
      if (el[type]) {
        el[type].forEach((el1) => {
          if (el1.enabled && el1.count) {
            out[el1.value] = el1;
          }
        });
      }
    });

    this.nominals = out;
    Object.keys(out).forEach((el) => {
      out1.push(el / 100);
    });
    out1.sort((a, b) => {
      return a - b;
    });
    return out1;
  };

  /**
   * show table
   * @param {*} type
   * @returns
   */
  showTable = (type) => {
    const title = type === 'cashbox' ? 'Стекер' : 'Сдача';
    const totals = this.getTotals(type);
    const counts = this.getCounts(type);

    return (
      <CashTable>
        <thead>
          <tr>
            <th style={{ textAlign: 'left', paddingLeft: '0' }}>{title}, ₽</th>
            {totals}
          </tr>
        </thead>
        <tbody>{counts}</tbody>
      </CashTable>
    );
  };

  /**
   * render
   * @returns
   */
  render() {
    // loading
    if (!this.isReady()) {
      return (
        <Window
          header="Сводная таблица по АПП"
          width="640px"
          //height="740px"
          //contentHeight="708px"
          {...this.props.data}
        >
          <Content>
            <Center>
              <img src="/images/loader.svg" alt="..." />
            </Center>
          </Content>
        </Window>
      );
    }

    // empty
    const { ok } = this.state;
    if (!ok) {
      return (
        <Window
          header="Сводная таблица по АПП"
          width="640px"
          //height="740px"
          //contentHeight="708px"
          {...this.props.data}
        >
          <Content>
            <Center>Активные АПП отсутствуют</Center>
          </Content>
        </Window>
      );
    }

    // show
    const appsHeaders = this.getAppsHeaders();
    const cnt = ok;
    let w = 70 + 120 * (cnt + 1); //"1240px";
    if (w > 1740) {
      w = 1740;
    }
    w += 'px';
    // console.log('🚀 ~ render ~ w:', w, ok);

    return (
      <Window header="Сводная таблица по АПП" width={w} {...this.props.data}>
        <Content>
          <CashTable>
            <thead>
              <tr>
                <th style={{ textAlign: 'left', paddingLeft: '0' }}>Паркоматы</th>
                {appsHeaders}
              </tr>
            </thead>
          </CashTable>
          {this.showTable('cashbox')}
          {this.showTable('payout')}
        </Content>
      </Window>
    );
  }
}

const mapStateToProps = (store) => {
  let status = store.equipment;
  return {
    status: status,
  };
};

export default connect(mapStateToProps)(Denominations2);
