import React from 'react';

import { Table2 } from '../styledComponents';

const CashTable2 = ({ /*total,*/ items }) => {

  if (items === undefined) {
    return null;
  }

  //: 'неизвестно'
  const getRows = (items/*, total*/) => {
    //const cnt = items.length;
    return items.map((item, idx) => {
      //console.log(item.description)
      
      const cl = '' + ((item.description.indexOf('bill') !== -1) ? 'bill' : 'coin');
      const value = Math.round(item.value / 100);
      return (
        <tr key={idx}>
          <td><i className={cl}></i>{value}</td>
          <td>{item.count}</td>
        </tr>
      );
    });
  }

  return (
    <Table2>
      <thead>
        <tr>
          <th>Номиналы/₽</th>
          <th>Кол-во/шт.</th>
        </tr>
      </thead>
      <tbody>
        {getRows(items/*, total*/)}
      </tbody>
    </Table2>
  )
}

export default CashTable2;