import styled from 'styled-components';

export const Input = styled.input`
  color: white;
  box-sizing: border-box;
  font-size: 15px;
  padding: 9px;
  background-color: #4f5761;
  height: 40px;
  display: ${(props) => {
    if (props.mergedLeftInput || props.mergedRightInput) return 'inline-block';
  }};
  border: solid 1px #a3b0c0;
  border-right: ${(props) => {
    if (props.mergedLeftInput) return 0;
  }};
  border-radius: ${(props) => {
    if (props.mergedLeftInput) return '4px 0 0 4px';
    else if (props.mergedRightInput) return '0 4px 4px 0';
    else return '4px';
  }};
`;

export const InputBox = styled.div`
  display: inline-block;
`;

export const DateInputBox = styled.div`
  position: relative;
  width: 160px;
`;

export const DateInput = styled(Input).attrs({
  disabled: 'disabled',
})`
  width: 160px;
  cursor: pointer;
`;

export const CalendarBox = styled.div`
  position: absolute;
`;
