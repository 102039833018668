import React from 'react';
import { connect } from 'react-redux';

import Window from 'components/shared/window/window';
import { closeWindow } from 'store/actions/windows';
import { Content, Button, Buttons, Label, TypeSelector } from './settings.styled';

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: '1',
      tpl: '',
    };
  }

  changeType = (val) => {
    //console.log("🚀 ~ changeType ~ val", val.target.value);
    this.setState({ type: val.target.value });
  };

  changeTpl = (val) => {
    //console.log("🚀 ~ changeType ~ val", val);
    this.setState({ tpl: val.target.value });
  };

  close = () => {
    this.props.closeWindow('settingswindow');
  };

  save = () => {
    console.log('🚀 ~ ', this.state.tpl);
  };

  render() {
    const { type, tpl } = this.state;
    const max1 = type === '1' ? 50 : 29;
    //console.log("🚀 ~ render ~ max1", max1);

    return (
      <Window width="424px" height="540px" contentHeight="508px" defaultPosition={{ x: 900, y: 40 }} {...this.props.data}>
        <Content>
          <TypeSelector>
            <Label>Тип принтера</Label>
            <select onChange={this.changeType} value={type}>
              <option value={1}>Custom VKP-80</option>
              <option value={2}>Custom KPM-150 (бумага с метками)</option>
              <option value={3}>Custom KPM-150 (бумага без меток)</option>
            </select>
          </TypeSelector>
          <TypeSelector>
            <Label>Шаблон ({max1}x30)</Label>
            <textarea style={{ width: max1 + 'ch' }} cols={max1} rows={30} wrap="HARD" value={tpl} onChange={this.changeTpl} />
          </TypeSelector>
          <Buttons>
            <Button onClick={this.close}>Отменить</Button>
            <Button onClick={this.save}>Сохранить</Button>
          </Buttons>
        </Content>
      </Window>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, { closeWindow })(Settings);
