import styled from 'styled-components';

export const Place = styled.div`
  position: absolute;
  top: 45px;
  z-index: 10;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.32);
  background: #5f6872;
  color: #fff;
  padding: 15px;
  max-width: 100%;
  min-width: 280px;
  border-radius: 4px;
  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #5f6872;
    top: -9px;
    left: 50px;
  }
`;

export const Content = styled.div``;

export const SimpleModalButtons = styled.div`
  background-color: #444;
  margin: 15px -15px -15px;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  button {
    min-width: 60px;
  }
`;

export const SimpleModalTitle = styled.div`
  font-size: 18px;
  line-height: normal;
  margin: 0 0 15px;
`;
