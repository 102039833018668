import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 -10px;
  min-height: 179px;
  &.withLoading {
    opacity: 0;
  }
`;
