import React from 'react';
import styled from 'styled-components';

import { Indicator } from './Indicator';
import RackNotification from './rack/Notification';
import AppNotification from './paymentRack/Notification';

import { ReloadPopup as Popup, ReloadPopupArrow, ReloadHeader, ReloadSection, ReloadButtons } from './styledComponents';
import { BlueButton, WhiteButton } from '../../../shared/buttons';

const DoubleControllerWrapper = styled.span`
  background: #000;
  display: block;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  margin-left: -10px;
  padding-left: 10px;
  margin-right: -10px;
`;

const RadioButtonsPopup = ({
  type,
  header,
  buttons,
  currentState,
  togglePopup,
  submit,
  notification,
  sendControlMsg,
}) => {
  const getButtons = () => {
    if (Array.isArray(buttons)) {
      return buttons.map((elem, index) => {
        if (Array.isArray(elem)) {
          const wrappedButtons = elem.map((button, index) => {
            return getReloadSection(button, index);
          });
          return <DoubleControllerWrapper>{wrappedButtons}</DoubleControllerWrapper>;
        } else {
          return getReloadSection(elem, index);
        }
      });
    }
  };

  const getReloadSection = (elem, key) => {
    return (
      <ReloadSection key={key}>
        <Indicator
          active={currentState === elem.msg}
          onClick={() => {
            elem.func(elem.msg);
          }}
        />{' '}
        {elem.text}
      </ReloadSection>
    );
  };

  const getNotification = () => {
    return type !== 'payment_terminal' ? (
      <RackNotification
        notification={notification}
        sendControlMsg={sendControlMsg}
        toggleReloadPopup={togglePopup}
        reloadType={currentState}
      />
    ) : (
      <AppNotification notification={notification} sendControlMsg={sendControlMsg} toggleReloadPopup={togglePopup} />
    );
  };

  return (
    <Popup>
      <ReloadPopupArrow />
      <ReloadHeader>{header}</ReloadHeader>
      {getButtons()}
      <ReloadButtons>
        <WhiteButton onClick={togglePopup}>Отменить</WhiteButton>
        <BlueButton onClick={submit}>Отправить</BlueButton>
      </ReloadButtons>
      {getNotification()}
    </Popup>
  );
};

export default RadioButtonsPopup;
