import styled from 'styled-components';

export const Place = styled.div`
  position: absolute;
  /* top: 30%; */
  z-index: 10;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.32);
  background: #4f5761;
  color: #fff;
  padding: 15px;
  max-width: 100%;
  min-width: 280px;
  border-radius: 4px;
  transform: translateY(15px);
`;

export const Title = styled.div`
  margin: 0 0 15px;
  font-size: 20px;
`;

export const Content = styled.div``;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
`;
