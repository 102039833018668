import React from 'react';

import { GridBox, Grid, TableHead, TableHeadRow, TableHeaders } from 'components/shared/grid';
import Signal from 'components/Signal';
import { demoData } from './grid.mocks';

const test = false;

const SignalsGrid = (props) => {
  const signals = test ? demoData : props.signals;

  const getSignalsList = () => {
    let signalsList = [];
    if (signals) {
      signalsList = signals.map((item, key) => {
        return <Signal view="full2" key={key} {...item} />;
      });
    }

    return signalsList;
  };

  const signalsList = getSignalsList();

  return (
    <GridBox>
      {/* rightConfirm && !!signals && signals.length > 0 && (
        <SubmitAllPlace>
          <SubmitAll onClick={closeAll}>Подтвердить все</SubmitAll>
        </SubmitAllPlace>
      ) */}
      <Grid>
        <TableHead>
          <TableHeadRow>
            <TableHeaders width="190">Начало сигнала</TableHeaders>
            <TableHeaders width="180">Источник</TableHeaders>
            {/*<TableHeaders width="120">Приоритет</TableHeaders>*/}
            <TableHeaders>Описание сигнала</TableHeaders>
            <TableHeaders width="160" align="right">Действие</TableHeaders>
          </TableHeadRow>
        </TableHead>
        <tbody>{signalsList}</tbody>
      </Grid>
    </GridBox>
  );
};

export default SignalsGrid;
