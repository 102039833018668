/**
 * Login form component
 *
 * @overview send credential to parent component after success
 */

import { Form, Field, TextField } from 'libs/form';
import { Button, Buttons } from 'libs/ui';
import { validationSchema } from './login-form.validation';

export const LoginForm = (props) => {
  const initial = {
    username: '',
    password: '',
  };

  return (
    <Form initialValues={initial} validationSchema={validationSchema} onSubmit={props.onSubmit} absoluteErrors>
      <Field name="username" label="Логин" component={TextField} />
      <Field name="password" label="Пароль" component={TextField} type="password" />

      <Buttons>
        <Button fullWidth type="submit">
          Войти
        </Button>
      </Buttons>
    </Form>
  );
};
