import React from 'react';
import PropTypes from 'prop-types';

import { RedButton, WhiteButton } from '../../../shared/buttons';
import { WarningContainer, Content, Title, Buttons } from './confirm.styled';

class Confirm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      last: props.last,
    };

    this.timer = null;
  }

  componentDidMount() {
    this.timer = setInterval(() => {
      const cnt =  this.state.last - 1;
      this.setState({last: cnt});
      if (cnt === 0) {
        clearInterval(this.timer)
      }
    }, 1000);
  }

  componentWillUnmount() 
  {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  render() {
    const { last } = this.state;

    return (
      <WarningContainer>
        <Content>
          <Title>Внимание</Title>
          <p>ПК будет перезагружен и недоступен некоторое время. Продолжить?</p>
          <Buttons>
            <WhiteButton onClick={this.props.close}>Отмена</WhiteButton>
            <RedButton disabled={last > 0} onClick={this.props.action}>Перезагрузка&nbsp;{last > 0 && <span> ( {last} с. )</span>}</RedButton>
          </Buttons>
        </Content>
      </WarningContainer>
    );
  }
}

Confirm.propTypes = {
  close: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired,
  last: PropTypes.number,
};

export default Confirm;
