import { ADD_OPENED_WINDOW, REMOVE_OPENED_WINDOW } from '../constants'

export const openWindow = (data) => {
  return{
    type: ADD_OPENED_WINDOW,
    data: data
  }
}

export const closeWindow = (id) => {
  return{
    type: REMOVE_OPENED_WINDOW,
    id: id
  }
}