import styled, { css } from 'styled-components';

export const Place = styled.div`
  position: relative;
  min-width: 340px;
`;

export const FormError = styled.div`
  margin: 1rem 0 0;
  text-align: left;
  p {
    //font-size: 0.875rem;
    line-height: 1.36;
    color: #f00;
    margin: 0;
    padding: 0;
  }
  ${(props) =>
    props.absoluteErrors &&
    css`      
      padding: 10px 20px;
      border-radius: 5px;
      background: #7f0000;
      position: absolute;
      bottom: 100%;
      left: 0;
      right: 0;
      p {
        text-align: center;
        color: #fff;
      }
    `}
`;
