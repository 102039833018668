import { UPDATE_STATUS, UPDATE_STATUSBG, SET_PAYMENT_MEAN_INFO, CLEAR_PAYMENT_MEAN_INFO } from '../constants';
const eventsReducer = (state, action) => {
  const defaultState = {};
  if (!state) {
    state = defaultState;
  }

  switch (action.type) {
    case UPDATE_STATUSBG:
      //console.log('🚀 ~ eventsReducer ~ action.data.params.mode', action.data.params.mode);
      //console.log('🚀 ~ eventsReducer', state[action.data.source]);
      const current = state[action.data.source];
      if (!current || current.mode !== 'offline') {
        return state;
      }
      return {
        ...state,
        [action.data.source]: {
          ...current,
          mode: action.data.params.mode,
        },
      };

    case UPDATE_STATUS:
      return {
        ...state,
        [action.data.source]: {
          ...state[action.data.source],
          ...action.data.params,
        },
      };
    case SET_PAYMENT_MEAN_INFO:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          payment_mean: {
            ...state[action.id].payment_mean,
            info: action.info,
          },
        },
      };
    case CLEAR_PAYMENT_MEAN_INFO:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          payment_mean: null,
        },
      };
    default:
      return state;
  }
};

export default eventsReducer;
