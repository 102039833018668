import React from 'react';
// import styled from 'styled-components'

import { BlueButton, WhiteButton } from '../../../../shared/buttons';
import { Notification as Container, WarningNotification as WarningContainer } from '../styledComponents';

const Notification = ({ notification, sendControlMsg, toggleReloadPopup, reloadType }) => {
  const getNotification = () => {
    switch (notification) {
      case 'reload_controller_confirm':
      case 'reboot lower controller':
      case 'reload_getaway_confirm':
        return (
          <WarningContainer>
            <div style={{ marginRight: '20px' }}>
              <img src="/images/icon.png" alt="" />
            </div>
            <div>
              <div>
                Перезагрузка
                {notification === 'reload_controller_confirm' ||
                notification === 'reboot lower controller' ||
                notification === 'reboot upper controller'
                  ? 'контроллера'
                  : 'шлюза'}
                ведет за собой «махание» шлагбаума. При проведении данной команды необходимо присутствие человека рядом
                со стойкой проезда
              </div>
              <div style={{ display: 'flex', marginTop: '20px', justifyContent: 'space-evenly' }}>
                <WhiteButton onClick={toggleReloadPopup}>Отменить</WhiteButton>
                <BlueButton onClick={() => sendControlMsg(reloadType, toggleReloadPopup)}>Подтвердить</BlueButton>
              </div>
            </div>
          </WarningContainer>
        );
      case 'reload_help':
        return (
          <Container>
            <div style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold', marginBottom: '10px' }}>
              Помогла перезагрузка?
            </div>
            <div></div>
            <div style={{ display: 'flex', marginTop: '20px', justifyContent: 'space-evenly' }}>
              <WhiteButton onClick={toggleReloadPopup}>Да, помогла</WhiteButton>
              {<BlueButton onClick={() => sendControlMsg('', toggleReloadPopup)}>Перезапустить сервис RFID</BlueButton>}
            </div>
          </Container>
        );
      default:
        return null;
    }
  };

  return getNotification();
};

export default Notification;
