import React from 'react';

import Window from 'components/shared/window/window';
import SignalsActive from './active/signals-active';
import SignalsHistory from './history/signals-history';

function SignalsWindow(props) {
  const tabs = [
    { text: 'Активные', id: 'active' },
    { text: 'История', id: 'history' },
  ];

  const content = {
    active: SignalsActive,
    history: SignalsHistory,
  };

  return <Window tabs={tabs} content={content} width="1000px" height="700px" contentHeight="625px" {...props.data} />;
}

export default SignalsWindow;
