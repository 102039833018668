/**
 * Form adapter component
 *
 * @overview uses Formik
 */

import { Formik } from 'formik';
import { FormError, Place } from './shared-form.styled';

export const getErrors = (formik, absoluteErrors = false) => {
  if (!formik.errors.api) {
    return null;
  }

  return (
    <FormError absoluteErrors={absoluteErrors}>
      <p>{formik.errors.api}</p>
    </FormError>
  );
};

export function Form(props) {
  const { children, onSubmit, debug, absoluteErrors, ...rest } = props;

  const customOnSubmit = (values, helpers) => {
    return onSubmit(values, helpers);
  };

  return (
    <Formik {...rest} onSubmit={customOnSubmit}>
      {/*<FormikForm>{children}</FormikForm>*/}
      {(formik) => {
        const isFunc = typeof children === 'function';
        const errors = getErrors(formik, absoluteErrors);
        //console.log('🚀 ~ errors, isFunc', errors, isFunc);
        const data = typeof children === 'function' ? children(formik) : children;
        return (
          <Place>
            <form onSubmit={formik.handleSubmit}>{data}</form>
            {!isFunc && errors}
            {debug && <pre>{JSON.stringify(formik, null, 2)}</pre>}
          </Place>
        );
      }}
    </Formik>
  );
}

export default Form;
