import { SignalsHistoryAll } from 'libs/ui';

/**
 * general history
 * @returns
 */
const SignalsHistory = (props) => {
  return <SignalsHistoryAll apiMethod="get_events_history" target={props.id} /*rackType={props.data.type}*/ />;
};

export default SignalsHistory;
