import styled from 'styled-components';

export const CallsWrapper = styled.div`
  padding: 20px;
  box-sizing: border-box;
  height: 625px;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 50px;
`;
export const PaymentCheckboxBlock = styled.div`
  margin-left: 25px;
`;
export const RacksCheckboxBlock = styled.div`
  width: 410px;
  border-right: 1px solid #979797;
`;
export const CheckboxRow = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0px;
  }
  div {
    margin-right: 15px;
    &:last-child {
      margin-right: 0px;
    }
  }
`;
export const TurnOnSpeaker = styled.div`
  cursor: pointer;
  width: 238px;
  height: 30px;
  border-radius: 20px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: linear-gradient(#3ea5ff, #3ea5ff), linear-gradient(to bottom, rgba(255, 255, 255, 0.5), #00396c 98%);
  box-shadow: 0 1px 1px 0 #1c4a7b;
  p {
    margin: 0;
    font-size: 15px;
  }
  img {
    margin-right: 5px;
  }
`;
export const CheckBox = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  p {
    margin: 0px;
  }
  div {
    margin-right: 7px;
    height: 14px;
    width: 14px;
    border: 1px solid ${(props) => (props.selected ? '#3ea5ff' : '#979797')};
    border-radius: 2px;
    background-color: ${(props) => (props.selected ? '#3ea5ff' : '#393e43')};
  }
  span {
    display: ${(props) => (props.selected ? 'inline-block' : 'none')};
    width: 3px;
    height: 7px;
    border: solid #393e44;
    margin: 0 0 4px 4px;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`;

export const NodeCheckBox = styled(CheckBox)`
  font-weight: bold;
`;
export const RackCheckBox = styled(CheckBox)``;
