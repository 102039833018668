// active calls functions
window.loadActiveCalls = () => {
  if (!window.haveRight('mnemo.calls')) {
    return false;
  }

  let out = {};
  let calls = sessionStorage.getItem('activecalls');
  if (calls) {
    try {
      calls = JSON.parse(calls);
    } catch (e) {
      calls = {};
    }
    out = calls;
  }
  window.activeCalls = out;
};

window.getActiveCalls = () => {
  return window.activeCalls;
};

window.getActiveCallsCnt = () => {
  return Object.keys(window.activeCalls).length;
};

window.saveActiveCalls = () => {
  const out = window.getActiveCalls();
  sessionStorage.setItem('activecalls', JSON.stringify(out));
};

window.addActiveCall = (id) => {
  //console.log('🚀 ~ addActiveCall', id);
  window.activeCalls[id] = 1;
  window.saveActiveCalls();
};

window.removeActiveCall = (id) => {
  if (window.activeCalls[id]) {
    //console.log('🚀 ~ removeActiveCall', id);
    delete window.activeCalls[id];
    window.saveActiveCalls();
  }
};

window.isActiveCall = (id) => {
  return !!window.activeCalls[id];
};

window.clearActiveCalls = () => {
  //console.log('🚀 ~ clearActiveCalls');
  window.activeCalls = {};
  window.saveActiveCalls();
};

window.showActiveCallWidget = (callsObj) => {
  if (!window.haveRight('mnemo.calls')) {
    return false;
  }
  if (!window.getActiveCallsCnt()) {
    return false;
  }
  let r = false;
  for (let key in callsObj) {
    if (window.isActiveCall(key)) {
      r = true;
    }
  }
  return r;
};
