import { useDispatch, useSelector } from 'react-redux';

import { socket } from 'api';
import { stopSound } from 'store/actions/sounds';
import { dropCall } from 'store/actions/calls';
import { CallButton, DropButton } from './buttons/buttons';
import { Content } from './call-buttons.styled';

export const CallButtons = (props) => {
  const { rackId, style } = props;
  const call = useSelector((state) => state.calls.calls[rackId]);
  const dispatch = useDispatch();

  const rightCall = window.haveRight('mnemo.calls');
  if (!rightCall) {
    return null;
  }

  const isOperator = () => {
    return true;
  };

  /**
   * stop sound
   */
  const doStopSound = () => {
    try {
      dispatch(stopSound('call'));
    } catch (__error) {}
  };

  /**
   * make call
   * @returns
   */
  const makeCall = () => {
    doStopSound();

    if (call && isOperator()) {
      socket.call('call_answer', call, () => {
        window.addActiveCall(rackId);
      });
      return;
    }

    socket.call('call_create', { target: rackId }, () => {
      window.addActiveCall(rackId);
    });
  };

  /**
   * cancel call
   */
  const cancelCall = () => {
    doStopSound();

    const isActive = window.isActiveCall(rackId);

    if (isOperator()) {
      if (isActive) {
        socket.call('call_end', {
          id: call.id,
          target: call.source_inner_id,
        });
      } else {
        socket.call('call_reject', {
          id: call.id,
          source: call.source_inner_id,
        });
      }
      dispatch(dropCall(call.id));
    }
  };

  return (
    <Content style={style}>
      <CallButton action={makeCall}>
        <img alt="start" src="/images/calls/call-start.png" />
      </CallButton>
      {call && (
        <DropButton action={cancelCall}>
          <img alt="end" src="/images/calls/call-end.png" />
        </DropButton>
      )}
    </Content>
  );
};
