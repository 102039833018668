import { connect } from 'react-redux';

import { closeWindow } from 'store/actions/windows';
// import WindowWrapper from 'components/shared/window/window-wrapper';
import Window from 'components/shared/window/window';
import { CallErrorWrapper, BigText, SmallText, Submit } from './CallError.styled';

function CallError(props) {
  // console.log('🚀 ~ CallError ~ props:', props);
  return (
    <Window width={'442px'} height={'225px'} {...props.data}>
      <CallErrorWrapper>
        <img src="/images/error2.png" alt="" />
        <div>
          <BigText>Ошибка соединения</BigText>
          <SmallText>Сервис звонков временно недоступен</SmallText>
          <Submit
            onClick={() => {
              props.closeWindow('call_error');
            }}
          >
            OK
          </Submit>
        </div>
      </CallErrorWrapper>
    </Window>
  );
}

export default connect(null, {
  closeWindow,
})(CallError);
