import styled from 'styled-components';

export const MainWindowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #393e44;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.58);
`;
