import { PLAY_SOUND, STOP_SOUND } from '../constants';

const soundsReducer = (state, action) => {
  const defaultState = {
    sounds: [],
  };
  if (!state) {
    state = defaultState;
  }

  const newSounds = state.sounds.slice(0);

  switch (action.type) {
    case PLAY_SOUND:
      if (newSounds.indexOf(action.data) === -1) {
        newSounds.push(action.data);
      }
      return {
        ...state,
        sounds: newSounds,
      };
    case STOP_SOUND:
      let index = newSounds.indexOf(action.data); // <-- Not supported in <IE9
      if (index !== -1) {
        newSounds.splice(index, 1);
      }
      return {
        ...state,
        sounds: newSounds,
      };
    default:
      return state;
  }
};

export default soundsReducer;
