import { connect } from 'react-redux';

import services from 'services';
import { socket } from 'api';
import { deactivateEvent } from 'store/actions/events';
import { Cell, Row, Submit } from './Signal.styled';

const Signal = (props) => {
  //console.log('🚀 ~ Signal ~ props', props);

  // date
  // priority
  // message

  // date
  // source type
  // number
  // priority
  // message

  // date
  // source type + number
  // priority
  // message

  const { view, type } = props;

  const rightConfirm = window.haveRight('mnemo.confirm_events');

  const confirmSignal = () => {
    socket.call('confirm_event_by_id', { id: props.id });
    props.deactivateEvent(props.source, props.id);
  };

  return (
    <Row priority={type}>
      <Cell>{services.formatDateTime(props.timestamp)}</Cell>
      {(view === 'full' || view === 'full2') && <Cell>{props.source_name}</Cell>}
      {/*<Cell>{services.getSignalPriority(type)}</Cell>*/}
      <Cell>{props.message}</Cell>
      {(view === 'full2' || view === 'confirm') && <Cell align='right'>{rightConfirm ? <Submit onClick={confirmSignal}>Подтвердить</Submit> : <p>-</p>}</Cell>}
    </Row>
  );
};

export default connect(null, {
  deactivateEvent,
})(Signal);
