import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { openWindow } from '../../store/actions/windows';

export const WarningWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  border: ${(props) => {
    switch (props.type) {
      case 'high':
        return 'solid 2px #e70f2c';
      case 'medium':
        return 'solid 2px #ff8322';
      case 'low':
      default:
        return 'solid 2px #d9c969';
    }
  }};
  z-index: 2;
  pointer-events: ${(props) => (props.active ? 'auto' : 'none')};
`;
const Icon = styled.img`
  position: absolute;
  left: 17px;
  top: -10px;
`;

class Event extends React.Component {
  state = {
    showIcon: true,
  };

  interval = null;

  constructor(props) {
    super(props);
    if (props.data.active) {
      this.toggleIcon();
    }
  }

  componentWillUnmount() {
    window.clearInterval(this.interval);
  }

  toggleIcon = () => {
    this.interval = window.setInterval(() => {
      this.setState({ showIcon: !this.state.showIcon });
    }, 500);
  };

  render() {
    return (
      <WarningWrapper
        type={this.props.type}
        active={this.props.data.active}
        onClick={
          () => this.props.showWindow() /*this.props.openWindow({
          id: this.props.data.id,
          type: 'event',
          data: this.props.data
        })*/
        }
      >
        {this.state.showIcon ? <Icon src={'/images/' + this.props.icon} /> : null}
      </WarningWrapper>
    );
  }
}

export default connect(
  () => {
    return {};
  },
  {
    openWindow,
  }
)(Event);
