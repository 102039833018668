//import { NavLink } from 'react-router-dom';
//mport { css } from '@emotion/react';

import { ButtonStyled } from './button.styled';

export * from './button.styled';

// standard
export const Button = (props) => {
  const { children, ...rest } = props;

  return <ButtonStyled {...rest}>{children}</ButtonStyled>;
};
Button.defaultProps = {
  color: 'primary',
  size: 'large',
};

export default Button;
