import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { openWindow } from '../store/actions/windows';
import {
  InfoWrapper,
  //CallsCount,
  InfoGroup,
  //Separator,
  Signals,
  SignalsCount,
  Button,
} from './InfoBlock.styled';

const InfoBlock = (props) => {
  function getSignals() {
    //let signals = 0;
    let warnings = 0;
    let errors = 0;

    for (let rack in props.signals) {
      for (let key in props.signals[rack]) {
        switch (props.signals[rack][key].type) {
          case 'critical':
            errors += 1;
            break;
          case 'notification':
            if (props.signals[rack][key].message !== 'Dispenser status' && props.signals[rack][key].message !== 'Stacker status') {
              warnings += 1;
            }
            break;
          case 'signal':
          default:
            //signals += 1;
            break;
        }
      }
    }

    return (
      <Fragment>
        {/*<SignalsCount priority={3}>{signals}</SignalsCount>*/}
        <SignalsCount priority={2}>{warnings}</SignalsCount>
        <SignalsCount priority={1}>{errors}</SignalsCount>
      </Fragment>
    );
  }

  /*
  function getMissCallCount() {
    const count = 0;
    if (count) {
      return (
        <CallsCount>
          <img src="./images/miss_call_icon.png" alt="" />
          <span>{count}</span>
        </CallsCount>
      );
    } else {
      return null;
    }
  }
  */

  return (
    <InfoWrapper>
      <InfoGroup
        onClick={() =>
          props.openWindow({
            id: 'events',
            type: 'signals',
          })
        }
      >
        <p>Сигналы</p>
        <Signals>{getSignals()}</Signals>
      </InfoGroup>
      {window.config.objects.payments && window.config.objects.payments.length > 1 && (
        <Button
          title="Сводная таблица по АПП"
          onClick={() =>
            props.openWindow({
              id: 'denominations2',
              type: 'denominations2',
            })
          }
        >
          ₽
        </Button>
      )}

      {/*
        <Separator />
        <InfoGroup onClick={() => props.openWindow({
          id: 'audio',
          type: 'calls'
        })}>
          <p>Аудиосвязь</p>
          {getMissCallCount()}
        </InfoGroup>
        <Separator />
        <InfoGroup onClick={() => props.openWindow({
          id: 'denominations',
          type: 'denominations'
        })}>
          <p>Номиналы</p>
        </InfoGroup>
        */}
    </InfoWrapper>
  );
};

function mapStateToProps(store) {
  return {
    signals: store.events.events,
  };
}

export default connect(mapStateToProps, {
  openWindow,
})(InfoBlock);
