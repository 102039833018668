import styled from 'styled-components';

export const Content = styled.div`
  margin: 0 10px;
  border-radius: 3px;
  max-width: 471px;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  img {
    box-sizing: border-box;
    height: auto;
  }
`;

export const Title = styled.div`
  /* text-align: center; */
  margin: 0 0 5px;
  font-size: 17px;
  line-height: 1;
  color: #bbb;
`;

export const PhotoPlace = styled.div`
  border: 3px solid #2c3034;
  background: #2c3034;
  border-radius: 3px;
  height: 260px;
  width: 100%;
  margin: 0 0 10px;
  overflow: hidden;
  position: relative;
  transition: 0.2s opacity ease-in;
  &:hover {
    opacity: 0.8;
  }
  &.error {
    background: #1c2024 url('/images/no-photo.png') no-repeat 50%;
    background-size: 100px auto;
  }
  img {
    border-radius: 3px;
  }
`;

export const Refresh = styled.button`
  position: absolute;
  right: 10px;
  top: 9px;
  border: 0;
  /* z-index: 15; */
  border-radius: 3px;
  width: 34px;
  height: 34px;
  background: rgba(51, 51, 51, 0.6) url('/images/rebut.png') no-repeat 50% 50%;
  transition: all 0.3s ease-in;
  cursor: pointer;
  &:hover {
    background-color: #111;
  }
`;
