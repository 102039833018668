import styled, { css } from 'styled-components';

import { GridCell, GridRow } from './shared/grid';

export const Row = styled(GridRow)`
  td:first-child {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      left: 7px;
      top: 50%;
      margin-top: -3.5px;
      border-radius: 50%;
      width: 7px;
      height: 7px;
      background-color: ${(props) => {
        switch (props.priority) {
          case 'critical':
            return '#ff4747';
          case 'notification':
            return '#ff8322';
          case 'info':
            return '#569056';
          default:
            return 'transparent';
        }
      }};
    }
  }
`;

export const Cell = styled(GridCell)`
  ${(props) =>
    props.align === 'right' &&
    css`
      text-align: right;
      & button {
        display: inline-flex;
      }
    `}
`;

export const Submit = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 15px;
  width: 128px;
  height: 30px;
  border-radius: 20px;
  border: 0;
  background: #1e85df;
  box-shadow: 0 0px 3px 0 #1c4a7b;
  transition: all 0.3s ease;
  &:hover {
    background: #2e95ef;
  }
`;
