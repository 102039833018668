import React from 'react'

import Window from 'components/shared/window/window'
import Board from './Board'


function DoubleBoardWindow(props){
  let data = props.data.data
  const tabs = [
    {text: `Табло ${data.first.zoneId}`, id: data.first.zoneId},
    {text: `Табло ${data.second.zoneId}`, id: data.second.zoneId},
  ]

  const content = {
    [data.first.zoneId]: <Board key={data.first.zoneId} data={data.first}/>,
  [data.second.zoneId]: <Board key={data.second.zoneId} data={data.second}/>,
  }

  return(
    <Window tabs={tabs} content={content} width='480px' height='320px' {...props.data}/>
  )

}

export default DoubleBoardWindow
