import React from 'react';
import styled from 'styled-components';

const StyledLoader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(/images/loader.svg) no-repeat 50%;
  background-size: 100px auto;
`;

export const Loader = () => {
  return <StyledLoader />;
};
