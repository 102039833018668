import React, { useState, useEffect } from 'react';

import AppLogined from './app-logined';
import { Loader, Auth } from 'components/auth';
import { notify } from 'libs/common';
import { user } from 'services/user';

const App = () => {
  //const [logined, setLogined] = useState(false);
  const [checked, setChecked] = useState(false);

  /**
   * check user token
   */
  useEffect(() => {
    window.user = user;
    user
      .getInfo()
      .then(() => {
        setChecked(true);
      })
      .catch((error) => {
        notify('Ошибка сети', error);
        setChecked(true);
      });
  }, []);

  if (!checked) {
    return <Loader />;
  }

  // console.log('🚀 ~ app, user:', user.isLoggedIn);

  return user.isLoggedIn ? <AppLogined /> : <Auth />;
};

export default App;
