import styled from 'styled-components';

import ZoneInfo from 'components/shared/zone/ZoneInfo';
import { BlueButton } from 'components/shared/buttons';

export const BoardWrapper = styled.div`
  height: 100%;
  padding: 30px 40px;
  box-sizing: border-box;
`;
export const Header = styled.div`
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
`;
export const Places = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 30px;
`;
export const Label = styled.div`
  font-size: 13px;
  color: #ffffff;
  opacity: 0.6;
  margin-bottom: 5px;
`;
export const PlacesInputWrapper = styled.div`
  position: relative;
  margin-top: 9px;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
`;
export const PlacesInput = styled.input`
  border-radius: 4px;
  border: solid 1px #a3b0c0;
  background-color: #393e44;
  width: 133px;
  height: 48px;
  font-size: 20px;
  color: #ffffff;
  padding-right: 50px;
  text-align: right;
  box-sizing: border-box;
`;
export const CommentInput = styled(PlacesInput)`
  text-align: left;
  padding-right: 5px;
  padding-left: 5px;
`;

export const PlacesInputArrows = styled.div`
  position: absolute;
  top: 0;
  right: 10px;
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 0;
  box-sizing: border-box;
  img {
    cursor: pointer;
  }
`;
export const ZoneInfoBig = styled(ZoneInfo)`
  transform: scale(1.9);
  margin-top: 30px;
  margin-left: 50px;
`;
export const SizedBlueButton = styled(BlueButton)`
  width: 130px;
  margin-top: 30px;
`;
export const Comment = styled.div`
  margin: 5px 0;
`;
