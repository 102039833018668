import React, { Component } from 'react';

import { GridBox, Grid, TableHead, TableHeadRow, TableHeaders } from 'components/shared/grid';
import Signal from 'components/Signal';

export default class SignalsHistoryGrid extends Component {
  grid = null;
  box = null;

  /**
   * get signal table rows
   * @returns []
   */
  getSignalsList = () => {
    const { signals, target } = this.props;
    let out = [];
    if (signals) {
      out = signals.map((el, idx) => {
        const other = {};
        if (!target) {
          other.view = 'full';
        }
        return <Signal key={idx} {...el} {...other} />;
      });
    }
    return out;
  };

  scrollListner = () => {
    if (this.box.scrollTop + this.box.clientHeight + 50 > this.grid.clientHeight) {
      this.props.addEvents(true);
    }
  };

  render() {
    const { target } = this.props;
    const signals = this.getSignalsList();

    return (
      <GridBox className={this.props.loading ? 'loading' : ''} innerRef={(box) => (this.box = box)} maxHeight={'445px'} onScroll={this.scrollListner}>
        <Grid innerRef={(grid) => (this.grid = grid)}>
          <TableHead>
            <TableHeadRow>
              <TableHeaders width="210">Начало</TableHeaders>
              {!target && <TableHeaders width="180">Источник</TableHeaders>}
              {/*<TableHeaders width="65">Номер</TableHeaders>*/}
              {/*<TableHeaders width="120">Приоритет</TableHeaders>*/}
              <TableHeaders>Описание сигнала</TableHeaders>
            </TableHeadRow>
          </TableHead>
          <tbody>{signals}</tbody>
        </Grid>
      </GridBox>
    );
  }
}
