import React from 'react';

import { Confirm } from 'libs/ui';
import withRackLogic from '../racksControl/withRackLogic';
import { Wrapper, Content, Buttons } from './options.styled';
import { GrayButton } from '../../../shared/buttons';
import Reload from './reload';

class Options extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      confirm: false,
      confirm1: false,
    };
  }

  componentDidMount = () => {
    this._ismounted = true;

    this.props.sendControlMsg('get_state');
  };

  componentWillUnmount = () => {
    this._ismounted = false;
  };

  showConfirm = () => {
    this.setState({ confirm: true, confirm1: false });
  };

  showConfirm1 = () => {
    this.setState({ confirm: false, confirm1: true });
  };

  closeConfirm = () => {
    this.setState({ confirm: false, confirm1: false });
  };

  reconciliation = () => {
    this.props.sendControlMsg('reconciliation', () => {
      this.closeConfirm();
    });
  };

  closeShift = () => {
    this.props.sendControlMsg('zreport_to_buffer', () => {
      this.setState({ confirm: false });
      this.props.sendControlMsg('get_state');
    });
  };

  render() {
    const { confirm, confirm1 } = this.state;
    const { status, id } = this.props;
    const closed = status.flags ? status.flags.includes('cash_register.shift_closed') : true;
    //console.log('🚀 ~ render ~ status', closed, status);
    const { mode } = status;
    //console.log('🚀 ~ render ~ status', status);
    const offline = mode === 'offline';
    let cl2 = offline || !mode ? 'offline1' : '';

    return (
      <Wrapper>
        <Content className={cl2}>
          <Buttons>
            <div>
              <GrayButton disabled={closed || confirm} className="btn" onClick={this.showConfirm}>
                Закрыть смену
              </GrayButton>
              {confirm && (
                // <>
                //   <Title>Кассовая смена будет закрыта. Продолжить?</Title>
                //   <Buttons>
                //     <GrayButton className="btn" onClick={this.closeConfirm}>
                //       Отмена
                //     </GrayButton>
                //     <RedButton className="btn" onClick={this.closeShift}>
                //       &nbsp; &nbsp; Ок &nbsp; &nbsp;
                //     </RedButton>
                //   </Buttons>
                // </>
                <Confirm
                  close={this.closeConfirm}
                  action={this.closeShift}
                  title={'Кассовая смена будет закрыта. Продолжить?'}
                  actionTitle={<>&nbsp; &nbsp; Ок &nbsp; &nbsp;</>}
                />
              )}
            </div>
            <div>
              <GrayButton disabled={confirm1} className="btn" onClick={this.showConfirm1}>
                Сверка итогов
              </GrayButton>
              {confirm1 && (
                <Confirm
                  close={this.closeConfirm}
                  action={this.reconciliation}
                  title={'Провести сверку итогов?'}
                  actionTitle={<>&nbsp; &nbsp; Ок &nbsp; &nbsp;</>}
                />
              )}
            </div>
          </Buttons>

          <Reload id={id} />
        </Content>
      </Wrapper>
    );
  }
}

const rackSettings = {
  type: 'app',
  reloadType: 'reboot controller',
};

export default withRackLogic(Options, rackSettings);
