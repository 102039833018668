import React from 'react';
import styled from 'styled-components';
import Window from 'components/shared/window/window';

import { connect } from 'react-redux';

const CashTable = styled.table`
  margin: auto;
  border-collapse: collapse;
  width: 950px;
  color: #adafb1;
  font-size: 15px;
  border-style: hidden;
  .warning-medium {
    color: #ad963f;
  }
  .warning-hight {
    color: #ad4143;
  }
  img {
    position: absolute;
    left: 10px;
  }
  p {
    font-size: 13px;
    color: white;
  }
  td,
  th {
    position: relative;
    width: 60px;
    padding: 0 10px;
    text-align: right;
    height: 25px;
    border: 1px solid #4c5056;
    &:first-child {
      width: 152px;
    }
  }
  th {
    background-color: #395269;
    &:first-child {
      background-color: rgba(0, 0, 0, 0) !important;
    }
    &:nth-child(even) {
      background-color: #354e64;
    }
  }
  td {
    &:nth-child(even) {
      background-color: #34393e;
    }
    &:first-child {
      vertical-align: text-top;
      text-align: left;
      padding-top: 5px;
      padding-left: 0;
      border-bottom: 0;
    }
  }
  &:last-child {
    margin-top: 10px;
  }
`;

const InfoHeader = styled.div`
  font-size: 14px;
  color: #3ea5ff;
  padding-bottom: 10px;
  margin-left: 17px;
  margin-bottom: 15px;
`;

class Denominations extends React.Component {
  componentDidMount() {
    this.getAllDenominationsInfo();
  }

  getAllDenominationsInfo() {
    let racks = window.config.app_map;
    for (let key in racks) {
      this.getDispancerAndStackerStatus(key);
    }
  }

  getDispancerAndStackerStatus(id) {
    let msg = {
      target: id,
      data: JSON.stringify({ message: 'get status dispenser and stacker' }),
      user_id: '123',
    };
    window.socket.emit('message', { method: 'control.send', params: msg });
  }

  getTotalInStacker() {
    let list = [];
    for (let key in window.config.app_map) {
      let rack = this.props.status[key] || {};
      list.push(<td>{rack.cassette_lower && rack.cassette_lower * rack.nominal_lower + rack.cassette_upper * rack.nominal_upper}</td>);
    }

    return list;
  }

  getFirstCassette() {
    let list = [];
    for (let key in window.config.app_map) {
      let rack = this.props.status[key] || {};
      list.push(
        <td className={rack.cassette_lower === 0 ? 'warning-hight' : rack.cassette_lower < 50 ? 'warning-medium' : ''}>
          {rack.cassette_lower === 0 ? (
            <img src="/images/warning-hight-icon.png" alt="" />
          ) : rack.cassette_lower < 50 ? (
            <img src="/images/warning-icon.png" alt="" />
          ) : null}
          {rack.cassette_lower}
        </td>
      );
    }

    return list;
  }

  getSecondCassette() {
    let list = [];
    for (let key in window.config.app_map) {
      let rack = this.props.status[key] || {};
      list.push(
        <td className={rack.cassette_upper === 0 ? 'warning-hight' : rack.cassette_upper < 50 ? 'warning-medium' : ''}>
          {rack.cassette_upper === 0 ? (
            <img src="/images/warning-hight-icon.png" alt="" />
          ) : rack.cassette_upper < 50 ? (
            <img src="/images/warning-icon.png" alt="" />
          ) : null}
          {rack.cassette_upper}
        </td>
      );
    }

    return list;
  }

  getTotalCash() {
    let list = [];
    for (let key in window.config.app_map) {
      let rack = this.props.status[key] || {};
      list.push(<td>{rack.ba_stacker_total}</td>);
    }

    return list;
  }

  getNominals(nominal) {
    let list = [];
    for (let key in window.config.app_map) {
      let rack = this.props.status[key] || {};
      list.push(<td>{rack.ba_stacker ? rack.ba_stacker['bill_' + nominal] : ''}</td>);
    }

    return list;
  }

  getCashByNominals() {
    let list = [];
    const nominals = [5000, 2000, 1000, 500, 200, 100, 50];
    list = nominals.map((item) => {
      return (
        <tr>
          <td>{item}</td>
          {this.getNominals(item)}
        </tr>
      );
    });
    return list;
  }

  render() {
    return (
      <Window width="1000px" height="740px" contentHeight="708px" {...this.props.data}>
        <div style={{ height: '708px', width: '100%', paddingTop: '15px', boxSizing: 'border-box' }}>
          <div>
            <InfoHeader>Выдача сдачи</InfoHeader>
            <CashTable>
              <thead>
                <tr>
                  <th style={{ textAlign: 'left', paddingLeft: '0' }}>Паркоматы</th>
                  <th>№ 01</th>
                  <th>№ 02</th>
                  <th>№ 03</th>
                  <th>№ 04</th>
                  <th>№ 05</th>
                  <th>№ 06</th>
                  <th>№ 07</th>
                  <th>№ 08</th>
                  <th>№ 09</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ verticalAlign: 'text-top', textAlign: 'left', paddingTop: '5px', paddingLeft: '0', borderBottom: '0' }}>
                    Всего в кассетах/₽
                  </td>
                  {this.getTotalInStacker()}
                </tr>
              </tbody>
            </CashTable>
            <CashTable>
              <thead>
                <tr>
                  <th style={{ textAlign: 'left', paddingLeft: '0', border: '0' }}></th>
                  <th style={{ textAlign: 'center', backgroundColor: 'rgba(0,0,0,0)', border: '0', borderBottom: 'solid 1px #5386b5' }} colSpan={9}>
                    Количество купюр/шт.
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    style={{
                      color: '#fff',
                      verticalAlign: 'text-top',
                      textAlign: 'left',
                      paddingTop: '5px',
                      paddingLeft: '0',
                      borderTop: '0',
                      borderBottom: '0',
                    }}
                  >
                    Кассета 1
                  </td>
                  {this.getFirstCassette()}
                </tr>
                <tr>
                  <td style={{ color: '#fff', verticalAlign: 'text-top', textAlign: 'left', paddingTop: '5px', paddingLeft: '0', borderBottom: '0' }}>
                    Кассета 2
                  </td>
                  {this.getSecondCassette()}
                </tr>
              </tbody>
            </CashTable>
          </div>
          <div style={{ marginTop: '40px' }}>
            <InfoHeader>Прием купюр</InfoHeader>
            <CashTable>
              <thead>
                <tr>
                  <th style={{ textAlign: 'left', paddingLeft: '0' }}>Паркоматы</th>
                  <th>№ 01</th>
                  <th>№ 02</th>
                  <th>№ 03</th>
                  <th>№ 04</th>
                  <th>№ 05</th>
                  <th>№ 06</th>
                  <th>№ 07</th>
                  <th>№ 08</th>
                  <th>№ 09</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ verticalAlign: 'text-top', textAlign: 'left', paddingTop: '5px', paddingLeft: '0', borderBottom: '0' }}>
                    Принято всего/₽
                  </td>
                  {this.getTotalCash()}
                </tr>
              </tbody>
            </CashTable>
            <CashTable>
              <thead>
                <tr>
                  <th style={{ textAlign: 'left', paddingLeft: '0' }}> Номиналы/₽</th>
                  <th style={{ textAlign: 'center', backgroundColor: 'rgba(0,0,0,0)', borderBottom: 'solid 1px #5386b5' }} colSpan={9}>
                    Количество купюр/шт.
                  </th>
                </tr>
              </thead>
              <tbody>{this.getCashByNominals()}</tbody>
            </CashTable>
          </div>
        </div>
      </Window>
    );
  }
}

const mapStateToProps = (store) => {
  let status = store.equipment;
  return {
    status: status,
  };
};

export default connect(mapStateToProps)(Denominations);
