import styled from 'styled-components';

export const ZoneWrapper = styled.div`
  position: absolute;
  left: ${(props) => props.left};
  top: ${(props) => props.top};
`;
export const ZoneId = styled.div`
  font-weight: bold;
  font-size: 40px;
  color: ${(props) => props.color};
`;
export const Places = styled.div`
  font-size: 24px;
  color: ${(props) => props.color};
  display: ${(props) => (props.show ? 'block' : 'none')};
`;
