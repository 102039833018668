import React from 'react';
import moment from 'moment';

import withRackLogic from '../withRackLogic';
import { prepareVideoUrls, isOperator } from 'services';
import { RackWrapper, CallMakeButton, CallDropButton } from '../styledComponents';
import { StyledControlPanel, Buttons, OpenGateButton } from './GooseControl.styled';
import RackState from '../RackState';
import RackVideos from '../RackVideos';
import ConfirmWithComment from './ConfirmWithComment';

class GooseControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cams: null,
      showConfirm: false,
      working: false,
      action: () => '',
    };

    this.withCommentWhenOpen = window.dict['reasonofpass'] !== undefined;
  }

  componentDidMount = () => {
    this._ismounted = true;
    this.createtime = moment().unix();

    this.props.sendControlMsg('get_video_urls');
    window.socket.on('get_video_urls', (msg) => {
      if (!this._ismounted) {
        return;
      }
      if (msg.target === this.props.id) {
        this.setState({ cams: prepareVideoUrls(msg) });
      }
    });

    this.props.sendControlMsg('get_state');
  };

  componentWillUnmount = () => {
    this._ismounted = false;
  };

  /**
   * action open gate
   * @param {*} type
   * @param {*} comment
   */
  doOpenGate = (type, comment = undefined) => {
    if (this.state.working) {
      return;
    }
    this.setState({ working: true });

    const out = {};
    if (comment) {
      out.comment = comment;
    }
    this.props.sendControlMsg(type, null, out);
    setTimeout(() => {
      this.setState({ working: false });
    }, 500);
  };

  /**
   * open gate with confirm and comment
   * @param {*} type
   */
  openWithConfirm = (type) => {
    if (!this.withCommentWhenOpen) {
      this.doOpenGate(type);
      return;
    }

    this.setState({
      showConfirm: true,
      action: (comment) => {
        this.doOpenGate(type, comment);
      },
    });
  };

  /**
   * close confirm
   */
  closeConfirm = () => {
    this.setState({ showConfirm: false });
  };

  /**
   * open gate
   */
  openGate = () => {
    this.openWithConfirm('open_main');
  };

  render() {
    const { cams, working } = this.state;

    const rightCall = window.haveRight('mnemo.calls');
    const rightControl = window.haveRight('mnemo.rack_control');
    const { mode } = this.props.status;
    const offline = mode === 'offline';
    let cl = '';
    let cl1 = offline ? 'offline' : '';
    let cl2 = offline ? 'offline1' : '';
    if (!cams) {
      cl += ' loading';
      cl1 += ' loading';
      cl2 += ' offline1';
    }
    const btncl = working ? 'working' : '';

    return (
      <RackWrapper className={cl}>
        <RackVideos cams={cams} id={this.props.id} cl={cl2} />
        <StyledControlPanel className={cl1}>
          {rightCall && <CallMakeButton action={this.props.makeCall} src="/images/audio.png" />}
          {rightCall && this.props.call && <CallDropButton action={this.props.cancelCall} src="/images/audio-2.png" />}

          {!isOperator() && (
            <Buttons>
              <OpenGateButton className={btncl} disabled={!rightControl} onClick={this.openGate}>
                Открыть дверь
              </OpenGateButton>
            </Buttons>
          )}
          {this.state.showConfirm && <ConfirmWithComment close={this.closeConfirm} action={this.state.action} />}
        </StyledControlPanel>
        <RackState sendControlMsg={this.props.sendControlMsg} id={this.props.id} offline={offline} gooseMode />
      </RackWrapper>
    );
  }
}

const rackSettings = {
  type: 'rack',
  reloadType: 'reboot controller',
};

export default withRackLogic(GooseControl, rackSettings);
