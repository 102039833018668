import styled, { css } from 'styled-components';

export const GridContainer = styled.div`
  width: 100%;
  max-height: 100px;
  border-radius: 2px;
`;
export const GridBox = styled.div`
  max-height: ${(props) => props.maxHeight};
  overflow-y: auto;
  padding-top: 15px;
  position: relative;

  &.loading {
    position: relative;
    height: 100%;
  }
  &.loading::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: url('/images/loader.svg') no-repeat 50%;
  }
`;
export const Total = styled.p`
  margin: 0 0 30px 20px;
  font-size: 13px;
  font-weight: bold;
  color: #1c1c1c;
  span {
    font-size: 15px;
  }
`;
export const Grid = styled.table`
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
  text-align: left;
`;
export const TableHead = styled.tbody``;
export const TableRow = styled.tr`
  cursor: pointer;
  th,
  td {
    &:first-child {
      padding-left: 20px;
    }
    &:last-child {
      padding-right: 20px;
    }
  }
`;
export const TableHeadRow = styled(TableRow)`
  &:hover {
    background-color: inherit;
    cursor: default;
  }
`;
export const TableHeaders = styled.th`
  position: relative;
  height: 35px;
  font-size: 13px;
  color: #ffffff;
  opacity: 0.6;
  ${(props) => props.align === 'right' && css`
    text-align: right;
  `}
`;
export const TableSortHeaders = styled(TableHeaders)`
  pointer-events: auto;
  &:hover {
    font-weight: bold;
    opacity: 1;
  }
  div {
    cursor: pointer;
    p {
      display: inline-block;
      margin-right: 10px;
    }
  }
`;
export const PaymentsTableHeaders = styled(TableHeaders)`
  color: #b7babf;
  opacity: 1;
  border-bottom: 1px solid #4d5156;
`;
export const GridRow = styled(TableRow)``;
export const GridCell = styled.td`
  text-align: ${(props) => (props.numeric ? 'end' : 'start')};
  position: relative;
  border-bottom: 1px solid rgba(222, 227, 231, 0.2);
  height: 40px;
  font-size: 15px;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const GridCellNumber = styled(GridCell)`
  padding-right: 25px;
`;
export const GridCellFirst = styled(GridCell)`
  padding-left: 20px;
`;
export const GridCellLast = styled(GridCell)`
  padding-right: 20px;
`;
export const GridCellLock = styled(GridCell)`
  &::before {
    position: absolute;
    left: 5px;
    content: ${(props) => {
      if (props.status === 'active') return '';
      if (props.status === 'blocked') return 'url(/images/icon_lock_gray.png)';
    }};
  }
`;
export const CommentBox = styled.div`
  height: inherit;
  overflow-y: auto;
  padding: 10px 0;
`;
export const ZoneIcon = styled.div`
  width: 32px;
  height: 32px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  border: 2px solid #fff;
  font-size: 13px;
  color: white;
  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
`;
export const LongZoneIcon = styled.div`
  width: 30px;
  height: 70px;
  background-color: ${(props) => props.color};
  border-radius: 20px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  p {
    font-size: 13px !important;
    color: white !important;
  }
`;
export const RepalceFormBox = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
`;
export const ReplaceFormText = styled.p`
  opacity: 0.4;
  font-size: 15px;
  line-height: 1.33;
  text-align: right;
  color: #000000;
  margin-right: 20px;
`;
export const ReplaceListButton = styled.div`
  cursor: pointer;
  width: 180px;
  height: 30px;
  border-radius: 20px;
  background-color: #3ea5ff;
  box-shadow: 0 1px 1px 0 #1d4a7b;
  div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      margin-left: 8px;
      font-size: 15px;
      line-height: 0.8;
      color: #ffffff;
    }
  }
`;
export const OptionsCell = styled(GridCell)`
  overflow: visible;
  padding-top: 4px;
`;
export const OptionsPopup = styled.div`
  position: absolute;
  top: 35px;
  width: 220px;
  height: 125px;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), #f2f2f2), linear-gradient(#ffffff, #ffffff);
  box-shadow: 0 -1px 10px 0 rgba(0, 0, 0, 0.5);
  z-index: 999;
  border-radius: 5px;
  right: -62px;
  p {
    top: -10px;
    right: 90px;
    position: absolute;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid white;
  }
`;
export const Option = styled.div`
  height: 40px;
  font-size: 13px;
  padding: 15px 0 0 15px;
  color: #000;
`;
export const OptionsSeparator = styled.div`
  height: 1px;
  opacity: 0.6;
  background-color: rgba(85, 85, 85, 0.2);
`;

export const Submit = styled.div`
  cursor: pointer;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 15px;
  width: 128px;
  height: 30px;
  border-radius: 20px;
  background-image: linear-gradient(#3ea5ff, #3ea5ff), linear-gradient(to bottom, rgba(255, 255, 255, 0.5), #00396c 98%);
  box-shadow: 0 1px 1px 0 #1c4a7b;
`;

export const SubmitAll = styled(Submit)`
  width: 150px;
  background-image: linear-gradient(#f74747, #f74747), linear-gradient(to bottom, rgba(255, 255, 255, 0.5), #f74747 98%);
  margin-top: 20px;
`;

export const SubmitAllPlace = styled.div`
  padding: 20px;
  width: 150px;
  margin: -20px 0 0;
`;
