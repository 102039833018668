import { Label } from './refresh-filter.styled';

export const RefreshFilter = (props) => {
  const { value, change, title } = props;

  return (
    <Label>
      <input type="checkbox" checked={value} onChange={change} />
      <span>{title}</span>
    </Label>
  );
};

RefreshFilter.defaultProps = {
  title: 'Автоообновление',
};
