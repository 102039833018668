import React from 'react';

import { socket } from 'api';
import {
  CallsWrapper,
  CheckBox,
  CheckboxRow,
  CheckboxWrapper,
  NodeCheckBox,
  PaymentCheckboxBlock,
  RackCheckBox,
  RacksCheckboxBlock,
  TurnOnSpeaker,
} from './CallForm.styled';

let rackGroups, paymentTerminals;

class CallUpForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRacks: [],
      allRacksSelected: false,
      allPaymentRacksSelected: false,
    };
  }

  getCheckBoxRow = (type) => {
    let list = null;
    if (type === 'raks') {
      list = rackGroups;
    } else {
      list = paymentTerminals;
    }

    let allRacksSelected = true;

    let racksCheckBoxes = null;
    const racks = list.map((item) => {
      let isNodeSelected = true;
      if (item.racks) {
        racksCheckBoxes = item.racks.map((item) => {
          let isRackSelected = true;
          if (this.state.selectedRacks.indexOf(item.id) === -1) {
            isRackSelected = false;
            isNodeSelected = false;
            allRacksSelected = false;
          }
          return (
            <RackCheckBox onClick={() => this.toggleRack(item.id)} selected={isRackSelected} key={item.id}>
              <div>
                <span />
              </div>
              <p>{item.id}</p>
            </RackCheckBox>
          );
        });
      } else if (this.state.selectedRacks.indexOf(item.id) === -1) {
        isNodeSelected = false;
        allRacksSelected = false;
      }
      return (
        <CheckboxRow key={item.id}>
          <NodeCheckBox onClick={item.racks ? () => this.toggleNode(item, isNodeSelected) : () => this.toggleRack(item.id)} selected={isNodeSelected}>
            <div>
              <span />
            </div>
            <p>{this.getVisibleId(item.id)}</p>
          </NodeCheckBox>
          {racksCheckBoxes ? racksCheckBoxes : null}
        </CheckboxRow>
      );
    });

    if (type === 'raks') {
      if (this.state.allRacksSelected !== allRacksSelected) {
        this.setState({ allRacksSelected: allRacksSelected });
      }
    } else {
      if (this.state.allPaymentRacksSelected !== allRacksSelected) {
        this.setState({ allPaymentRacksSelected: allRacksSelected });
      }
    }

    return racks;
  };

  toggleRack = (id) => {
    let index = this.state.selectedRacks.indexOf(id);
    if (index === -1) {
      this.setState({ selectedRacks: [...this.state.selectedRacks, id] });
    } else {
      let newArr = [...this.state.selectedRacks];
      newArr.splice(index, 1);
      this.setState({ selectedRacks: newArr });
    }
  };

  toggleNode = (node, selected) => {
    let newArr = [...this.state.selectedRacks];
    if (!selected) {
      node.racks.forEach((item) => {
        newArr.push(item.id);
      });
      newArr = Array.from(new Set(newArr));
    } else {
      node.racks.forEach((item) => {
        newArr = newArr.filter((id) => id !== item.id);
      });
    }
    this.setState({ selectedRacks: newArr });
  };

  getVisibleId = (id) => {
    window.service.call('getEquipmentInnerId', id);
  };

  toggleAllRacks = () => {
    let newArr = [...this.state.selectedRacks];
    if (!this.state.allRacksSelected) {
      let list = rackGroups;
      list.forEach((item) => {
        item.racks.forEach((item) => {
          newArr.push(item.id);
        });
      });
      newArr = Array.from(new Set(newArr));
      this.setState({ allRacksSelected: true, selectedRacks: newArr });
    } else {
      newArr = newArr.filter((item) => item.length !== 2);
      this.setState({ allRacksSelected: false, selectedRacks: newArr });
    }
  };

  toggleAllPaymentRacks = () => {
    let newArr = [...this.state.selectedRacks];
    if (!this.state.allPaymentRacksSelected) {
      let list = paymentTerminals;
      list.forEach((item) => {
        newArr.push(item.id);
      });
      newArr = Array.from(new Set(newArr));
      this.setState({ allPaymentRacksSelected: true, selectedRacks: newArr });
    } else {
      newArr = newArr.filter((item) => item.length === 2);
      this.setState({ allPaymentRacksSelected: false, selectedRacks: newArr });
    }
  };

  submit = () => {
    let requestArr = [];
    requestArr = this.state.selectedRacks.map((item) => {
      //console.log('!!!!', item)
      const node = window.config.nodes[item];
      return {
        source_inner_id: item,
        source: node.phone, //item.length === 2 ? window.config.rack_asterisk_id_map[item] : window.config.app_asterisk_id_map[item]
      };
    });
    socket.call('call_create_many', { idsArray: requestArr });
    //window.socket.emit('message', {jsonrpc: '2.0', id: '1', method: 'calls.callMany', params: {idsArray: requestArr}})
  };

  render() {
    if (!rackGroups) {
      rackGroups = window.config.objects ? window.config.objects.nodes : [];
    }
    if (!paymentTerminals) {
      paymentTerminals = window.config.objects ? window.config.objects.payments : [];
    }

    if (!rackGroups && !paymentTerminals) {
      return null;
    }

    return (
      <CallsWrapper>
        <CheckboxWrapper>
          <RacksCheckboxBlock>
            <CheckboxRow>
              <RackCheckBox onClick={() => this.toggleAllRacks()} selected={this.state.allRacksSelected}>
                <div>
                  <span />
                </div>
                <p>Все стойки</p>
              </RackCheckBox>
            </CheckboxRow>
            {this.getCheckBoxRow('raks')}
          </RacksCheckboxBlock>
          <PaymentCheckboxBlock>
            <CheckboxRow>
              <RackCheckBox onClick={() => this.toggleAllPaymentRacks()} selected={this.state.allPaymentRacksSelected}>
                <div>
                  <span />
                </div>
                <p>Все паркоматы</p>
              </RackCheckBox>
            </CheckboxRow>
            {this.getCheckBoxRow('paymentTerminal')}
          </PaymentCheckboxBlock>
        </CheckboxWrapper>
        <TurnOnSpeaker onClick={this.submit}>
          <img src="/images/speaker_icon.png" alt="" />
          <p>Включить громкую связь</p>
        </TurnOnSpeaker>
      </CallsWrapper>
    );
  }
}

export default CallUpForm;
