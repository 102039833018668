import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import services from 'services';
import VideoUrl from './VideoUrl';
import { Save } from './styledComponents';

const Videos = styled.div`
  width: 500px;
`;
const Container = styled.div`
  position: relative;
  display: block;
  height: 100%;
  height: ${(props) => props.height};
  z-index: 10;
  overflow: hidden;
  //background-color: #a9a9a9;
`;

const Main = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  img {
    height: 100%;
    width: 100%; //auto;
  }
`;

const Selector = styled.div`
  position: absolute;
  left: 10px;
  bottom: 10px;
  right: 10px;
  z-index: 2;
  img {
    display: inline-block;
    border-radius: 3px;
    border: 1px solid #000;
    width: auto;
    min-width: 92px;
    height: 60px;
    margin-right: 5px;
    cursor: pointer;
    opacity: 0.7;
    transition: 0.2s all ease-in;
    &.active {
      border: 1px solid #fff;
      opacity: 1;
      cursor: default;
    }
    &:hover {
      opacity: 1;
    }
  }
`;

class VideoBlockUrls extends React.PureComponent {
  constructor(props) {
    super(props);

    let defaultCam = services.getDefaultCam(props.rackId);
    if (!this.find(defaultCam)) {
      defaultCam = props.cams[0]?.id;
    }
    this.state = {
      activeCam: defaultCam,
    };
  }

  componentWillUnmount() {
    //const cam = document.getElementById(this.props.id);
    //console.log('🚀 ~ unmount VideoBlockUrls', this.props);
    //cam.src = "";
  }

  find = (idx) => {
    if (!this.props.cams || !this.props.cams[0]) {
      return null;
    }
    return this.props.cams.find((el) => {
      return el.id === idx;
    });
  };

  getClassName(active) {
    return active ? 'active' : 'inactive';
  }

  setActiveCam = (cam) => {
    services.setDefaultCam(this.props.rackId, cam);
    //console.log('🚀 ~ cam', cam);
    this.setState({ activeCam: cam });
  };

  getVideos = () => {
    const { cams } = this.props;

    return cams.map((cam) => {
      return this.getVideo(cam.id);
    });
  };

  nope = () => {};

  getVideo = (id, main = false) => {
    const { activeCam } = this.state;
    const cl = this.getClassName(activeCam === id);
    const cam = this.find(id);
    if (!cam) {
      console.error('🚀 ~ VideoBlockUrls not found', id);
    }
    const key = id + (main ? '_main' : '');
    const id1 = this.props.rackId + '_' + key;
    /*
    this.id1 = id1;
    if (id1.indexOf('_main') === -1) {
      this.id1 += '_main';
    }
    */
    const url = cam.url + '?ct=' + this.props.createtime;
    //console.log('🚀 ~ key', key);
    //console.log('🚀 ~ cam.url', cam.url);

    return (
      <VideoUrl
        //rackId={this.props.rackId}
        key={id1}
        id={id}
        id1={id1}
        createtime={this.props.createtime}
        className={cl}
        src={url}
        func={main ? this.nope : this.setActiveCam}
      />
    );
  };

  getActiveCamId = () => {
    const { activeCam } = this.state;
    return this.props.rackId + '_' + activeCam + '_main';
  };

  /**
   * save shot from video stream
   * @returns
   */
  save = () => {
    if (this.working) {
      return;
    }
    this.working = true;
    let camId = this.getActiveCamId();
    const c = document.createElement('canvas');
    const img = document.getElementById(camId);
    //console.log('🚀 ~ this.id1', camId);
    try {
      const w = img.width;
      const h = img.height;
      // console.log('🚀 ~ h:', w, h);
      c.width = w;
      c.height = h;
      const ctx = c.getContext('2d');
      // ctx.drawImage(img, 0, 0);
      ctx.drawImage(img, 0, 0, w, h);
      const dataURL = c.toDataURL('image/png');
      const link = document.createElement('a');
      link.download = camId + '.png';
      link.href = dataURL;
      //link.click();
      document.body.appendChild(link);
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
      document.body.removeChild(link);
    } catch (e) {
      console.log('🚀 save image error', e);
    }
    this.working = false;
  };

  render() {
    const { camHeight, cams } = this.props;
    const { activeCam } = this.state;
    //console.log('🚀 ~ render ~ activeCam', activeCam);

    if (!cams || !cams[0]) {
      return null;
    }
    const video = this.getVideo(activeCam, true);
    const videos = cams.length > 1 ? this.getVideos() : null;
    return (
      <Videos>
        <Container height={camHeight}>
          <Save onClick={this.save} />
          <Main>{video}</Main>
          {cams.length > 1 && <Selector>{videos}</Selector>}
        </Container>
      </Videos>
    );
  }
}

VideoBlockUrls.propTypes = {
  rackId: PropTypes.string.isRequired,
  camHeight: PropTypes.string.isRequired,
  createtime: PropTypes.number.isRequired,
  cams: PropTypes.array.isRequired,
};

VideoBlockUrls.defaultProps = {
  camHeight: '310px',
  createtime: 0,
  cams: [],
};

export default VideoBlockUrls;
