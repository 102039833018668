import React, { useState } from 'react';
import styled from 'styled-components';

import { GrayButton } from '../../../shared/buttons';
import { Indicator, IndicatorActive } from './Indicator';

export const VideoBlockCap = styled.div`
  background: #30353b;
  &.offline1 {
    background: #111214;
  }
`;

export const TwoVideosPlace = styled.div`
  display: block;
  position: relative;
`;

export const Refresh = styled.button`
  position: absolute;
  right: 10px;
  top: 9px;
  border: 0;
  z-index: 15;
  border-radius: 3px;
  width: 34px;
  height: 34px;
  background: rgba(51, 51, 51, 0.6) url('/images/rebut.png') no-repeat 50% 50%;
  transition: all 0.3s ease-in;
  cursor: pointer;
  &:hover {
    background-color: #111;
  }
`;

export const Save = styled(Refresh)`
  background-color: rgba(170, 170, 170, 0.6);
  background-image: url('/images/takefoto.png');
  background-size: 16px auto;
  right: auto;
  left: 10px;
  &:hover {
    background-color: #eee;
  }
`;

export const ControllWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  .offline::after,
  &.loading::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 3;
  }
  .offline::before {
    content: 'Отключен';
    color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: normal;
    width: 240px;
    height: 50px;
    text-align: center;
    margin: -25px 0 0 -120px;
    z-index: 11;
  }
  &.loading::after {
    background: #393e44 url(/images/loader.svg) no-repeat 50%;
  }
`;
export const Media = styled.div`
  width: 500px;
`;
export const ReloadBlock = styled.div`
  position: relative;
  margin-bottom: 45px;
  ${GrayButton} {
    margin-bottom: 30px;
  }
`;

export const Mode = styled.div`
  position: relative;
`;
export const ModeHeader = styled.div`
  font-size: 20px;
  color: #fff;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const ControlPanel = styled.div`
  width: 450px;
  position: relative;
`;
export const ModeSection = styled.div`
  height: ${(props) => props.height};
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: white;
`;
export const Block = styled.div`
  box-sizing: border-box;
  position: absolute;
  width: 265px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.9);
  top: 50px;
  right: 0px;
  padding: 15px;
  z-index: 9;
`;
export const Arrow = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid black;
  top: -9px;
  left: 50px;
`;
export const ReloadPopup = styled(Block)`
  z-index: 999;
  background-color: #5f6872;
  width: 310px;
  left: 0px;
`;
export const ReloadPopupArrow = styled(Arrow)`
  border-bottom: 10px solid #5f6872;
`;
export const ReloadHeader = styled.div`
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
`;
export const ReloadButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  margin-top: 20px;
`;
export const Notification = styled.div`
  padding: 30px 40px;
  box-sizing: border-box;
  background: #fff;
  position: absolute;
  width: 430px;
  left: 0;
  top: 100px;
`;
export const WarningNotification = styled(Notification)`
  display: flex;
`;
export const CurrentMode = styled.div`
  font-size: 13px;
  margin-left: 15px;
  margin-right: -40px;
  span {
    color: #3ea5ff;
  }
  &.error span {
    color: #f00;
  }
  position: relative;
`;
export const CurrentModeInfo = styled.div`
  position: absolute;
  left: 69%;
  bottom: -17px;
  color: #f00;
  text-align: left;
  white-space: nowrap;
`;

export const ReloadSection = styled(ModeSection)`
  border-bottom: 0;
  height: 50px;
  ${Indicator} {
    width: 22px;
    height: 22px;
    ${IndicatorActive} {
      width: 12px;
      height: 12px;
    }
  }
`;
export const Table = styled.table`
  border-collapse: collapse;
  width: 430px;
  color: #adafb1;
  font-size: 15px;
  border-style: hidden;
  p {
    font-size: 13px;
    color: white;
  }
  thead > tr > th:first-child {
    text-align: left;
    padding-left: 0;
  }
  tbody > tr:first-child > td:first-child {
    vertical-align: text-top;
    text-align: left;
    padding-top: 5px;
    padding-left: 0;
    border-bottom: 0;
  }
  td,
  th {
    padding: 0 10px;
    text-align: right;
    height: 25px;
    border: 1px solid #4c5056;
  }
`;
export const Table2 = styled.table`
  border-collapse: collapse;
  width: 100%;
  color: #adafb1;
  font-size: 15px;
  border-style: hidden;
  td,
  th {
    box-sizing: border-box;
    height: 26px;
    position: relative;
    padding: 3px;
    font-size: 15px;
    line-height: normal;
    text-align: right;
    border: 1px solid #4c5056;
    border-bottom: 0;
  }
  td {
    position: relative;
  }
  .bill,
  .coin {
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -14px;
    width: 30px;
    height: 28px;
    background: url(/images/coin2.png) no-repeat 2px 50%;
    background-size: contain;
  }
  .bill {
    background-image: url(/images/bill2.png);
  }
  .coin {
  }
  img {
    position: absolute;
    max-height: 100%;
    height: 20px;
    width: auto;
    top: 50%;
    margin-top: -10px;
    left: 10px;
  }
  .total {
    text-align: left;
    vertical-align: top;
  }
  .warning-medium {
    color: #ad963f;
  }
  .warning-hight {
    color: #ad4143;
  }
`;

export const RackWrapper = styled(ControllWrapper)`
  display: flex;
`;

/**
 * call drop button
 */
export const CallButton = styled.img`
  z-index: 2;
  cursor: pointer;
  position: absolute;
  top: 21px;
  right: 62px;
  height: 47px;
  cursor: pointer;
`;

const CallButtonPlace = styled.div`
  z-index: 2;
  cursor: pointer;
  position: absolute;
  top: 21px;
  right: 12px;
  height: 47px;
  & img {
    position: static;
    right: auto;
    top: auto;
  }
  &.loading {
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.5) url(/images/loader.svg) no-repeat 50% 50%;
      background-size: 35px auto;
      border-radius: 50%;
    }
  }
`;

export const CallDropButton = (props) => {
  const { action, ...rest } = props;
  const [loading, setLoading] = useState(false);

  const click = (__event) => {
    setLoading(true);
    action();
  };

  const cl = loading ? 'loading' : '';

  return (
    <CallButtonPlace className={cl}>
      <CallButton onClick={click} {...rest} />
    </CallButtonPlace>
  );
};

export const CallMakeButton = (props) => {
  const { action, ...rest } = props;
  const [loading, setLoading] = useState(false);

  const click = (__event) => {
    setLoading(true);
    action();
    setTimeout(() => setLoading(false), 700);
  };

  const cl = loading ? 'loading' : '';

  return (
    <CallButtonPlace className={cl} style={{ right: '62px' }}>
      <CallButton onClick={click} {...rest} />
    </CallButtonPlace>
  );
};
