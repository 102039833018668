export class PacketJSONRPC {
  getErrorMessage;

  constructor({ getErrorMessage }) {
    this.getErrorMessage = getErrorMessage;
  }

  /**
   * get packet
   * @param method
   * @param data
   * @returns
   */
  createPacket = (method, data) => {
    return {
      jsonrpc: '2.0',
      id: +new Date(),
      method,
      params: data,
    };
  };

  /**
   * decode packet
   * @param {*} res
   * @returns
   */
  decodePacket = (res, isLoginMethod) => {
    //console.log('🚀 ~ decode packet:', res);

    let out = {};
    let error = false;
    let errorMsg = '';
    let is403 = false;
    let is401 = false;

    // http errors
    is403 = res.status === 403 && isLoginMethod;
    is401 = res.status === 401 && isLoginMethod;

    // api errors
    const data = res.data;
    if (data.error) {
      if (data.error.code === 403) {
        is403 = true;
      }
      if (data.error.code === 401) {
        is401 = true;
      }
      errorMsg = data.error.message; //getError(data.error.code, 'errors', data.error.message);
      error = true;
    }
    if (data.result) {
      if (data.result.code === 403) {
        is403 = true;
        errorMsg = data.result.message;
        error = true;
      } else if (data.result.code === 401) {
        is401 = true;
        errorMsg = data.result.message;
        error = true;
      }
    }

    if (!error) {
      out = data.result;
    } else {
      out = errorMsg;
    }

    return { packet: out, error, is403, is401 };
  };

  /**
   * get error
   * @param res
   */
  getError = (res) => {
    //console.log('🚀 ~ get error:', res);
    let out = '';
    if (res === undefined || res === null) {
      return out;
    }
    // response error
    if (res.response) {
      out = res.response.data && res.response.data.message ? res.response.data.message : res.response.status;
    } else {
      out = res.code; // + ' ' + res.message;
    }

    return this.getErrorMessage(out.toString());
  };
}
