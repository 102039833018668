import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { socket } from 'api';
import { stopSound } from 'store/actions/sounds';
import { dropCall } from 'store/actions/calls';
import { updateStatus } from 'store/actions/equipment';

const getDisplayName = (WrappedComponent) => {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
};

const isOperator = () => {
  return true; //window.user.info.access_level === 1;
};

const withRackLogic = (WrappedComponent, settings) => {
  class WithRackLogic extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: false,
        showReloadPopup: false,
        notification: '',
        reloadType: settings.reloadType,
        newMode: '',
        lastMsg: '',
      };
    }

    componentDidMount() {
      this._ismounted = true;
    }

    componentWillUnmount() {
      this._ismounted = false;
    }

    componentDidUpdate(prevProps) {
      if (prevProps.status !== this.props.status) {
        //console.log('t1', prevProps, this.props)
        if (this._ismounted) {
          this.setState({ newMode: this.props.status.mode });
        }
      }
    }

    sendControlMsg = (message, callback, params = {}, withLoading = true) => {
      //console.log(message, params)
      //if (message && message !== this.state.lastMsg) {

      window.clearTimeout(this.timeout);
      this.setState({ lastMsg: message, loading: withLoading });
      this.timeout = window.setTimeout(() => {
        if (this._ismounted) {
          this.setState({ lastMsg: '' });
        }
      }, 3000);

      if (message === 'get_state' && withLoading) {
        this.props.updateStatus({ source: this.props.id, params: { mode: undefined } });
      }
      const msg = Object.assign({ target: this.props.id, user_id: window.user.getId() }, params);
      socket.call(message, msg);
      //}

      if (callback) {
        callback();
      }
    };

    toggleReloadPopup = () => {
      this.setState({
        showReloadPopup: !this.state.showReloadPopup,
        notification: '',
      });
    };

    setReloadType = (reloadType) => {
      this.setState({ reloadType: reloadType });
    };

    setNotification = (notification) => {
      this.setState({ notification: notification });
    };

    setNewMode = (mode) => {
      this.setState({ newMode: mode });
    };

    makeCall = () => {
      const id = this.props.id;
      this.props.stopSound('call');

      if (this.props.call && isOperator()) {
        socket.call('call_answer', this.props.call, () => {
          window.addActiveCall(id);
        });
        return;
      }

      socket.call('call_create', { target: id }, () => {
        window.addActiveCall(id);
      });
    };

    cancelCall = () => {
      //const id = this.props.id;
      //console.log('🚀 ~ cancelCall');
      try {
        this.props.stopSound('call');
      } catch (e) {
        console.log('🚀 stopsound error:', e);
      }
      const isActive = window.isActiveCall(this.props.id);

      if (isOperator()) {
        if (isActive) {
          socket.call('call_end', {
            id: this.props.call.id,
            target: this.props.call.source_inner_id,
          });
        } else {
          socket.call('call_reject', {
            id: this.props.call.id,
            source: this.props.call.source_inner_id,
          });
        }
        this.props.dropCall(this.props.call.id);
      }
    };

    render() {
      return (
        <WrappedComponent
          // shared methods
          sendControlMsg={this.sendControlMsg}
          toggleReloadPopup={this.toggleReloadPopup}
          setReloadType={this.setReloadType}
          setNotification={this.setNotification}
          setNewMode={this.setNewMode}
          makeCall={this.makeCall}
          cancelCall={this.cancelCall}
          // shared properties
          sharedState={{ ...this.state }}
          {...this.props}
        />
      );
    }
  }

  WithRackLogic.displayName = `WithRackLogic(${getDisplayName(WrappedComponent)})`;

  return WithRackLogic;
};

const mapStateToProps = (store, props) => {
  const rackEvents = store.events.events[props.id];
  const status = store.equipment[props.id];
  return {
    call: store.calls.calls[props.id],
    events: rackEvents,
    status: status || {},
  };
};

const composedRackLogic = compose(connect(mapStateToProps, { stopSound, updateStatus, dropCall }), withRackLogic);

export default composedRackLogic;
