import React from 'react';
import { connect } from 'react-redux';

import EventWindow from './windows/event-window';
import CallsWindow from './windows/calls/CallsWindow';
import RackWindow from './windows/racks/RackWindow';
import SignalsWindow from './windows/signals/SignalsWindow';
import DoubleBoard from './windows/scoreboard/Double';
import SingleBoard from './windows/scoreboard/Single';
import CallError from './windows/errors/CallError';
import Denominations from './windows/denominations';
import Denominations2 from './windows/denominations2/denominations2';
import Settings from './windows/settings/settings';
import SettingsSIP from './windows/settingssip';
import FotoWindow from './windows/foto/foto';
import { WindowVisibleController } from './WindowsController.styled';

class Windows extends React.Component {
  state = {
    order: [],
  };

  static getDerivedStateFromProps(nextProps, state) {
    // console.log('🚀 ~ componentWillReceiveProps ~ this.state.order:', state.order);
    if (state.order.indexOf(nextProps.newWindow) === -1) {
      //console.log('wc get props', nextProps.newWindow)
      const order = [...state.order];
      order.push(nextProps.newWindow);
      // console.log('🚀 ~ this.setState ~ order:', order);
      return {
        order,
      };
    }
    return null;
  }

  // componentWillReceiveProps(nextProps) {
  //   console.log('🚀 ~ componentWillReceiveProps ~ this.state.order:', this.state.order);
  //   if (this.state.order.indexOf(nextProps.newWindow) === -1) {
  //     //console.log('wc get props', nextProps.newWindow)
  //     this.setState((state) => {
  //       let order = [...state.order];
  //       order.push(nextProps.newWindow);
  //       console.log('🚀 ~ this.setState ~ order:', order);
  //       return {
  //         order,
  //       };
  //     });
  //   }
  // }

  setActive = (id) => {
    //console.log('wc setActive', id)
    this.setState((state) => {
      let order = [...state.order];
      order = order.filter((e) => e !== id);
      order.push(id);
      return {
        order: order,
      };
    });
  };

  // removeFromOrder = (id) => {
  //   //console.log('wc removeFromOrder', id)
  //   this.setState((state) => {
  //     let order = [...this.state.order];
  //     order = order.filter((e) => e !== id);
  //     return {
  //       order: order,
  //     };
  //   });
  // };

  getWindows = () => {
    const windows = [];

    // console.log('🚀 ~ this.state.order.forEach ~ this.state.order:', this.state.order);
    this.state.order.forEach((id) => {
      let item = this.props.windows[id];
      // console.log('wc windows', item, id)
      let coord = {};
      this.state.order;
      if (!item) {
        //this.removeFromOrder(id)
      } else {
        let Window = null;

        switch (item.type) {
          case 'foto':
            Window = FotoWindow;
            break;
          case 'event':
            Window = EventWindow;
            break;
          case 'calls':
            Window = CallsWindow;
            break;
          case 'rack':
            Window = RackWindow;
            break;
          case 'signals':
            Window = SignalsWindow;
            break;
          case 'denominations':
            Window = Denominations;
            break;
          case 'denominations2':
            Window = Denominations2;
            break;
          case 'double_board':
            Window = DoubleBoard;
            break;
          case 'single_board':
            Window = SingleBoard;
            break;
          case 'settings':
            Window = Settings;
            coord.defaultPosition = { x: 500, y: 0 };
            break;
          case 'settingssip':
            Window = SettingsSIP;
            coord.defaultPosition = { x: 500, y: 0 };
            //coord.left = '42%';
            break;
          case 'call_error':
            Window = CallError;
            break;
          default:
            throw new Error('Unknown window type');
        }

        windows.push(
          <WindowVisibleController key={'win_ev_' + id} onClick={() => this.setActive(item.id)}>
            <Window data={item} {...coord} />
          </WindowVisibleController>
        );
      }
    });

    return windows;
  };

  render() {
    return <div>{this.getWindows()}</div>;
  }
}

const mapStateToProps = (store) => {
  //console.log(store.windows)
  // console.log('🚀 ~ mapStateToProps ~ store.windows:', store.windows);
  return {
    windows: store.windows.openedWindows,
    newWindow: store.windows.newWindow,
  };
};

export default connect(mapStateToProps)(Windows);
