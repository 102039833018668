import React from 'react';
import moment from 'moment';

import { api, socket } from 'api';
import withRackLogic from '../withRackLogic';
//import VideoBlock from "../VideoBlock";
import { Indicator } from '../Indicator';
import CashTable2 from './CashTable2';
import ChangeTable2 from './ChangeTable2';
import RadioButtonsPopup from '../RadioButtonsPopup';
import Clarification from '../Clarification';
import services from 'services';
import { GrayButton } from '../../../../shared/buttons';
import Bsk from './Bsk';
import Confirm from './Confirm';
import ConfirmBenefit from './ConfirmBenefit';
import {
  ControllWrapper,
  ReloadBlock,
  CallMakeButton,
  Mode,
  ModeHeader,
  ModeSection,
  CurrentMode,
  CallDropButton,
  CurrentModeInfo,
} from '../styledComponents';
import RackState from '../RackState';
import AppVideos from '../AppVideos';
import { TopContentWrapper, AcceptButton, BenefitPlace, BottomContent, Info, InfoHeader, StyledControlPanel } from './PaymentRackControl.styled';

let timer1;

class PaymentRackControl extends React.Component {
  state = {
    bskLoading: false,
    showBskForm: false,
    showBskConfirmForm: false,
    showBskError: false,
    card_id: null,
    blocked: false,
    errors: {},
    cams: null,
    showBenefitAdd: false,
  };

  componentDidMount() {
    var self = this;
    this._ismounted = true;

    this.props.sendControlMsg('get_video_urls');
    this.props.sendControlMsg('get_state');
    this.createtime = moment().unix();

    //console.log('🚀 ~ componentDidMount ~ this.props.id', this.props.id);
    window.socket.on('get_video_urls', (msg) => {
      //console.log('🚀 ~ get_video_urls paymentrackcontrol', this.props.id, msg.target);
      if (msg.target === this.props.id) {
        if (self._ismounted) {
          self.setState({ cams: services.prepareVideoUrls(msg) });
        }
      }
    });

    window.socket.on('dispense_card', (msg) => {
      //console.log("🚀 ~ window.socket.on ~ msg", msg);
      if (this.state.bskLoading) {
        clearTimeout(timer1);
        if (self._ismounted && self.bskPressed) {
          self.setState({
            showBskForm: true,
            bskLoading: false,
            errors: {},
            card_id: msg.card_id,
          });
        }
      }
    });
  }

  componentWillUnmount() {
    this._ismounted = false;
    if (timer1) {
      clearTimeout(timer1);
    }
  }

  cancelOperation = () => {
    const msg = {
      target: this.props.id,
      //data: '{"message": "cancel operation"}',
      user_id: window.user.getId(),
    };
    socket.call('cancel', msg);
  };

  submitReload = () => {
    if (this.props.sharedState.reloadType === 'reboot') {
      this.props.setNotification('reload_confirm');
    } else {
      this.props.sendControlMsg(this.props.sharedState.reloadType, () => this.props.setNotification('reload_help'));
    }
  };

  submitNewMode = () => {
    this.props.sendControlMsg('set_mode', null, {
      mode: this.props.sharedState.newMode,
    });
  };

  getCurrentMode = () => {
    if (this.props.status.mode === 'regular') {
      return 'штатный';
    } else if (this.props.status.mode === 'offline') {
      return 'отключен';
    } else if (this.props.status.mode === 'blocked') {
      return 'блокированный';
    } else if (this.props.status.mode === 'emergency') {
      return 'ошибка';
    }
  };

  getReloadPopupButtons = () => {
    let buttons = [
      {
        msg: 'reboot',
        func: this.props.setReloadType,
        text: 'Не работает контроллер',
      },
      {
        msg: 'restart_lightdm',
        func: this.props.setReloadType,
        text: 'Завис экран на паркомате',
      },
    ];

    return buttons;
  };

  getClarifications = () => {
    return [
      {
        imgSrc: '/images/normal.png',
        name: 'Штатный',
        text: 'Работа устройства в соответствии с алгоритмами системы',
      },
      {
        imgSrc: '/images/blocked.png',
        name: 'Блокированный',
        text: 'Платежный терминал заблокирован оператором',
      },
    ];
  };

  // show bsk form
  showBskForm = () => {
    if (this.state.bskLoading) {
      return;
    }
    if (timer1) {
      clearTimeout(timer1);
    }

    if (this.state.showBskForm) {
      this.setState({
        showBskConfirmForm: false,
        showBskForm: false,
        errors: {},
      });
      return;
    }

    this.setState({ bskLoading: true, showBskConfirmForm: false }, () => {
      const msg = {
        target: this.props.id,
        //data: '{"message": "cancel operation"}',
      };
      this.bskPressed = true;
      socket.call('dispense_card', msg);
      timer1 = setTimeout(() => {
        this.setState({ showBskError: true, bskLoading: false });
        this.bskPressed = false;
      }, 60000);
    });
  };

  // show bskconfirm form
  showBskConfirmForm = () => {
    if (this.state.bskLoading) {
      return;
    }
    this.setState({ showBskConfirmForm: true });
  };
  closeBskConfirmForm = () => {
    this.setState({ showBskConfirmForm: false, showBskError: false });
  };

  // close bsk form
  closeBsk = () => {
    this.setState({ showBskForm: false, errors: {} });
  };
  // add bsk form
  addBsk = async (data) => {
    if (this.state.bskLoading) {
      return;
    }
    this.setState({ bskLoading: true });
    //console.log("🚀 ~ data", data);
    var params = Object.assign({}, data);
    if (params.vehicle_class) {
      if (params.vehicle_class === '-1') {
        params.vehicle_class = null;
      } else {
        params.vehicle_class = parseInt(params.vehicle_class, 0);
      }
    }
    if (params.vehicle_category) {
      if (params.vehicle_category === '-1') {
        params.vehicle_category = null;
      } else {
        params.vehicle_category = parseInt(params.vehicle_category, 0);
      }
    }
    params.is_blocked = params.is_blocked === 'true';

    return await api
      .call('subscriptions.add', params)
      .then((data) => {
        if (data.error) {
          //reject(data.error.data);
          this.setState({ bskLoading: false, errors: data.error.data });
        } else {
          //resolve(data);
          this.setState({
            showBskForm: false,
            errors: {},
            bskLoading: false,
          });
        }
      })
      .catch(() => {
        this.setState({ bskLoading: false, errors: {} });
      });
  };

  unblock = () => {
    if (this.state.blocked) {
      return;
    }
    this.setState({ blocked: true }, () => {
      this.props.sendControlMsg('unblock');
      setTimeout(() => {
        this.setState({ blocked: false });
      }, 10000);
    });
  };

  addBenefit = () => {
    this.setState((state) => {
      return { showBenefitAdd: !state.showBenefitAdd };
    });
  };

  benefitTakeFoto = () => {
    this.props.sendControlMsg('racks.get_photo', undefined, { content: true });
  };

  render() {
    const { cams, showBskForm, showBskConfirmForm, showBskError, bskLoading, showBenefitAdd } = this.state;

    const rightCall = window.haveRight('mnemo.calls');
    const rightControl = window.haveRight('mnemo.rack_control');
    const { status } = this.props;
    const { mode } = status;
    const offline = mode === 'offline';
    const isCardDispenser = !!status.card_dispenser;
    let cl = ''; //offline ? "offline" : "";
    let cl1 = offline ? 'offline' : '';
    let cl2 = offline ? 'offline1' : '';
    if (!mode) {
      cl += ' loading';
      cl1 += ' loading';
      cl2 += ' offline1';
    }
    const clMode = mode === 'emergency' ? 'error' : '';
    const error = mode === 'emergency';

    return (
      <ControllWrapper className={cl}>
        <TopContentWrapper>
          <AppVideos cams={cams} id={this.props.id} cl={cl2} />

          <StyledControlPanel className={cl1}>
            {rightCall && <CallMakeButton action={this.props.makeCall} src="/images/audio.png" />}
            {rightCall && this.props.call && <CallDropButton action={this.props.cancelCall} src="/images/audio-2.png" />}
            <ReloadBlock>
              {/*<GrayButton onClick={this.props.toggleReloadPopup}><img src='/images/rebut.png' alt='' />Перезагрузить</GrayButton>*/}

              <GrayButton disabled={!rightControl} className={this.state.blocked ? 'loading' : ''} onClick={this.unblock}>
                <img src="/images/unlock.png" alt="" />
                Разблокировать оборудование
              </GrayButton>

              {isCardDispenser && window.haveRight('mnemo.add_subscriptions') && (
                <div style={{ position: 'relative' }}>
                  <GrayButton className={bskLoading ? 'loading' : ''} onClick={this.showBskConfirmForm}>
                    Выдать БСК
                  </GrayButton>
                  {showBskConfirmForm && <Confirm cancel={this.closeBskConfirmForm} ok={this.showBskForm} />}
                  {showBskError && <Confirm cancel={this.closeBskConfirmForm} error={true} />}
                  {showBskForm && (
                    <Bsk
                      errors={this.state.errors}
                      loading={bskLoading}
                      id={this.props.id}
                      card={this.state.card_id}
                      close={this.closeBsk}
                      action={this.addBsk}
                    />
                  )}
                </div>
              )}
              {showBenefitAdd && (
                <ConfirmBenefit
                  id={this.props.id}
                  takeFoto={this.benefitTakeFoto}
                  close={this.addBenefit}
                  //useBenefit={this.benefitUse}
                  //meanId={this.props.data}
                />
              )}
              {rightControl && this.props.sharedState.showReloadPopup && (
                <RadioButtonsPopup
                  type={this.props.data.type}
                  notification={this.props.sharedState.notification}
                  sendControlMsg={this.props.sendControlMsg}
                  submit={this.submitReload}
                  currentState={this.props.sharedState.reloadType}
                  togglePopup={this.props.toggleReloadPopup}
                  buttons={this.getReloadPopupButtons()}
                />
              )}
            </ReloadBlock>
            <Mode>
              <ModeHeader>
                Режимы работы <Clarification clarifications={this.getClarifications()} />
                <CurrentMode className={clMode}>
                  Текущий режим: <span> {this.getCurrentMode()} </span>
                  {error && <CurrentModeInfo>оборудования АПП</CurrentModeInfo>}
                </CurrentMode>
              </ModeHeader>
              {!services.isOperator() && (
                <React.Fragment>
                  <ModeSection height={'40px'}>
                    <Indicator
                      onClick={() => (rightControl ? this.props.setNewMode('regular') : null)}
                      active={this.props.sharedState.newMode ? this.props.sharedState.newMode === 'regular' : this.props.status.mode === 'regular'}
                    />{' '}
                    Штатный
                  </ModeSection>
                  <ModeSection height={'40px'}>
                    <Indicator
                      onClick={() => (rightControl ? this.props.setNewMode('blocked') : null)}
                      active={this.props.sharedState.newMode ? this.props.sharedState.newMode === 'blocked' : this.props.status.mode === 'blocked'}
                    />{' '}
                    Блокированный
                  </ModeSection>
                </React.Fragment>
              )}
            </Mode>
            {!services.isOperator() && (
              <AcceptButton disabled={!rightControl} onClick={this.submitNewMode}>
                Применить
              </AcceptButton>
            )}
            <BenefitPlace>
              <GrayButton disabled={!window.haveRight('mnemo.add_handicapped')} onClick={this.addBenefit}>
                Льгота
              </GrayButton>
            </BenefitPlace>
          </StyledControlPanel>
        </TopContentWrapper>
        {this.props.status.cashbox && (
          <BottomContent>
            <Info>
              <InfoHeader>Сумма в стекере</InfoHeader>
              {!isNaN(Math.round(this.props.status.cashbox_sum / 100)) && (
                <InfoHeader className="right">{Math.round(this.props.status.cashbox_sum / 100)} ₽</InfoHeader>
              )}
              <CashTable2 items={this.props.status.cashbox} />
            </Info>
            <Info>
              <InfoHeader>Выдача сдачи</InfoHeader>
              {!isNaN(Math.round(this.props.status.payout_sum / 100)) && (
                <InfoHeader className="right">{Math.round(this.props.status.payout_sum / 100)} ₽</InfoHeader>
              )}
              <ChangeTable2 items={this.props.status.payout} />
            </Info>
          </BottomContent>
        )}
        <RackState sendControlMsg={this.props.sendControlMsg} id={this.props.id} timeOut={60000} offline={offline} />
      </ControllWrapper>
    );
  }
}

const rackSettings = {
  type: 'app',
  reloadType: 'reboot',
};

export default withRackLogic(PaymentRackControl, rackSettings);
