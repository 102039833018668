import { ADD_WIDGET, REMOVE_WIDGET } from '../constants'

export function addWidget(widgetType){
  return{
    type: ADD_WIDGET,
    data: widgetType
  }
}

export function removeWidget(widgetType){
  return{
    type: REMOVE_WIDGET,
    data: widgetType
  }
}