import React from 'react';
import { connect } from 'react-redux';

import { socket } from 'api';
import { GridBox, Grid, TableHead, TableHeadRow, TableHeaders, SubmitAll, SubmitAllPlace } from '../../../shared/grid';
import Signal from '../../../Signal';
import { deactivateEvent, deactivateAllEvents } from '../../../../store/actions/events';

const test = false;
const demoData = [
  {
    created_at: '2022-04-25T09:40:29.907508+03:00',
    method: 'controller.gate_closed',
    replace: 'controller.gate_closing_failed',
    id: '99471482-c462-11ec-ae5d-feb67f45ce4a',
    message: 'Контроллер проезда: шлагбаум закрыт',
    parking_id: '062',
    source: '062-ENTRY-1-1',
    source_type: 'rack.lane',
    status: 'processed',
    timestamp: '2022-04-25T09:40:29.080701+03:00',
    type: 'info',
    updated_at: '2022-04-25T09:40:29.907514+03:00',
  },
];

function SignalsGrid(props) {
  const signals = test ? demoData : props.signals;

  function getSignalsList() {
    let signalsList = [];

    if (signals) {
      signalsList = signals.map((item, key) => {
        return <Signal view={'confirm'} key={key} {...item} />;
      });
    }

    return signalsList;
  }

  const rightConfirm = window.haveRight('mnemo.confirm_events');

  function closeAll() {
    const id = props.source;
    socket.call('confirm_event_by_rack', { target: id });
    props.deactivateAllEvents(id);
  }

  return (
    <GridBox>
      {rightConfirm && !!signals && signals.length > 0 && (
        <SubmitAllPlace>
          <SubmitAll onClick={closeAll}>Подтвердить все</SubmitAll>
        </SubmitAllPlace>
      )}
      <Grid>
        <TableHead>
          <TableHeadRow>
            <TableHeaders width="190">Начало сигнала</TableHeaders>
            {/*<TableHeaders width="120">Приоритет</TableHeaders>*/}
            <TableHeaders>Описание сигнала</TableHeaders>
            <TableHeaders align="right" width="190">Действия</TableHeaders>
          </TableHeadRow>
        </TableHead>
        <tbody>{getSignalsList()}</tbody>
      </Grid>
    </GridBox>
  );
}

export default connect(null, {
  deactivateEvent,
  deactivateAllEvents,
})(SignalsGrid);
