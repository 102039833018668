import styled from 'styled-components';

export const RackWrapper = styled.div`
  cursor: pointer;
  position: relative;
  min-height: 94px;
  width: 56px;
  background-color: #171a1f;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const Barrier = styled.div`
  margin: 2px 0;
  width: 23px;
  height: 13px;
  background: url('/images/barrier.png');
  background-repeat: no-repeat;
`;

export const Id = styled.div`
  position: absolute;
  font-size: 14px;
  font-weight: bold;
  bottom: 5px;
  color: #171a1f;
  text-align: center;
  &.server,
  &.cashbox {
    padding: 1px 2px;
    border-radius: 2px;
    background: rgba(255, 255, 255, 0.85);
    bottom: -14px;
  }
`;

export const IconTitle = styled.div`
  position: relative;
  height: 42px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  margin: 2px 0 5px 0;
  &.server,
  &.cashbox {
    img {
      position: absolute;
      top: -10px;
    }
  }
  &.server {
    img {
      top: -13px;
    }
  }
`;
