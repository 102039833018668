import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Place, PlaceItem } from './options-checkbox.styled';

const OptionsCheckbox = (props) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.socket.on(props.name, () => {
      props.sendControlMsg('get_state');
    });
    window.socket.on('get_state', () => {
      setLoading(false);
    });
  }, []);

  const getPropName = (prop) => {
    prop = prop.split('.');
    return prop[1];
  }

  const setOption = (event) => {
    const val = event.target.checked;
    setLoading(true);
    props.sendControlMsg(
      props.name,
      () => {
        //this.setState({ loading: false });
      },
      { enable: val }
    );
  };

  const name = getPropName(props.name);
  const checked = props.state[name];

  return (
    <Place loading={loading}>
      <PlaceItem>
        <input type="checkbox" id={props.name} checked={checked} onChange={setOption} />
        <label htmlFor={props.name}>{props.title}</label>
      </PlaceItem>
    </Place>
  );
};

OptionsCheckbox.propTypes = {
  state: PropTypes.object,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  sendControlMsg: PropTypes.func.isRequired,
};

export default OptionsCheckbox;
