import React from 'react'
import { Field } from 'redux-form'
import { createTextMask, createNumberMask } from 'redux-form-input-masks'

import SourceInput from '../sourceInputs/FieldSelector'



const FieldComponent = (props) => {
  const { mask } = props 
  let textMask = null
  let numberMask = null

  switch (typeof mask) {
    case 'string':
      textMask = createTextMask({
        pattern: props.mask
      })
      break
    case 'object':
      numberMask = createNumberMask(mask)
      break
    default:
      break
  }

  const normalize = value => {
    if (props.naturalNumber) {
      if (!value || /^0+$/g.test(value)) return '0'
      return value.replace(/[^\d]/g, '').replace(/^0+(?=[1-9])/g, '')
    } return value
  }
  
  return (
    <Field
      component={SourceInput}
      className={props.className}
      validate={props.validate}
      Tip={props.tip}
      mask={props.mask}
      name={props.name}
      label={props.label}
      placeholder={props.placeholder}
      type={props.type}
      disabled={props.disabled}
      value={props.value}
      data={props.data}
      normalize={normalize}
      withoutWrapper={props.withoutWrapper}
      // need for autocomplete field
      getList={props.getList}
      setAutocompleteList={props.setAutocompleteList}
      requestParams={props.requestParams}
      // end
      {...textMask}
      {...numberMask}
    >
      {props.children}
    </Field>
  )
}

export default FieldComponent