import styled from 'styled-components';

export const Content = styled.div`
  position: absolute;
  right: 20px;
  bottom: 0px; 
  /* transform: translate(500px, 500px); */
  /* transform-origin: bottom right; */

  width: 240px;
  opacity: 0.7;
  border-radius: 5px;
  background-color: #000000;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.58);
  padding: 10px;
  box-sizing: border-box;
  p {
    align-items: center;
    display: flex;
    margin: 0;
    &:last-child {
      margin-top: 10px;
    }
  }
`;

export const TypeIcon = styled.div`
  img {
    height: 40px;
  }
`;

export const CallStatus = styled.div`
  text-align: left;
  font-size: 14px;
  color: #fff;
  width: 60px;
`;

export const DropCall = styled.div`
  cursor: pointer;
`;

export const TopPanel = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const BottomPanel = styled.div`
  border-top: 1px solid #1b1b1b;
  color: #7d7d7d;
  font-size: 14px;
  margin-top: 10px;
  padding-top: 5px;
  box-sizing: border-box;
`;

export const Dot = styled.span`
  display: block;
  min-width: 10px;
  height: 10px;
  background-color: ${(props) => props.color};
  margin-right: 10px;
  border-radius: 50%;
`;
