import React from 'react';
import { connect } from 'react-redux';

import { socket } from 'api';
import { setBoardsData } from '../../../store/actions/boards';
import {
  BoardWrapper,
  Comment,
  CommentInput,
  Header,
  Label,
  Places,
  PlacesInput,
  PlacesInputArrows,
  PlacesInputWrapper,
  SizedBlueButton,
  ZoneInfoBig,
} from './Board.styled';

class Board extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      comment: '',
      newCount: props.count,
    };
  }

  componentDidMount() {
    socket.call('get_zones_status');
    window.socket.on('set_zone_status', (msg) => {
      this.props.setBoardsData([msg.zone]);
    });
  }

  setValue = (e) => {
    const val = parseInt(e.target.value, 0);
    if (val <= this.props.max && val >= 0) {
      this.setState({ newCount: val });
    }
  };

  setComment = (e) => {
    this.setState({ comment: e.target.value });
  };

  increaseValue = () => {
    let cnt = this.state.newCount;
    if (cnt < this.props.max) {
      cnt++;
      this.setState({ newCount: cnt });
    }
  };

  decreaseValue = () => {
    let cnt = this.state.newCount;
    //if (cnt > 0) {
    cnt--;
    this.setState({ newCount: cnt });
    //}
  };

  accept = () => {
    //if (this.state.newCount) {
    socket.call(
      'set_zone_status',
      {
        user_id: window.user.getId(),
        zone_id: this.props.data.zoneId,
        current: this.props.max - this.state.newCount,
        comment: this.state.comment,
      },
      () => {
        this.setState({ comment: '' });
      }
    );
    //}
  };

  render() {
    const { newCount, comment } = this.state;

    return (
      <BoardWrapper>
        <Header>Количество свободных мест</Header>
        <Places>
          <div>
            <Label>Текущее</Label>
            <ZoneInfoBig zoneId={this.props.data.zoneId} color={this.props.data.color} />
          </div>
          <div>
            <Label>Новое</Label>
            <PlacesInputWrapper>
              <PlacesInput type="number" max={this.props.max} value={newCount} onChange={this.setValue} />
              <PlacesInputArrows>
                <img src="/images/rectangle-215.png" onClick={this.increaseValue} alt="" />
                <img src="/images/rectangle-216.png" onClick={this.decreaseValue} alt="" />
              </PlacesInputArrows>
            </PlacesInputWrapper>
            <Comment>
              <Label>Комментарий</Label>
              <CommentInput type="text" value={comment} onChange={this.setComment} />
            </Comment>
            <SizedBlueButton onClick={this.accept}>Применить</SizedBlueButton>
          </div>
        </Places>
      </BoardWrapper>
    );
  }
}

const mapStateToProps = function (store, props) {
  let count = store.boards.boards[props.data.zoneId]
    ? store.boards.boards[props.data.zoneId].maximum - store.boards.boards[props.data.zoneId].current
    : 0;
  let max = store.boards.boards[props.data.zoneId] ? store.boards.boards[props.data.zoneId].maximum : 0;
  return {
    count: count,
    max: max,
  };
};

export default connect(mapStateToProps, { setBoardsData })(Board);
