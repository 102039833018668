import { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import ruRU from 'rc-calendar/lib/locale/ru_RU';

import { socket } from 'api';
//import services from 'services';
import { LocalStorage } from 'libs/common';
import { useFilterRefresher } from 'libs/hooks';
import Filter from './filter';
import Grid from './grid';
import { Content } from './signals-history.styled';

// storage for AR
const storage = new LocalStorage('filter');

// default filter
const limit = 30;
const date = new Date();
const firstDay = moment(new Date(date.setHours(0, 0, 0, 0)));
const lastDay = moment(new Date(date.setHours(23, 59, 59, 0)));
const defaultFilter = {
  time_from: firstDay,
  time_to: lastDay,
  message: '',
  source: '',
  type: '',
  equipment_type: '',
};

// refresh timer
let timer;
const timerTimeout = 100;

/**
 * general history
 * @returns
 */
export const SignalsHistoryAll = (props) => {
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(defaultFilter);
  const [offset, setOffset] = useState(0);
  const [events, setEvents] = useState([]);
  const last = useRef(1);
  const blocking = useRef(false);
  const lastAR = useRef(false);

  const { apiMethod, target } = props;

  /**
   * add events after scroll
   */
  const addEvents = () => {
    if (blocking.current) {
      return;
    }

    if (last.current) {
      blocking.current = true;
      setOffset((offset) => offset + limit);
    }
  };

  /**
   * update filter
   * --- auto get events after
   */
  const updateFilter = (param, value) => {
    if (timer) {
      window.clearTimeout(timer);
    }

    timer = window.setTimeout(() => {
      setLoading(true);
      last.current = 1;
      setEvents([]);
      setOffset(0);
      const out = typeof param === 'object' ? param : { [param]: value };
      const newFilter = { ...filter, ...out };
      setFilter(newFilter);
    }, timerTimeout);
  };

  /**
   * reset filter
   * --- auto get events after
   */
  const resetFilter = () => {
    // exit if autorefresh
    if (refreshActive) {
      return;
    }
    last.current = 1;
    setLoading(true);
    setOffset(0);
    setEvents([]);
    setFilter(defaultFilter);
  };

  /**
   * get events without blocking
   */
  const getEventsSimple = (flt, resetOffset = false) => {
    setLoading(true);
    const params = { ...flt };
    params.source = params.source.trim();
    params.offset = resetOffset ? 0 : offset; //offset: this.state.events.length,
    params.limit = limit;
    if (target) {
      //params.equipment_type = services.getEquipmentFilterType(rackType); //window.service.call('getEquipmentType', props.id),
      params.target = target;
    }
    socket.call(apiMethod, params);
  };

  /**
   * auto refresher
   * --- direct get events
   */
  const refresh = () => {
    const refreshFilter = { ...defaultFilter };
    refreshFilter.time_from = null;
    refreshFilter.time_to = null;
    setOffset(0);
    getEventsSimple(refreshFilter, true);
  };
  const refreshKey = 'refresh' + (target ? target : '');
  const [refreshActive, refreshInput] = useFilterRefresher(refreshKey, false, refresh, storage);

  /**
   * set events from socket
   */
  useEffect(() => {
    const onGetEvents = (data) => {
      blocking.current = false;
      setLoading(false);

      if (target) {
        if (!data.target === target) {
          return;
        }
      } else {
        if (data.target) {
          return;
        }
      }

      last.current = data.events.length;
      lastAR.current = refreshActive;
      setEvents((previous) => (refreshActive ? data.events : previous.concat(data.events)));
    };

    const customMethod = 'get_events_history';
    window.socket.on(customMethod, onGetEvents);
    return () => {
      window.socket.off(customMethod, onGetEvents);
    };
  }, [refreshActive]);

  /**
   * refresh data if changed filter, offset, autorefresh
   */
  useEffect(() => {
    if (!refreshActive) {
      if (lastAR.current) {
        setEvents([]);
      }
      getEventsSimple(filter);
    }
  }, [refreshActive, filter, offset]);

  // render
  const filterParams = { refresh, updateFilter, resetFilter, filter, refreshActive, refreshInput, target };
  return (
    <Content>
      <Filter {...filterParams} />
      <Grid loading={loading} signals={events} addEvents={addEvents} target={target} />
    </Content>
  );
};
