import { ADD_WIDGET, REMOVE_WIDGET } from '../constants'

const widgetsReducer = (state, action) => {

  const defaultState = {
    activeWidgets: []
  }

  if(!state){
    state = defaultState
  }

  const newActiveWidgets = state.activeWidgets.slice(0)

  switch(action.type){
    case ADD_WIDGET:
      if(newActiveWidgets.indexOf(action.data) === -1){
        newActiveWidgets.push(action.data)
      }
      return {
        ...state,
        activeWidgets: newActiveWidgets
      }
    case REMOVE_WIDGET:
      let index = newActiveWidgets.indexOf(action.data);
      if (index !== -1) {
        newActiveWidgets.splice(index, 1);
      }
      return  {
        ...state,
        activeWidgets: newActiveWidgets
      }
    default:
      return state
  }
}

export default widgetsReducer
