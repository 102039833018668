import React from 'react';
//import { connect } from 'react-redux';

import withRackLogic from '../racksControl/withRackLogic';
import { Reloader } from './options.styled';
import { RedButton } from '../../../shared/buttons';
import Confirm from './confirm';

class Reload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reloadConfirm: false,
    };
  }

  reloadConfirm = () => {
    this.setState({ reloadConfirm: true });
  };

  reload = () => {
    this.props.sendControlMsg('racks.reset_main_relay', () => {
      this.setState({ reloadConfirm: false });
    });
  };
  closeConfirm = () => {
    this.setState({ reloadConfirm: false });
  };

  render() {
    const { reloadConfirm } = this.state;
    const { id } = this.props;

    const canReload = window.config.nodes[id] && window.config.nodes[id].main_relay === true;
    if (!canReload) {
      return null;
    }

    return (
      <Reloader>
        <RedButton className="btn" onClick={this.reloadConfirm}>
          Перезагрузить ПК
        </RedButton>
        {reloadConfirm && <Confirm action={this.reload} close={this.closeConfirm} last={5} />}
      </Reloader>
    );
  }
}

const rackSettings = {
  type: 'rack',
  reloadType: 'reboot controller',
};

export default withRackLogic(Reload, rackSettings);
