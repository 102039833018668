import React, { Component } from 'react';
import styled from 'styled-components';

import { Block, Arrow } from './styledComponents';

const Container = styled.div``;
const Icon = styled.div`
  width: 28px;
  height: 28px;
  border: solid 1px #24282c;
  border-radius: 50%;
  color: #24282c;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
`;
const Section = styled.div`
  margin-bottom: 20px;
  p {
    color: #8d8d8d;
    font-size: 13px;
  }
`;
const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 13px;
  color: #fff;
  margin-bottom: 10px;
  img {
    margin-right: 15px;
  }
`;

class Clarifications extends Component {
  state = {
    showClarification: false,
  };

  showClarification = (value) => {
    this.setState({ showClarification: value });
  };

  getClarifications = () => {
    const clarifications = this.props.clarifications;
    if (Array.isArray(clarifications)) {
      return clarifications.map((clarification, index) => {
        return (
          <Section key={index}>
            <Header>
              <img width="28" src={clarification.imgSrc} alt="" />
              {clarification.name}
            </Header>
            <p>{clarification.text}</p>
          </Section>
        );
      });
    }
  };

  render() {
    return (
      <Container>
        <Icon onMouseEnter={() => this.showClarification(true)} onMouseLeave={() => this.showClarification(false)}>
          ?
        </Icon>
        {this.state.showClarification ? (
          <Block>
            <Arrow />
            {this.getClarifications()}
          </Block>
        ) : null}
      </Container>
    );
  }
}

export default Clarifications;
