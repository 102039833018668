import styled from 'styled-components';

export const Position = styled.div`
  position: absolute;
  top: 48px;
  left: 0;
  right: 0;
  z-index: 10;
  //bottom: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Position2 = styled.div`
  position: absolute;
  top: 5px;
  left: 0;
  width: 100%;
  /* right: 0; */
  /* z-index: 10; */
`;

export const Content = styled.div`
  /* transform-origin: top left; */
  transform: scale(${(props) => props.scale});
`;
export const ContentOther = styled.div`
  /* position: absolute;
  top: 48px;
  left: 0;
  right: 0;
  //bottom: 0;
  z-index: 11; */
  /* transform-origin: top left; */
  transform: scale(${(props) => props.scale});
`;

export const Mnemo = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  //bottom: 0;
  top: 0;
  z-index: 11;
`;
