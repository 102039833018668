import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import moment from 'moment'

import Video from './windows/racks/racksControl/Video'
import Cookies from 'js-cookie';

const Wrapper = styled.div`
  position: absolute;
  left: 145px;
  top: -14px;
  border-radius: 4px;
`
let w = 760;
let h = (w * 0.778) + 'px';
let wfull = (w * 2 + 100) + 'px';
w += 'px';
//console.log(w, h)

const WrapperVideo = styled.div`
    width: ${wfull};    
    display: flex;
    justify-content: center;
    & img{
        max-height: none;
    }
    & > *{
        width: ${w};
        margin: 0 40px;
        overflow: hidden;
    }
`;
const VideoLink = styled.a`
    display: block;
    width: 60px;
    height: 49px;
    transition: .15s all ease-out;
    border-radius: 5px;
    background: #5a5a5a url(/images/video.png) no-repeat 50%;
    background-size: 35px auto;
    margin: 0 0 100px;
    cursor: pointer;
    border: 1px solid #333;
    z-index: 99;
    position: relative;
    &:hover{
        background-color: #666;
    }
    &.active{
        border: 1px solid #999;
        background-color: #999;
    }
    &.active:hover{
        background-color: #aaa;
    }
`;

const cookieName = 'main-video-active';

class VideoBlock extends React.Component {
    constructor(props) {
        super(props);

        const active = Cookies.get(cookieName) === 'true';
        //console.log('active', active)

        this.state = {
            active: active
        }

        this.toggleVideo = this.toggleVideo.bind(this);
    }

    toggleVideo() {
        this.setState((state) => { 
            Cookies.set(cookieName, !state.active);
            return {active: !state.active};
        });
    }

    render() {
        const { active } = this.state;
        if (!window.config.video) {
            return null;
        }
        //if (!window.config || !window.config.nodes || window.config.nodes[cameraID]) {
        //    return null;
        //}
        const cameraID = this.props.cameraID;
        const node = this.props.node;
        if (!node || !cameraID) {
            return null;
        }
        const cameraUrl1 = node.cameras.main;
        const cameraUrl2 = node.cameras.face_upper;
        const v = moment().unix();
        const cl = active ? 'active' : '';

        const vurl1 = cameraUrl1; //window.config.network.http_url + '/getVideo/' + cameraUrl1 + '?key=' + v

        //console.log(active)
        return (
            <Wrapper>
                <VideoLink className={cl} onClick={this.toggleVideo} />
                { active && (
                    <WrapperVideo>
                        <Video
                            height={h}
                            params={{
                                id: 'cam1' + cameraID,
                                //src: `${window.config.network.http_url}/getVideo/${window.config.cams_map.payment_racks[this.props.id + '_1']}?key=${moment().unix()}`
                                src: vurl1
                            }}
                        />
                        { cameraUrl2 && (
                            <Video
                                height={h}
                                poster={window.config.network.http_url + '/getVideo/' + cameraUrl2 + '?key=' + v}
                                params={{
                                    id: 'cam3' + cameraID,
                                    //src: `${window.config.network.http_url}/getVideo/${window.config.cams_map.payment_racks[this.props.id + '_1']}?key=${moment().unix()}`
                                    src: window.config.network.http_url + '/getVideo/' + cameraUrl2 + '?key=' + v
                                }}
                            />
                        )}
                    </WrapperVideo>                    
                )}
            </Wrapper>
        );
    }
}

function mapStateToProps(store) {
    return {
        signals: store.events.events
    }
}

export default connect(mapStateToProps)(VideoBlock);
