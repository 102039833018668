import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import SignalsGrid from './Grid';

const SignalsWrapper = styled.div`
  height: 620px;
  width: 100%;
  display: flex;
`;

function Signals(props) {
  //console.log('🚀 ~ Signals ~ props', props);
  function getEventsArr() {
    const signalsList = [];
    if (props.signals) {
      for (let key in props.signals) {
        signalsList.push(props.signals[key]);
      }
    }

    signalsList.sort((x, y) => {
      return y.timestamp - x.timestamp;
    });

    return signalsList;
  }

  return (
    <SignalsWrapper>
      <SignalsGrid source={props.id} signals={getEventsArr()} />
    </SignalsWrapper>
  );
}

function mapStateToProps(store, props) {
  const events = [];
  const rackEvents = store.events.events[props.id];
  for (let key in rackEvents) {
    events.push(rackEvents[key]);
  }

  return {
    signals: events,
  };
}

export default connect(mapStateToProps)(Signals);
