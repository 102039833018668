import { CustomSelect } from 'libs/ui';

export const PrioritySelect = (props) => {
  const { value, change, ...rest } = props;

  const items = [
    { name: 'Все', value: '' },
    { name: 'Высокий', value: 'critical' },
    { name: 'Средний', value: 'notification' },
    { name: 'Низкий', value: 'info' },
  ];

  return <CustomSelect className="priority" value={value} onChange={change} items={items} {...rest} />;
};
