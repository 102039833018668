import styled from 'styled-components';

export const Button = styled.button`
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 10px;
  height: 42px;
  width: 42px;
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 50%;
  img {
    max-width: 100%;
    height: auto;
  }
  transition: box-shadow 0.2s ease-in;
  &:hover {
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.6);
  }
`;
