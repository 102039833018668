import { useState } from 'react';

import { Button } from './buttons.styled';

export const CallButton = (props) => {
  const { action, ...rest } = props;
  const [loading, setLoading] = useState(false);

  const click = () => {
    setLoading(true);
    action();
    setTimeout(() => setLoading(false), 700);
  };

  const cl = loading ? 'loading' : '';

  return <Button className={cl} onClick={click} {...rest} />;
};
