import React, { Component } from 'react';
import moment from 'moment';
//import styled from "styled-components";

import VideoBlockUrls from './VideoBlockUrls';
import { VideoBlockCap, TwoVideosPlace, Refresh } from './styledComponents';

class RackVideos extends Component {
  state = {
    createtime: 1,
  };

  componentDidMount = () => {
    const createtime = moment().unix();
    this.setState({ createtime: createtime });
  };

  /**
   * refresh video
   */
  refresh = () => {
    const createtime = moment().unix();
    this.setState({ createtime: createtime });
  };

  render() {
    const { createtime } = this.state;
    //console.log('🚀 ~ render ~ createtime', createtime);
    const { cams, id, cl, simple } = this.props;
    //let h1 = cams !== null && cams.length > 1 ? '620px' : '310px';

    // if no cams
    if (cams === null || !cams.length) {
      const h1 = '396px';
      return (
        <VideoBlockCap
          className={cl}
          style={{
            height: h1,
            width: '500px',
          }}
        />
      );
    }

    // prepare blocks
    const videos = [];
    if (cams !== null) {
      videos.push(
        <VideoBlockUrls key="vblock1" createtime={createtime} rackId={id} camHeight="310px" cams={[cams[0]]} />
      );
      if (cams.length > 1) {
        videos.push(
          <VideoBlockUrls
            key="vblock2"
            createtime={createtime}
            rackId={id}
            camHeight="310px"
            //cams={['main', 'face_lower']}
            cams={cams.slice(1)}
          />
        );
      }
    }

    return (
      <TwoVideosPlace>
        {!simple && <Refresh onClick={this.refresh} />}
        {videos}
      </TwoVideosPlace>
    );
  }
}

export default RackVideos;
