import styled from 'styled-components';

export const Line = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Place = styled.div`
  position: absolute;
  top: 100%;
  right: 0px;
  padding: 5px;
  background: #171a1f;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 0 10px 20px 0 rgb(0 0 0 / 58%);
`;

export const Head = styled.div`
  margin: 0 5px 0 20px;
  color: #fff;
  font-size: 14px;
  text-align: right;
  line-height: 1;
`;

export const Item = styled.div`
  text-align: center;
  margin-left:1px;
`;

export const Value = styled.div`
  
  width: 28px;
  border-radius: 3px;
  border: 1px solid #89898b;
  padding: 4px 0px;
  background: ${(props) => (props.active ? '#5c8c5e' : '#757577')};
`;

export const Title = styled.div`
  color: #222;
  font-weight: bold;
  font-size: 11px;
  line-height: 1;
`;
