import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  max-width: 472px;
  > * {
    box-sizing: border-box;
  }
  &.loading {
    position: relative;
  }
  &.loading::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.8) url('/images/loader.svg') no-repeat 50%;
    background-size: 35px auto;
  }

  input {
    box-sizing: border-box;
    font-size: 20px;
    padding: 7px 12px;
    height: 35px;
    border: 1px solid rgb(190, 190, 190);
    color: rgb(255, 255, 255);
    background-color: rgb(79, 87, 97);
    border-radius: 4px;
    max-width: 260px;
  }
`;

export const Label = styled.label`
  margin: 0 15px 0 0;
`;

export const Button = styled.button`
  margin: 0 0 0 15px;
  box-sizing: border-box;
  height: 35px;
  font-size: 20px;
  padding: 0 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(79, 13, 13, 0.5);
  background-color: #f0f0f0;
  transition: 0.2s background-color ease-in;
  &:hover {
    background-color: #ddd;
  }
`;
