import styled from 'styled-components';

import { Filter, Input, Select } from 'components/shared/filter';

export const SignalsHistoryFilterBox = styled(Filter)`
  height: 120px;
`;
export const DescriptionInput = styled(Input)`
  width: 260px;
`;
export const EquipmentSelect = styled(Select)`
  width: 120px;
`;
export const NumberInput = styled(Input)`
  width: 55px;
`;
