import styled, { css } from 'styled-components';

export const WindowVisibleController = styled.div`
  position: absolute;
  left: 20%;
  top: 15%;
  left: 0;
  top: 0;
  z-index: 999;
  width: 0;
  height: 0;
  ${(props) =>
    props.left &&
    css`
      left: ${props.left};
    `}
`;
