import { SET_CALL, SET_CALL_ACTIVE, DROP_CALL } from '../constants'

export const setCall = (call) => {
  return {
    type: SET_CALL,
    data: call
  }
}

export const setCallActive = (id) => {
  return {
    type: SET_CALL_ACTIVE,
    data: id
  }
}


export const dropCall = (id) => {
  return {
    type: DROP_CALL,
    data: id
  }
}