import { useEffect } from 'react';

export function useOutsideClicker(ref, action) {
  useEffect(() => {
    function handleClickOutside(event) {
      //console.log('🚀 ~ handleClickOutside ~ ref.current:', ref.current);
      if (ref && ref.current && !ref.current.contains(event.target)) {
        action();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}
