import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { reduxForm, change } from "redux-form";
import moment from 'moment';

import { BlueButton, WhiteButton } from "../../../../shared/buttons";
import Field from "../../../../modules/form/readyInputs";

export const required = (value) => {
  return value || value === 0 ? undefined : "Обязательное поле";
};

const StyledField = styled(Field)`
  display: flex;

  margin-bottom: 5px;
  align-items: center;
  label {
    font-size: 13px;
    width: 180px;
    line-height: 1;
    padding: 0 1rem 0 0;
    margin: 0;
  }
  & input,
  & > select,
  & > div {
    font-size: 13px;
    padding: 3px 5px;
    border: none;
    background: ${(props) => (props.error ? "#fff0f0" : "#fff")};
    color: #000;
    width: 180px;
    height: 25px;
    line-height: 25px;
    border-bottom: 1px solid rgba(255, 255, 255, 1);
    transition: 0.2s all ease-in;
    &:focus {
      outline: none;
      border-bottom-color: rgba(200, 200, 200, 1);
    }
  }
  & > select,
  & > div {
    padding-left: 0;
  }
`;

const Container = styled.div`
  padding: 16px;
  box-sizing: border-box;
  background: #fff;
  color: #000;
  position: absolute;
  max-width: 480px;
  left: 0;
  top: 35px;
  border-radius: 16px;
  z-index: 100;
`;
const Title = styled.h2`
  margin: 0 0 2rem;
  font-weight: bold;
`;
const Buttons = styled.div`
  display: flex;
  margin: 32px 0 0;
  justify-content: flex-start;
  & > button {
    margin-right: 1rem;
  }
`;

const ErrorMsg = styled.div`
  color: #933;
  margin: 1rem 0;
`;

class Bsk extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      catd: props.card,
    };
  }

  addEvent = (msg) => {
    if (
      this._mounted &&
      msg.source === this.props.id &&
      msg.data &&
      msg.data.method === "card.read"
    ) {
      //console.log("🚀 ~ window.socket.on ~ msg", msg);
      this.setState({ card: msg.data.card_id });
      this.props.dispatch(
        change(this.props.form, "mean_id", msg.data.card_id)
      );
    }
  }

  componentDidMount() {
    this._mounted = true;
    window.socket.on("add_event", this.addEvent);
  }

  componentWillUnmount () {
    this._mounted = false;
    window.socket.off("add_event",  this.addEvent);
  }

  fillList = (type) => {
    const object = window.config[type];
    let item;
    return Object.keys(object).map((el, idx) => {
      item = object[el];
      return (
        <option key={idx} value={el}>
          {item}
        </option>
      );
    });
  };

  getCategoriesList = () => {
    return this.fillList("vehicle_category_map");
  };

  getClassesList = () => {
    return this.fillList("vehicle_class_map");
  };

  render() {
    const { handleSubmit, loading } = this.props;
    //console.log('🚀 ~ render ~ this.props', this.props);

    let vehicle_category_def;
    if (this.props.categories && this.props.categories.length) {
      vehicle_category_def = this.props.categories[0].id;
    }

    let err = "";
    if (this.props.errors && this.props.errors.type) {
        if (this.props.errors.type === 'duplicate') {
          err += ' БСК с таким номером уже существует.';
        }
    }

    return (
      <Container>
        <Title>Выдать БСК</Title>
        <form onSubmit={handleSubmit}>
          {(err !== '') && (
            <ErrorMsg>
              <img src="/images/error-triangle-icon.png" alt="" />
              Ошибка {err}
            </ErrorMsg>
          )}

          <StyledField type="select" label="Статус" name="is_blocked">
            <option value={false}>Активен</option>
            <option value={true}>Заблокирован</option>
          </StyledField>

          <StyledField
            type="text"
            label="БСК"
            placeholder="3786 4678 6467 5656"
            name="mean_id"
            validate={required}
          />
          <StyledField
            type="text"
            label="Держатель"
            placeholder="Введите текст"
            name="owner"
            validate={required}
          />

          <StyledField
            validate={required}
            type="select"
            label="Класс ТС"
            name="vehicle_class"
          >
            <option value={-1}>Не задано</option>
            {this.getClassesList()}
          </StyledField>
          <StyledField
            type="select"
            label="Категория"
            name="vehicle_category"
            value={vehicle_category_def}
          >
            <option value={-1}>Не задано</option>
            {this.getCategoriesList()}
          </StyledField>

          <StyledField
            type="date"
            label="Дата действия по"
            placeholder="Дата действия по"
            name="end_at"
            //validate={required}
          />

          <StyledField
            type="text"
            label="Комментарий"
            placeholder="Введите текст"
            name="comment"
            //validate={required}
          />

          <Buttons>
            <WhiteButton onClick={() => this.props.close()}>
              Отменить
            </WhiteButton>
            <BlueButton className={loading ? 'loading' : ''} type="submit">Сохранить</BlueButton>
          </Buttons>
        </form>
      </Container>
    );
  }
}

Bsk.propTypes = {
  close: PropTypes.func.isRequired,
  //action: PropTypes.func.isRequired,
};

const d1 = moment().subtract(1, 'days');

export default reduxForm({
  form: "bsk",
  initialValues: {end_at: d1},
  onSubmit: (data, dispatch, props) => {
    return props.action(data);
  },
  onSubmitSuccess(result, dispatch, props) {
    //console.log('🚀 ~ onSubmitSuccess ~ result', result);
    dispatch(props.reset());
  },
})(Bsk);

//export default Bsk;
