import styled from 'styled-components';

import { BlueButton } from 'components/shared/buttons';

const ControlPanel = styled.div`
  width: 450px;
  position: relative;
`;

export const TopContentWrapper = styled.div`
  display: flex;
`;
export const StyledControlPanel = styled(ControlPanel)`
  padding: 30px 30px 30px 30px;
  width: calc(100% - 500px);
`;
export const AcceptButton = styled(BlueButton)`
  /*margin-left: 335px;
  */
  margin: 0.7rem 0 0;
`;
export const BottomContent = styled.div`
  /* height: 320px; */
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
`;
export const Info = styled.div`
  width: 50%;
  padding: 10px 15px;
  box-sizing: border-box;
  position: relative;
`;
export const InfoHeader = styled.div`
  font-size: 14px;
  color: #3ea5ff;
  padding-bottom: 10px;

  &.right {
    position: absolute;
    right: 20px;
    top: 10px;
    text-align: right;
    margin: 0;
    font-weight: 900;
  }
`;

export const BenefitPlace = styled.div`
  margin: 13px 0 -20px;
`;
