import * as yup from 'yup';

export const validationSchema = yup.object({
  username: yup
    .string()
    //.min(5, 'Минимум 5 символов')
    .required('Заполните поле'),
  password: yup
    .string()
    .required('Заполните поле'),
});
