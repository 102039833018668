import React from 'react';
import styled from 'styled-components';

import RackGroup from '../shared/rack/RackGroup';
import Rack from '../shared/rack/Rack';

export const PaymentTerminalWrapper = styled.div`
  position: absolute;
  left: ${(props) => props.left};
  top: ${(props) => props.top};
`;

function RacksMap() {
  const rackGroups = window.config.objects ? window.config.objects.nodes : [];
  const paymentTerminals = window.config.objects ? window.config.objects.payments : [];
  const extensions = window.config.objects ? window.config.objects.extensions : [];
  const gooses = window.config.objects && window.config.objects.gooses ? window.config.objects.gooses : [];

  // rack groups
  function getRacks() {
    const rackGroupList = rackGroups.map((item) => {
      return <RackGroup key={'rgr_' + item.id} {...item} />;
    });
    return rackGroupList;
  }

  // apps
  function getPaymentTerminals() {
    const paymentTerminalsList = paymentTerminals.map((item) => {
      const key = item.type === 'cashbox' ? 'cshbx' : 'term';
      return (
        <PaymentTerminalWrapper key={key + item.id} left={item.left} top={item.top}>
          <Rack {...item} />
        </PaymentTerminalWrapper>
      );
    });
    return paymentTerminalsList;
  }

  // ext
  function getExtensions() {
    const out = extensions.map((item) => {
      //console.log('🚀 ~ out ~ item', item);
      const key = item.type === 'cashbox' ? 'cshbx' : 'term';
      return (
        <PaymentTerminalWrapper key={key + item.id} left={item.left} top={item.top}>
          <Rack {...item} />
        </PaymentTerminalWrapper>
      );
    });
    return out;
  }

  // gooses
  function getGooses() {
    const out = gooses.map((item) => {
      return (
        <PaymentTerminalWrapper key={item.id} left={item.left} top={item.top}>
          <Rack {...item} />
        </PaymentTerminalWrapper>
      );
    });
    return out;
  }

  return (
    <div>
      {getRacks()}
      {getPaymentTerminals()}
      {getExtensions()}
      {getGooses()}
      {/*getCashboxes()*/}
    </div>
  );
}

export default RacksMap;
