import React from 'react';

import Window from 'components/shared/window/window';
import CallForm from './CallForm';

function CallsWindow(props) {
  const tabs = [{ text: 'Позвонить', id: 'call' }];

  const content = {
    call: CallForm,
  };

  return <Window tabs={tabs} content={content} width="730px" height="700px" {...props.data} />;
}

export default CallsWindow;
