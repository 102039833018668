import Draggable from 'react-draggable';

import { MainWindowWrapper } from './window-wrapper.styled';

export function WindowWrapper(props) {
  const { children, width, height, ...rest } = props;
  return (
    <Draggable bounds={'html'} {...rest}>
      <MainWindowWrapper width={width} height={height}>
        {children}
      </MainWindowWrapper>
    </Draggable>
  );
}

export default WindowWrapper;
