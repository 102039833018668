import React, { Component } from 'react'
import styled from 'styled-components'

const StyledFieldWrapper = styled.div`
  position: relative;
`
const ErrorWrapper = styled.div`
  position: relative;
  &.error {
    & > div {
      &:first-child {
        border-top: solid 1px #d33d34 !important;
        border-bottom: solid 1px #d33d34 !important;
        background-color: rgba(211, 61, 52, 0.2);
        label, input, select {
          color: #d33d34 !important;
        }
      }
    }
  }
`
const Message = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: -44px;
  height: 40px;
  min-width: 300px;
  background-color: #d33d34;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  font-size: 15px;
  color: #fff;
  line-height: 1.73;
  width: max-content;
  padding: 0 15px;
  &::before {
    content: '';
    position: absolute;
    background-color: #d33d34;
    top: -3px;
    width: 18px;
    height: 18px;
    border-bottom-right-radius: 100%;
    left: 50%;
    transform: rotate(45deg) translate(-50%, 0);
  }
`

export default class FieldWrapper extends Component {
  constructor(props) {
    super(props)
    this.state = { showTip: false }
  }

  render() {
    const Field = this.props.component
    const { Tip, meta: { error, touched } } = this.props
    return (
      <StyledFieldWrapper>
        <ErrorWrapper
          className={touched && error ? 'error' : ''}
          onMouseEnter={() => this.setState({ showTip: true })}
          onMouseLeave={() => this.setState({ showTip: false })}
        >
          <Field {...this.props} />
          {(touched && error && this.state.showTip) ?
            <Message>{error}</Message>
            : null
          }
        </ErrorWrapper>
        {Tip ? <Tip /> : null}
      </StyledFieldWrapper>
    )
  }
}