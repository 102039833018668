import io from 'socket.io-client';

export class SocketApi {
  token = '';
  url = '';
  storage;
  socket = null;
  constructor(url, storage) {
    this.storage = storage;
    this.url = url;
    //console.log('🚀 ~ socket constructor ~ url:', url);

    this.setToken(this.storage.getItem('token'));
  }

  init() {
    this.socket = io(this.url, /*{ transports: ['websocket'], withCredentials: true }*/);

    // reconnect
    this.socket.on('connect', () => {
      console.log('socket: connect');
    });

    // disconnect
    this.socket.on('disconnect', (msg) => {
      console.log('socket: disconnect', msg);
    });

    // error
    this.socket.on('error', (msg) => {
      console.log('socket: error', msg);
    });

    return this.socket;
  }

  test() {
    this.call('ping');
  }

  setToken(token) {
    this.token = token;
  }

  call(method, params = {}, callback = null) {
    if (params.token === undefined) {
      params.token = this.token;
    }
    if (!params.token) {
      throw new Error('socket: no token');
    }

    this.socket.emit(method, params);
    if (callback) {
      callback();
    }
  }

  on(type, callback) {
    return this.socket.on(type, callback);
  }

  off(type, callback) {
    return this.socket.off(type, callback);
  }
}
