import React, { Component } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.height};
  background-color: #a9a9a9;
  img {
    position: absolute;
    max-height: 310px;
  }
`;
const DoubleVideoContainer = styled(Container)`
  .active {
    height: 100%;
  }
  .inactive,
  .tiny-no-cam {
    cursor: pointer;
    width: 150px;
    height: 90px;
    bottom: 15px;
    left: 15px;
    z-index: 2;
  }
  .tiny-no-cam {
    z-index: 1;
    width: 90px;
  }
`;
const NoVideoImg = styled.img`
  width: 200px;
`;
const VideoImg = styled.img`
  width: 100%;
`;

class Video extends Component {
  componentWillUnmount() {
    let cam = null;
    const params = this.props.params;
    if (Array.isArray(params)) {
      params.forEach((element) => {
        cam = document.getElementById(element.id);
        cam.src = '';
      });
    } else {
      cam = document.getElementById(params.id);
      cam.src = '';
    }
  }

  getVideos = () => {
    const params = this.props.params;
    //console.log('🚀 ~ params', params);
    //console.log(params)
    if (Array.isArray(params)) {
      if (this.props.isA) {
        const other = [];
        params.forEach((el, idx) => {
          if (idx > 0) {
            other.push(
              <React.Fragment>
                <img className="tiny-no-cam" src="/images/no-video.png" alt="" />
                <VideoImg id={el.id} className={el.className} src={el.src} onClick={el.func} alt="" />
              </React.Fragment>
            );
          }
        });
        return (
          <DoubleVideoContainer height={this.props.height}>
            <NoVideoImg src="/images/no-video.png" alt="" />
            <VideoImg id={params[0].id} className={params[0].className} src={params[0].src} onClick={params[0].func} alt="" />
            {other}
          </DoubleVideoContainer>
        );
      }

      return (
        <DoubleVideoContainer height={this.props.height}>
          <NoVideoImg src="/images/no-video.png" alt="" />
          <VideoImg id={params[0].id} className={params[0].className} src={params[0].src} onClick={params[0].func} alt="" />
          <img className="tiny-no-cam" src="/images/no-video.png" alt="" />
          <VideoImg id={params[1].id} className={params[1].className} src={params[1].src} onClick={params[1].func} alt="" />
        </DoubleVideoContainer>
      );
    } else {
      return (
        <Container height={this.props.height}>
          <NoVideoImg src="/images/no-photo.png" alt="" />
          <VideoImg id={params.id} src={params.src} alt="" />
        </Container>
      );
    }
  };

  render() {
    return this.getVideos();
  }
}

export default Video;
