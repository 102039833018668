import React from 'react';
import { connect } from 'react-redux';

import { socket } from 'api';
import { playSound, stopSound } from 'store/actions/sounds';
import { dropCall } from 'store/actions/calls';
import { addWidget } from 'store/actions/widgets';
import { CallWrapper, ButtonBlock, CallBlock, CallIcon, CallIndicator } from './Call.styled';

const isOperator = () => {
  return true; //window.user.info.config.access_level === 1;
};

class RackCall extends React.Component {
  state = {
    showCallBlock: false,
  };

  sound = null;
  closeTimeout = null;

  componentDidMount = () => {
    if (this.props.info.status !== 'active' && isOperator()) {
      this.props.playSound('call');
    }
  };

  componentWillUnmount = () => {
    this.props.stopSound('call');
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.info.status !== 'active' && !nextProps.isSoundPlay && !nextProps.isActiveCallExist && isOperator()) {
      this.props.playSound('call');
    }
    if (nextProps.isActiveCallExist || nextProps.info.status === 'active') {
      this.props.stopSound('call');
    }
  };

  showCallBlock = () => {
    window.clearTimeout(this.closeTimeout);
    this.setState({ showCallBlock: true });
  };

  hideCallBlock = () => {
    this.closeTimeout = window.setTimeout(() => {
      this.setState({ showCallBlock: false });
    }, 100);
  };

  makeAnswerRequest = () => {
    if (!this.props.isActiveCallExist && isOperator()) {
      const id = this.props.info.source_inner_id;
      this.props.stopSound('call');
      socket.call('call_answer', this.props.info, () => {
        window.addActiveCall(id);
      });
      //window.socket.emit('message', {jsonrpc: '2.0', id: '1', method: 'calls.call', params: this.props.info})
    }
  };

  dropCall = () => {
    if (isOperator()) {
      if (this.props.isActiveCallExist) {
        socket.call('call_end', {
          id: this.props.info.id,
          target: this.props.info.source_inner_id,
        });
      } else {
        socket.call('call_reject', {
          id: this.props.info.id,
          source: this.props.info.source_inner_id,
        });
      }
      //window.socket.emit('message', {jsonrpc: '2.0', id: '1', method: 'calls.reject', params: {id: this.props.info.id, source: this.props.info.source_inner_id}})
      this.props.dropCall(this.props.info.source_inner_id);
    }
  };

  render() {
    //console.log("🚀 ~ call", this.props.info);
    return (
      <CallWrapper onMouseEnter={this.showCallBlock} onMouseLeave={this.hideCallBlock}>
        {this.state.showCallBlock && this.props.info.status === 'wait' && isOperator() ? (
          <CallBlock>
            <ButtonBlock>
              <img onClick={this.makeAnswerRequest} src="/images/audio.png" alt="" />
              <span>Ответить</span>
            </ButtonBlock>
            <ButtonBlock>
              <img onClick={this.dropCall} src="/images/audio-2.png" alt="" />
              <span>Отклонить</span>
            </ButtonBlock>
          </CallBlock>
        ) : null}
        <CallIcon status={this.props.info.status} src="/images/call.png" />
        {this.props.info.status === 'active' ? <CallIndicator /> : null}
      </CallWrapper>
    );
  }
}

const mapStateToProps = (store) => {
  let isSoundPlay = store.sounds.sounds.indexOf('call') >= 0;
  let isActiveCallExist = store.widgets.activeWidgets.indexOf('activeCall') >= 0;
  return {
    isSoundPlay: isSoundPlay,
    isActiveCallExist: isActiveCallExist,
  };
};

export default connect(mapStateToProps, {
  addWidget,
  dropCall,
  playSound,
  stopSound,
})(RackCall);
