import { useState } from 'react';
import { Content, Button, Label } from './plate.styled';

export const Plate = (props) => {
  const { value, change, loading, title, disabled } = props;
  const [plate, setPlate] = useState(value);

  const onChange = () => {
    if (!plate) {
      return;
    }
    change(plate);
  };

  const changePlate = (e) => {
    const val = e.target.value;
    setPlate(val);
  };

  const cl = loading ? 'loading' : '';
  const titleOk = title ?? 'ГРЗ:';

  return (
    <Content className={cl}>
      <Label>{titleOk}</Label>
      <input disabled={disabled} type="text" value={plate} onChange={changePlate} />
      <Button type="button" onClick={onChange}>
        Установить
      </Button>
    </Content>
  );
};
