import styled from 'styled-components';

export const Content = styled.div`
  padding: 15px;
  box-sizing: border-box;
  overflow-x: auto;
  overflow-y: visible;
  color: #fff;
`;
export const Center = styled.div`
  text-align: center;
  padding: 80px 0;
`;

export const Nominal = styled.div`
  img {
    margin-top: 3px;
    margin-right: 2px;
  }
  span {
    display: inline-block;
    vertical-align: top;
    min-width: 45px;
    text-align: center;
  }
`;
export const NominalTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 2px;
  i {
    margin-left: 5px;
  }
`;
export const CashTable = styled.table`
  margin: 0 auto 25px;
  &:last-child {
    margin-bottom: 0;
  }
  border-collapse: collapse;
  table-layout: fixed;
  //width: 950px;
  color: #adafb1;
  font-size: 15px;
  border-style: hidden;
  .warning-medium {
    color: #ad963f;
  }
  .warning-hight {
    color: #ad4143;
  }
  img {
    position: absolute;
    right: 2px;
    width: 12px;
  }
  p {
    font-size: 13px;
    color: white;
  }
  .bill,
  .coin {
    display: block;
    //position: absolute;
    //left: 0;
    //top: 50%;
    //margin-top: -14px;
    width: 25px;
    height: 23px;
    margin-right: 5px;
    background: url(/images/coin2.png) no-repeat 2px 50%;
    background-size: contain;
  }
  .bill {
    background-image: url(/images/bill2.png);
  }
  td,
  th {
    position: relative;

    width: 120px;
    min-width: 120px;
    padding: 0 1px;
    text-align: center;
    height: 25px;
    border: 1px solid #4c5056;
    &:first-child {
      text-align: right;
    }
  }
  th {
    background-color: #395269;
    &:first-child {
      background-color: rgba(0, 0, 0, 0) !important;
    }
    &:nth-child(even) {
      background-color: #354e64;
    }
    &.gray {
      background-color: #393e44 !important;
    }
    &.disabled {
      position: relative;
      &::after {
        content: 'отключен';
        position: absolute;
        top: 102%;
        left: 0;
        width: 100%;
        font-size: 13px;
        font-weight: normal;
        color: #f74747;
        text-align: center;
      }
    }
  }
  td {
    &:nth-child(even) {
      background-color: #34393e;
    }
    &:first-child {
      vertical-align: text-top;
      text-align: right;
      padding-top: 5px;
      padding-left: 0;
      border-bottom: 0;
    }
  }
`;

export const InfoHeader = styled.div`
  font-size: 14px;
  color: #3ea5ff;
  padding-bottom: 10px;
  margin-left: 17px;
  margin-bottom: 15px;
`;
