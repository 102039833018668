const errors = {
  'Forbidden: invalid password': 'Неверный пароль',
  'Invalid params: user not found': 'Пользователь не найден',
  ERR_NETWORK: 'Сетевая ошибка',
  ECONNABORTED: 'Соединение отменено',
  404: 'Страница не найдена',
};

export const getRpcError = (msg) => {
  const out = errors[msg] ? errors[msg] : msg;
  return out;
};
