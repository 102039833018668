import React, { Component } from 'react'
import styled from 'styled-components'
import { change } from 'redux-form'
import Calendar from 'rc-calendar'
import moment from 'moment'
import ruRU from 'rc-calendar/lib/locale/ru_RU'
import TimePickerPanel from 'rc-time-picker/lib/Panel'

const InputBox = styled.div`
  position: relative;
  i {
    position: absolute;
    top: 2px;
    right: 0px;
    color: #fff;
    opacity: 0.6;
  }
`

class DateTime extends Component {

  state = {
    showCalendar: false
  }

  popup = null
  timePickerElement = <TimePickerPanel defaultValue={moment('00:00:00', 'HH:mm')} />

  setDate = (date) => {
    const { name } = this.props.input
    const { dispatch, form } = this.props.meta

    dispatch(change(form, name, date))
  }

  toggleCalendar = () => {
    const { disabled } = this.props
    if (disabled) return
    if (!this.state.showCalendar) {
      document.addEventListener('click', this.handleOutsideClick, true);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, true);
    }
    this.setState({ showCalendar: !this.state.showCalendar })
  }

  handleOutsideClick = (e) => {
    if (this.popup) {
      if (this.popup.contains(e.target)) {
        return
      } else {
        this.toggleCalendar();
      }
    }
  }

  getDisplayedValue = (type) => {
    if (this.props.input.value) {
      return type === 'dateTime' ?
        moment(this.props.input.value).format('lll')
        :
        moment(this.props.input.value).format('ll')
    } else return ''
  }

  render() {
    return (
      <div className={this.props.className}>
        <label>{this.props.label}</label>
        <div>
          <InputBox onClick={this.toggleCalendar}>
            <input
              placeholder={this.props.placeholder}
              value={this.getDisplayedValue(this.props.type)}
              disabled
            />
            <i className='fa fa-calendar' />
          </InputBox>
          {this.state.showCalendar ?
            <div ref={popup => { this.popup = popup }} style={{ position: 'absolute' }}>
              <Calendar
                timePicker={this.props.type === 'dateTime' ? this.timePickerElement : null}
                onSelect={(date) => { this.setDate(date) }}
                locale={ruRU}
                className={'calendar'}
                showDateInput={false}
              />
            </div>
            :
            null
          }
        </div>
      </div>
    )
  }
}

export default DateTime