import { Field as FormikField, FieldInputProps } from 'formik';

export function Field(props) {
  const { component, ...rest } = props;
  //console.log('🚀 ~ Field ~ props', props);
  
  return <FormikField component={component} {...rest} />;
}

export default Field;
