import React, { Component } from 'react'
import { change } from 'redux-form'
import styled from 'styled-components'

const Wrapper = styled.div``

const Popup = styled.div`
  max-height: 400px;
  overflow-y: auto;
  position: absolute;
  z-index: 11;
  padding: 20px;
  width: 100%;
  border: solid 1px #a3b0c0;
  border-radius: 4px;
  background-color: #4f5761;
  top: 42px;
  left: 0;
  display: ${props => props.popupIsOpen ? 'block !important' : 'none !important'};
`
const Checkbox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  &:last-child {
    margin-bottom: 0;
  }
  &.checkbox {
    input {
      margin-right: 15px;
      appearance: none;
      outline: none;
      cursor: pointer;
      width: 14px;
      height: 14px;
      border-radius: 4px;
      border: 1px solid #fff;
      opacity: 1;
      :checked {
        background-image: url('/images/checkbox-checked.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-color: #fff;
        border: none;
      }
    }
    label {
      font-size: 15px;
      color: #fff;
      opacity: 1;
    }
  }
`
const InputBox = styled.div`
  position: relative;
  height: 100%;
  &:after {
    content: '';
    position: absolute;
    top: 12px;
    right: 5px;
    width: 6px;
    height: 6px;
    border: 2px solid #bebebe;
    border-width: 0 2px 2px 0;
    transform: rotate( 45deg);
  }
  input {
    text-overflow: ellipsis;
    padding-right: 20px;
  }
`
const Select = styled.div`
  height: 100%;
  position: relative;
`

class Multiselect extends Component {
  constructor() {
    super()
    this.state = { popupIsOpen: false }
  }

  showPopup = () => {
    const { disabled } = this.props
    if (disabled) return

    if (!this.state.popupIsOpen) {
      document.addEventListener('click', this.handleOutsideClick, false)
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false)
    }
    this.setState({ popupIsOpen: !this.state.popupIsOpen })
  }

  handleOutsideClick = (e) => {
    if (this.popup) {
      if (this.popup.contains(e.target)) return
      this.showPopup()
    }
  }

  setValue = (e, fieldValue) => {
    const { name, value } = this.props.input
    const { dispatch, form } = this.props.meta

    let newValue = [...value]

    if (e.target.checked) {
      newValue.push(fieldValue)
    } else {
      newValue.splice(newValue.indexOf(fieldValue), 1)
    }
 
    // need cuz empty string not storing in form reducer
    // unlike empty array
    if (newValue.length === 0) newValue = '' 

    dispatch(change(form, name, newValue))
  }

  getInputs = () => {
    const { data, input: { value } } = this.props

    if (Array.isArray(data)) {
      return data.map((elem) => {
        return (
          <Checkbox key={elem.id} className='checkbox'>
            <input
              onChange={(e) => this.setValue(e, elem.id)}
              type='checkbox'
              name={elem.name}
              checked={Array.isArray(value) ? value.includes(elem.id) : false}
            />
            <label>{elem.name}</label>
          </Checkbox>
        )
      })
    }
  }

  getDisplayedValue = () => {
    const { data, input: { value } } = this.props
    if (Array.isArray(value)) {
    let displayedValue = ''

      value.forEach(id => {
        data.forEach(elem => {
          if (id === elem.id) {
            if (displayedValue) {
              displayedValue += `, ${elem.name}` 
            } else {
              displayedValue += elem.name
            }
          } 
        })
      })
      return displayedValue
    } else return ''
  }

  isCheckedAll = () => {
    const { data, input: { value } } = this.props
    
    if (Array.isArray(value)) {
      return value.length === data.length
    } else return false
  }

  selectAll = () => {
    const { dispatch, form } = this.props.meta
    const { data, input: { value, name } } = this.props

    let newValue = ''

    if (!value || value.length !== data.length) {
      newValue = data.map(elem => {
        return elem.id
      })
    } 

    dispatch(change(form, name, newValue))
  }

  render() {
    return (
      <Wrapper className={this.props.className}>
        <label>{this.props.label}</label>
        <Select innerRef={popup => { this.popup = popup }}>
          <InputBox onClick={this.showPopup}>
            <input
              placeholder={this.props.placeholder}
              value={this.getDisplayedValue()}
              disabled
            />
          </InputBox>
          <Popup popupIsOpen={this.state.popupIsOpen}>
            <Checkbox className='checkbox'>
              <input
                onChange={this.selectAll}
                checked={this.isCheckedAll()}
                type='checkbox'
                name='all'
              />
              <label>Все</label>
            </Checkbox>
            {this.getInputs()}
          </Popup>
        </Select>
      </Wrapper>
    )
  }
}

export default Multiselect