import React, { Component } from 'react';
//import styled from "styled-components";

class VideoUrl extends Component {

  test = false;

  componentDidMount() {
    //console.log('🚀 ~ videoUrl mount', this.props.id);
    const el = this.getEl(this.props.id);
    if (el) {
      el.src = this.getImageSrc();
    }
  }

  getImageSrc = () => {
    return this.test ? ('/images/test/' + this.props.id + '.jpg?=ct=' + this.props.createtime) : this.props.src;
  }

  componentWillUnmount() {
    //console.log('🚀 ~ videoUrl unmount', this.props.id);
    const el = this.getEl(this.props.id);
    if (el && el.src) {
      el.src = '';
    }
    //const cam = document.getElementById(this.props.id);
    //console.log('🚀 ~ unmount VideoUrl', this.props);
    //cam.src = "";
  }

  getEl = () => {
    return document.getElementById(this.props.id1);
  };

  setActiveCam = () => {
    this.props.func(this.props.id);
  };

  render() {
    const { id1, className } = this.props;
    const src = this.getImageSrc();

    return (
      <img
        draggable="false"
        onDragStart={() => false}
        id={id1}
        className={className}
        src={src}
        onClick={this.setActiveCam}
        alt=""
      />
    );
  }
}

export default VideoUrl;
